declare global {
    interface Array<T> {
        distinct();
        isEmpty(): boolean;
        firstOrNull(condition?: (item: T) => boolean): T | null;
        first(condition?: (T: T) => boolean): T;
        last(): T;
        any(condition: (item: T) => boolean): boolean;
        contains(element: T): boolean;
    }
}

Array.prototype.distinct = function() {
    return this.filter((element, index, array) => array.indexOf(element) === index);
};

Array.prototype.isEmpty = function() {
    return this.length === 0;
};

Array.prototype.firstOrNull = function(condition?: (item) => boolean) {
    if (this.length === 0) return null;
    const result = this.first(condition);
    if (typeof result === 'undefined') return null;
    return result;
};

Array.prototype.first = function(condition) {
    return condition ? this.filter(condition)[0] : this[0];
};

Array.prototype.last = function() {
    return this[this.length - 1];
};

Array.prototype.any = function(condition: (item) => boolean) {
    for (const item of this) {
        if (condition(item)) { return true; }
    }
    return false;
};

Array.prototype.contains = function(element) {
    return this.indexOf(element) !== -1;
};

export {};
