import { TaskApplication } from '../../../core/app/model/task/TaskApplication';
import { ApplicationAreas } from '../../../core/app/model/task/ApplicationAreas';
import { SupplyUnits } from '../../../core/app/model/task/SupplyUnits';
import { TaskApplicationForm } from '../../../core/app/model/task/TaskForm';

export class TaskApplicationFormatter {
    static formatDoseUnit(application: TaskApplication|TaskApplicationForm): string {
        let unit = this.unitAbbreviation(application.unit);
        if (application.area == ApplicationAreas.PerHectare) {
            unit = unit.trim() + ' x ha';
        }
        return unit.trim();
    }

    static formatCostUnit(unit: SupplyUnits): string {
        switch (unit) {
            case SupplyUnits.Litres: return 'U$S x litro';
            case SupplyUnits.Kilograms: return 'U$S x kilogramo';
            case SupplyUnits.Bag: return 'U$S x bolsa';
            default: return '';
        }
    }

    private static unitAbbreviation(unit: SupplyUnits): string {
        switch (unit) {
            case SupplyUnits.Litres: return 'lts';
            case SupplyUnits.Kilograms: return 'kg';
            case SupplyUnits.Bag: return 'bolsas';
            default: return '';
        }
    }
}
