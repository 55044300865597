import React from 'react';
import { RouterProps } from '../../lib/navigation/RouterProps';


export class Home extends React.Component<RouterProps, any> {
    componentDidMount() {
        this.props.navigation.redirect('tasks');
    }

    render() { return <></>; }
}

export default Home;
