import { Navigation } from '../Navigation';
import { createBrowserHistory } from 'history';
import { RouteConfig, RouteInfo } from './RouteConfig';
import { RouteUrlGenerator } from './RouteUrlGenerator';
import { RouteMatch } from '../RouteMatch';
import { Location } from '../Location';
import queryString from 'query-string';
import { RouterRenderer } from './RouterRenderer';
import { AnalyticsEventsLogger } from '../../../../core/infrastructure/logs/AnalyticsEventsLogger';

export type IsAuthenticatedFunc = () => boolean;

export class ReactRouterNavigation implements Navigation {
    private readonly urlGenerator = new RouteUrlGenerator();
    private _currentRoute: RouteMatch|null = null;
    private renderer: RouterRenderer;

    constructor(
        private routeConfig: RouteConfig,
        private isAuthenticated: IsAuthenticatedFunc,
        private history = createBrowserHistory()
    ) {
        this.renderer = this.createRenderer();
        this.validateDuplicatedNames();
        this.addNotFoundRoute();
    }

    location(): Location {
        return this.history.location;
    }

    currentRoute() {
        return this._currentRoute;
    }

    private validateDuplicatedNames() {
        const routeNames:string[] = [];
        for (const route of this.routeConfig.routes) {
            if (routeNames.includes(route.name)) throw new Error(`Route with name '${route.name}' already exists`);
            routeNames.push(route.name);
        }
    }

    generateUrl(name: string, params?: Record<string, any>): string {
        const route = this.findRouteByName(name);
        if (!route) throw new Error(`Route with name "${name}" not found`);
        return this.urlGenerator.generate(route, params);
    }

    private findRouteByName(name: string) {
        return this.routeConfig.routes.find(route => route.name === name);
    }

    redirect(name: string, params?: Record<string, any>, replace = false) {
        const url = this.generateUrl(name, params);
        this.trackPage();
        if (replace) {
            this.history.replace(url);
        } else {
            this.history.push(url);
        }
    }

    private trackPage() {
        const eventsLogger = new AnalyticsEventsLogger();
        eventsLogger.logScreenViewEvent(this.history.location.pathname);
    }

    goBack() {
        this.history.goBack();
    }

    redirectToAuth(replace = true) {
        const call = this.routeConfig.authRouteCall();
        this.redirect(call.name, call.params, replace);
    }

    redirectNotFound() {
        this.redirect('404');
    }

    private addNotFoundRoute() {
        this.routeConfig.routes.unshift({
            name: '404',
            path: this.routeConfig.notFoundRoutePath || '/404',
            component: this.routeConfig.notFoundComponent,
        });
    }

    private updateMatch(route: RouteInfo, match) {
        const query = queryString.parse(this.location().search) as Record<string, string>;
        this._currentRoute = {
            name: route.name,
            public: route.public,
            path: route.path,
            params: match.params,
            query
        };
    }

    render() {
        return this.renderer.render();
    }

    private createRenderer() {
        return new RouterRenderer(
            this,
            this.history,
            this.routeConfig,
            this.isAuthenticated,
            (route, match) => this.updateMatch(route, match),
        );
    }
}
