import { Navigation } from '../../../lib/navigation/Navigation';
import { GetTaskEditForm } from '../../../../core/app/useCases/task/GetTaskEditForm';
import { DateFormatter } from '../../../lib/formatters/DateFormatter';
import { TaskDateFormatter } from '../../../lib/formatters/TaskDateFormatter';
import { NumberFormatter } from '../../../lib/formatters/NumberFormatter';
import { UpdateTask } from '../../../../core/app/useCases/task/UpdateTask';
import { ValidationsError } from '../../../../common/validation/ValidationsError';
import { TaskNotFoundError } from '../../../../core/app/model/task/TaskNotFoundError';
import { TaskEditForm } from '../../../../core/app/model/task/TaskService';
import { TaskApplicationForm } from '../../../../core/app/model/task/TaskForm';
import { TaskApplicationFormatter } from '../../../lib/formatters/TaskApplicationFormatter';
import { TaskEditFormVM } from './TaskEditFormVM';
import { DeleteTaskApplication, DeleteTaskApplicationRequest } from '../../../../core/app/useCases/task/DeleteTaskApplication';
import { TaskFormNotFoundError } from '../../../../core/app/model/task/editForm/TaskFormNotFoundError';
import { InvalidOperationError } from '../../../../core/app/model/task/InvalidOperationError';
import { GetUserInfo } from '../../../../core/app/useCases/GetUserInfo';
import { EventsLogger } from '../../../../core/infrastructure/logs/EventsLogger';

export interface EditTaskView {
    showForm(form: TaskEditFormVM);
    showLoading();
    showEditApplicationForm(applicationId: number);
    showAddApplicationForm();
    showCannotEditTaskMessage();
    showCannotUpdateTaskMessage();
}

export class EditTaskPresenter {
    private formVM = new TaskEditFormVM();
    private taskId!: number;
    private fetchedForm!: TaskEditForm;

    constructor(
        private view: EditTaskView,
        private navigation: Navigation,
        private getTaskEditForm: GetTaskEditForm,
        private updateTask: UpdateTask,
        private getUserInfo: GetUserInfo,
        private eventsLogger: EventsLogger,
        private deleteTaskApplication: DeleteTaskApplication,
    ) {}

    async start() {
        this.taskId = this.getTaskIdFromRoute();
        await this.fetchForm();
        this.view.showForm(this.formVM);
    }

    private getTaskIdFromRoute() {
        return parseInt(this.navigation.currentRoute()?.params['id'], 10);
    }

    private async fetchForm() {
        this.view.showLoading();
        try {
            const form = await this.getTaskEditForm.exec(this.taskId);
            this.fetchedForm = form;
            this.formVM = this.formToVM(form);
        } catch (e) {
            this.handleFetchFormError(e);
        }
    }

    private formToVM(form: TaskEditForm): TaskEditFormVM {
        return {
            ...form.task,
            taskId: form.task.id,
            canEditType: form.task.typeIsSwitchable,
            startDate: form.task.startDate ? DateFormatter.fullPaddedDate(form.task.startDate!) : '',
            suggestedStartDate: TaskDateFormatter.suggestedDate(form.task.suggestedStartFrom, form.task.suggestedStartTo),
            hectares: form.task.hectares.toString(),
            laborCostPerHectare: NumberFormatter.float(form.task.laborCostPerHectare, 2),
            totalCostPerHectare: {
                amount: NumberFormatter.float(form.task.totalCostPerHectare, 2),
                unit: 'U$S/ha',
            },
            applications: form.task.applications.map(this.toApplicationVM.bind(this)),
            taskTypes: form.taskTypes,
            errors: {},
        };
    }

    setTaskTypeId(typeId: string) {
        this.updateForm({ typeId: parseInt(typeId, 10) });
    }

    setStartDate(startDate: string) {
        this.updateForm({ startDate });
    }

    setHectares(hectares: string) {
        this.updateForm({ hectares });
    }

    setLaborCostPerHectare(input: string) {
        this.updateForm({ laborCostPerHectare: input });
        this.refreshTotalCostPerHectare();
    }

    setResponsible(responsible: string) {
        this.updateForm({ assignee: responsible });
    }

    setObservations(observations: string) {
        this.updateForm({ observations });
    }

    private updateForm<K extends keyof TaskEditFormVM>(changes: Pick<TaskEditFormVM, K>) {
        this.formVM = Object.assign(this.formVM, changes);
        this.view.showForm(this.formVM);
    }

    async submit() {
        this.view.showLoading();
        try {
            this.logResponsibleAssignedEvent();
            await this.updateTask.exec(
                {
                    ...this.formVM,
                    taskId: this.taskId,
                    laborCostPerHectare: this.formatDecimal(this.formVM.laborCostPerHectare),
                }
            );
            this.navigation.redirect('tasks');
        } catch (e) {
            this.handleSubmitError(e);
        }
    }

    private logResponsibleAssignedEvent() {
        if (this.formVM.assignee == null) return;
        const userInfo = this.getUserInfo.exec();
        this.eventsLogger.logCustomEvent('ResponsibleAssigned', { user: userInfo.email });
    }

    private formatDecimal(decimal: string): string {
        return decimal.replace(',', '.');
    }

    private handleSubmitError(e: Error) {
        if (e instanceof ValidationsError) return this.updateForm({ errors: e.allErrorMessages() });
        if (e instanceof InvalidOperationError) return this.view.showCannotUpdateTaskMessage();
        throw e;
    }

    private handleFetchFormError(e: Error) {
        if (e instanceof TaskNotFoundError) return this.navigation.redirectNotFound();
        if (e instanceof TaskFormNotFoundError) return this.view.showCannotEditTaskMessage();
        throw e;
    }

    private toApplicationVM(application: TaskApplicationForm) {
        return {
            id: application.number,
            supplyName: application.supplyName,
            dose: {
                amount: NumberFormatter.float(application.dose, 2),
                unit: TaskApplicationFormatter.formatDoseUnit(application),
            },
            cost: {
                amount: NumberFormatter.float(application.costPerHectare, 2),
                unit: 'U$S/ha',
            },
        };
    }

    editApplication(id: number) {
        this.view.showEditApplicationForm(id);
    }

    async refreshApplications() {
        const form = await this.getTaskEditForm.exec(this.taskId);
        this.fetchedForm = form;
        const formVM = this.formToVM(form);
        this.formVM = {
            ...this.formVM,
            applications: formVM.applications,
        };
        this.refreshTotalCostPerHectare();
    }

    addApplication() {
        this.view.showAddApplicationForm();
    }

    private refreshTotalCostPerHectare() {
        const totalCostPerHectare = this.calculateTotalCostPerHectare();
        this.formVM.totalCostPerHectare.amount = NumberFormatter.float(totalCostPerHectare, 2);
        this.view.showForm(this.formVM);
    }

    private calculateTotalCostPerHectare(): number {
        const task = this.fetchedForm.task;
        const laborCostPerHectare = Number(this.formVM.laborCostPerHectare);
        return task.totalCostPerHectare - task.laborCostPerHectare + laborCostPerHectare;
    }

    async deleteApplication(applicationNumber: number) {
        try {
            await this.doDeleteApplication(applicationNumber);
        } catch(e) {
            if(e instanceof InvalidOperationError) return this.view.showCannotUpdateTaskMessage();
        }
    }

    private async doDeleteApplication(applicationNumber: number) {
        const request: DeleteTaskApplicationRequest = {
            taskId: this.taskId,
            applicationNumber,
            applications: this.fetchedForm.task.applications
        };
        await this.deleteTaskApplication.exec(request);
        await this.refreshApplications();
    }
}
