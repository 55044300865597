import React from 'react';
import styled from 'styled-components';

export const ModalOverlay: React.FC = (props) => (
    <Root>
        {props.children}
    </Root>
);

const Root = styled.div`
  background-color: #ffffffcc;
  height: 100%;
  position: absolute;
  width: 100%;
`;
