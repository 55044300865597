import React from 'react';
import { Helmet } from 'react-helmet';
import styled from 'styled-components';
import { Navigation } from '../../../lib/navigation/Navigation';
import { Icon } from '../../common/Icon';
import arrowLeftIcon from './icon-arrow-left.png';
import { HStack, VStack } from '../../common/Stack';

interface Props {
    title: string;
    navigation?: Navigation;
    headerContent?: JSX.Element;
    className?: string;
}

export const PageContainer: React.FC<Props> = (props) => (
    <Root className={props.className}>
        <Helmet><title>Culti - {props.title}</title></Helmet>
        <PageNav>
            {props.navigation && <BackButton size={{ w: 9, h: 16 }} url={arrowLeftIcon} onClick={() => props.navigation?.goBack()} />}
            <div className="title">{props.title}</div>
            {props.headerContent}
        </PageNav>
        <PageContent className="page-content">{props.children}</PageContent>
    </Root>
);

const Root = styled(VStack)`
  align-items: stretch;
  flex-grow: 1;
  flex-shrink: 1;
  padding: 5px 24px 22px 34px;
`;

const PageNav = styled(HStack)`
  align-items: center;
  padding: 18px 0;

  .title {
    align-items: center;
    color: #334d6e;
    flex: none;
    font-size: 18px;
    font-weight: 600;
    line-height: 1.32em;
    margin-left: 10px;
    margin-right: 10px;
  }
`;

const PageContent = styled.div`
  background-color: white;
  border-radius: 4px;
  box-shadow: 8px 0 18px 0 #0000000d;
`;

const BackButton = styled(Icon)`
  cursor: pointer;
`;
