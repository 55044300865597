import React, { MouseEventHandler } from 'react';
import styled from 'styled-components';

export const LinkButton: React.FC<Props> = (props) => {
    const handleClick = (e) => {
        e.preventDefault();
        props.onClick?.(e);
    };
    return <StyledLink href="#" {...props} onClick={handleClick}>{props.children}</StyledLink>;
};

const StyledLink = styled.a`
  flex-shrink: 0;
  height: 50px;
  line-height: 50px;
  display: inline-block;
  font-weight: 500;
  color: #666;
  font-size: 16px;
  padding: 0 36px;
  position: relative;
  text-align: center;

  &:hover { color: #1B4C74; }
`;

interface Props {
    onClick?: MouseEventHandler;
    className?: string;
}
