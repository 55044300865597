import { FirebaseApp, initializeApp } from 'firebase/app';
import { getAnalytics, logEvent, setCurrentScreen } from 'firebase/analytics';
import { EventsLogger } from './EventsLogger';

export class AnalyticsEventsLogger implements EventsLogger {
    // @ts-ignore
    private app: FirebaseApp = initializeApp(window.FIREBASE_CONFIG ? JSON.parse(window.FIREBASE_CONFIG) : { "apiKey": "AIzaSyDcE5t_tBN-WrocaxAI-E3Z-WWEE5fJ32Y", "authDomain": "culti-development.firebaseapp.com", "projectId": "culti-development", "storageBucket": "culti-development.appspot.com", "messagingSenderId": "695072887987", "appId": "1:695072887987:web:94b0dbbd8c5a9045edccc6", "measurementId": "G-YQK65HYYDS" });
    private analytics = getAnalytics(this.app);

    logCustomEvent(eventName: string, payload: Record<string, any>): void {
        payload.platform = 'web';
        logEvent(this.analytics, eventName, payload);
    }

    logScreenViewEvent(screenName: string): void {
        setCurrentScreen(this.analytics, screenName);
        logEvent(this.analytics, 'page_view');
    }
}
