import React, { ChangeEventHandler, KeyboardEventHandler } from 'react';
import styled from 'styled-components';
import theme from '../../application/theme';

export const Input: React.FC<Props> = (props) => (
    <Root>
        <StyledInput {...props} className={props.hasError ? 'has-error' : ''} />
        {props.unit && <Unit>{props.unit}</Unit>}
    </Root>
);

interface Props {
    readOnly?: boolean;
    hasError?: boolean;
    type?: string;
    unit?: string;
    value?: string | ReadonlyArray<string> | number;
    placeholder?: string;
    onChange?: ChangeEventHandler<HTMLInputElement>;
    onKeyDown?: KeyboardEventHandler<HTMLInputElement>;
}

const Root = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
`;

const StyledInput = styled.input`
  height: 45px;
  width: 100%;
  border-radius: ${theme.forms.field.input.borderRadius};
  border: 1px solid ${theme.forms.field.input.borderColor};
  font-size: 13px;
  line-height: 19px;
  padding: 12px 13px 14px 11px;
  color: ${theme.forms.field.input.color};

  &:focus { border-color: ${theme.forms.field.input.borderColorFocussed}; }

  &.has-error {
    background-color: ${theme.forms.field.input.backgroundColorError};
    border-color: ${theme.errorColor};
  }

  &[readonly] {
    background-color: ${theme.forms.field.input.backgroundColorDisabled};
    border: 1px solid ${theme.forms.field.input.borderColor};
  }

  &[type="password"] { font-size: 20px; }
`;

const Unit = styled.div`
  line-height: 45px;
  color: #828282;
  font-size: 13px;
  margin-left: 10px;
  white-space: nowrap;
`;
