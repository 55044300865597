import { TaskService } from '../../model/task/TaskService';
import { LocalDate } from '../../../../common/time/LocalDate';
import { Validator } from '../../../../common/validation/Validator';

export class CreateTask {
    constructor(private taskService: TaskService) {}

    async exec(request: CreateTaskRequest): Promise<number> {
        this.validate(request);
        const taskId = await this.taskService.create({
            taskTypeId: request.taskTypeId!,
            fieldId: request.fieldId!,
            grainId: request.grainId!,
            startDate: this.toLocalDate(request.startDate),
        });
        return taskId;
    }

    private validate(request: CreateTaskRequest) {
        const validator = new Validator();

        validator.check('taskType', request.taskTypeId).notNull('Debe seleccionar un tipo de tarea');
        validator.check('field', request.fieldId).notNull('Debe seleccionar un lote');
        validator.check('startDate', request.startDate).notNullOrBlank('Debe ingresar una fecha').date('DD/MM/YYYY', 'La fecha es inválida');
        validator.check('grain', request.grainId).notNull('Debe seleccionar un cultivo');

        validator.throwIfHasErrors();
    }

    private toLocalDate(dateString: string): LocalDate {
        const parts = dateString.split('/');
        return LocalDate.of(parseInt(parts[2], 10), parseInt(parts[1], 10), parseInt(parts[0], 10));
    }
}

export interface CreateTaskRequest {
    taskTypeId: Nullable<number>;
    fieldId: Nullable<number>;
    startDate: string;
    grainId: Nullable<number>;
}