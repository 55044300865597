const palette = {
    white: '#ffffff',
    gray: {
        2: '#4f4f4f',
        3: '#828282',
        4: '#bdbdbd',
        6: '#f2f2f2',
    },
    magenta: {
        1: '#fff0f6',
    },
    red: {
        2: '#D67376',
    },
};

const theme = {
    errorColor: palette.red['2'],
    icons: {
        deleteColor: palette.red['2'],
    },
    buttons: {
        backgroundColorDisabled: palette.gray['4'],
        colorDisabled: palette.gray['6'],
    },
    forms: {
        field: {
            labelColor: palette.gray['2'],
            hintMessageColor:palette.gray['3'],
            input: {
                backgroundColor: palette.white,
                backgroundColorDisabled: palette.gray['6'],
                backgroundColorError: palette.white,
                borderColor: palette.gray['4'],
                borderColorFocussed: '#276fa9',
                borderRadius: '6px',
                borderStyle: 'solid',
                borderWidth: '1px',
                color: palette.gray['3'],
                readOnly: {
                    backgroundColor: palette.red,
                    borderColor: palette.red,
                },
            },
            dropdown: {
                indicatorColor: palette.gray['2'],
            },
            checkbox: {
                borderRadius: '4px',
                color: '#43c5b3',
                padding: '7px',
                size: '20px',
                checkmark: {
                    borderRadius: '2px',
                },
                readOnly: {
                    backgroundColor: '#1f222a',
                    borderColor: '#2f323a',
                    color: '#43c5b3',
                },
            },
        },
    },
};

export default theme;
