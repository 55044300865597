import React from 'react';
import styled from 'styled-components';
import { Navigation } from '../../../lib/navigation/Navigation';
import { Icon } from '../../common/Icon';
import logo from './logo.png';
import farmIcon from './icon-farm.png';
import todoListIcon from './icon-todo-list.png';
import chartIcon from './icon-chart.png';
import siloIcon from './icon-silo.png';
import moneyBagIcon from './icon-money-bag.png';
import bagIcon from './icon-bag.png';
import { MenuItem } from './MenuItem';

interface Props { navigation: Navigation; }

interface Item { label: string; icon: string; route: string|null; }

export class MainMenu extends React.Component<Props> {
    private items: Item[] = [
        { label: 'Mi Campo', icon: farmIcon,     route: null },
        { label: 'Tareas',   icon: todoListIcon, route: 'tasks' },
        { label: 'Finanzas', icon: chartIcon,    route: 'financials' },
        { label: 'Granos',   icon: siloIcon,     route: null },
        { label: 'Ventas',   icon: moneyBagIcon, route: null },
        { label: 'Insumos',  icon: bagIcon,      route: null },
    ];

    private onItemClick(route: string|null) { route && this.props.navigation.redirect(route); }

    render() {
        return (
            <Root>
                <Logo src={logo} />
                <ul>
                    {this.items.map(item => (
                        <MenuItem key={item.label}
                            label={item.label}
                            icon={<Icon url={item.icon} size={21} />}
                            selected={this.isSelected(item)}
                            showTooltip={!item.route}
                            onClick={() => this.onItemClick(item.route)}
                        />
                    ))}
                </ul>
            </Root>
        );
    }

    private isSelected(item: Item): boolean {
        return this.props.navigation.currentRoute()?.name == item.route
            || this.props.navigation.location().pathname.startsWith(`/${item.route}/`);
    }
}

const Root = styled.div`
  background-color: white;
  box-shadow: 6px 0 18px 0 #0000000d;
  flex-grow: 0;
  flex-shrink: 0;
  width: 197px;

  ul {
    margin-top: 41px;
    padding: 0 14px;
    width: 100%;
  }
`;

const Logo = styled.img`
  height: 31px;
  margin-left: 43px;
  margin-top: 25px;
  width: 110px;
`;
