import { Validator } from './Validator';

export class PropertyAssertions {
    private readonly name: string;
    private readonly value: any;
    private validator: Validator;
    private hasPreviousErrors = false;

    constructor(name: string, value: any, validator: Validator) {
        this.name = name;
        this.value = value;
        this.validator = validator;
    }

    notNullOrEmpty(errorMessage?: string): PropertyAssertions {
        const isValid = this.value !== null && this.value !== '';
        this.processValidation(isValid, errorMessage || (this.name + ' es requerido'));
        return this;
    }

    notNullOrBlank(errorMessage?: string): PropertyAssertions {
        const isValid = this.value !== null && this.value.trim().length > 0;
        this.processValidation(isValid, errorMessage || (this.name + ' es requerido'));
        return this;
    }

    notNull(errorMessage?: string): PropertyAssertions {
        const isValid = this.value !== null;
        this.processValidation(isValid, errorMessage || (this.name + ' es requerido'));
        return this;
    }

    number(errorMessage?: string): PropertyAssertions {
        const isValid = this.value &&
                        !Number.isNaN(parseFloat(this.value)) &&
                        Number.isFinite(parseFloat(this.value));
        this.processValidation(isValid, errorMessage || (this.name + ' debe ser un número'));
        return this;
    }

    integer(errorMessage?: string): PropertyAssertions {
        const isValid = this.value &&
            !Number.isNaN(parseInt(this.value)) &&
            Number.isFinite(parseInt(this.value)) &&
            parseInt(this.value).toString() == this.value;
        this.processValidation(isValid, errorMessage || (this.name + ' debe ser un número entero'));
        return this;
    }

    positive(errorMessage?: string): PropertyAssertions {
        const isValid = this.value > 0;
        this.processValidation(isValid, errorMessage || (this.name + ' debe ser positivo'));
        return this;
    }

    zeroOrPositive(errorMessage?: string): PropertyAssertions {
        const isValid = this.value == 0 || this.value > 0;
        this.processValidation(isValid, errorMessage || (this.name + ' debe ser positivo'));
        return this;
    }

    equals(otherValue: any, errorMessage?: string): PropertyAssertions {
        const isValid = this.value === otherValue;
        this.processValidation(isValid, errorMessage || (`${this.value} no es igual a ${otherValue}`));
        return this;
    }

    email(errorMessage?: string): PropertyAssertions {
        // eslint-disable-next-line no-useless-escape
        const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        const isValid = emailRegex.test(this.value);
        this.processValidation(isValid, errorMessage || (this.name + ' debe ser un email válido'));
        return this;
    }

    minLength(min: number, errorMessage?: string): PropertyAssertions {
        const isValid = this.value.toString().length >= min;
        this.processValidation(isValid, errorMessage || (`${this.name} debe tener al menos ${min} caracteres`));
        return this;
    }

    maxLength(max: number, errorMessage?: string): PropertyAssertions {
        const isValid = this.value.toString().length <= max;
        this.processValidation(isValid, errorMessage || (`${this.name} debe tener menos de ${max} caracteres`));
        return this;
    }

    date(format: string, errorMessage?: string): PropertyAssertions {
        let isValid = true;
        if (format === 'DD/MM/YYYY') {
            isValid = this.value === '' || this.isValidDDMMYYYYDate();
        } else {
            throw new Error(`Unsupported date format: ${format}`);
        }
        this.processValidation(isValid, errorMessage || (`${this.name} debe ser una fecha válida`));
        return this;
    }

    minDate(format: string, minDate: Date, errorMessage?: string): PropertyAssertions {
        let isValid = true;
        if (format === 'DD/MM/YYYY') {
            const value = this.toDateFromDDMMYYYY();
            isValid = value.getTime() >= minDate.getTime();
        } else {
            throw new Error(`Unsupported date format: ${format}`);
        }
        this.processValidation(isValid, errorMessage || (`${this.name} debe ser una fecha válida`));
        return this;
    }

    maxDate(format: string, maxDate: Date, errorMessage?: string): PropertyAssertions {
        let isValid = true;
        if (format === 'DD/MM/YYYY') {
            const value = this.toDateFromDDMMYYYY();
            isValid = value.getTime() <= maxDate.getTime();
        } else {
            throw new Error(`Unsupported date format: ${format}`);
        }
        this.processValidation(isValid, errorMessage || (`${this.name} debe ser una fecha válida`));
        return this;
    }

    private processValidation(isValid: boolean, errorMessage: string) {
        if (this.hasPreviousErrors) { return; }
        if (!isValid) {
            this.hasPreviousErrors = true;
            this.validator.addPropertyError(this.name, errorMessage);
        }
    }

    private isValidDDMMYYYYDate() {
        const parsedDate = this.value.split('/');
        const date = this.toDateFromDDMMYYYY();
        return date.getDate() == parseInt(parsedDate[0], 10) &&
            date.getMonth() == parseInt(parsedDate[1], 10) - 1 &&
            date.getFullYear() == parseInt(parsedDate[2], 10);
    }

    private toDateFromDDMMYYYY() {
        const parsedDate = this.value.split('/');
        return new Date(parsedDate[2], parsedDate[1] - 1, parsedDate[0]);
    }
}
