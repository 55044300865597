import React from 'react';
import { ModalRender } from '../../../components/Modal/ModalRender';
import styled from 'styled-components';
import { Loader } from '../../../components/common/Loader';
import { ModalOverlay } from '../../../components/Modal/ModalOverlay';
import { LinkButton } from '../../../components/buttons/LinkButton';
import { PrimaryButton } from '../../../components/buttons/PrimaryButton';
import { CreateTaskView } from './CreateTaskPresenter';
import { Navigation } from '../../../lib/navigation/Navigation';
import { FormField } from '../../../components/forms/FormField';
import { DropDown } from '../../../components/forms/DropDown';
import { DateInput } from '../../../components/forms/DateInput';
import { CreateTaskFormVM } from './CreateTaskFormVM';
import AppContext from '../../../lib/AppContext';

interface State {
    isLoading: boolean;
    form: CreateTaskFormVM;
}

interface CreateTaskModalProps {
    navigation: Navigation;
    showModal: boolean;
    onCancel: () => void;
    onSubmit: () => void;
}

export class CreateTaskModal extends React.Component<CreateTaskModalProps, State> implements CreateTaskView {
    private presenter = AppContext.presenters.createTask(this);

    state: State = {
        isLoading: false,
        form: new CreateTaskFormVM(),
    };

    async componentDidMount() {
        await this.presenter.start();
    }

    componentWillUnmount() {
        this.presenter.stop();
    }

    showLoading() {
        this.setState({ isLoading: true });
    }

    closeLoading() {
        this.setState({ isLoading: false });
    }

    showForm(form: CreateTaskFormVM) {
        this.setState({ form });
    }

    close() {
        this.props.onCancel();
        this.setState({ form: { ...this.state.form, errors: {}, fieldId: null, grainId: null, startDate: '', taskTypeId: null } });
    }

    renderForm() {
        const form = this.state.form;
        return <>
            <div className="form">
                <div className="title">
                    <label>Crear tarea</label>
                    <div className="closeModal" onClick={() => this.close()}>X</div>
                </div>
                <FormField label="Tipo de tarea" error={form.errors['taskType']}>
                    <DropDown
                        options={form.taskTypes.map(t => ({ label: t.name.charAt(0).toUpperCase() + t.name.slice(1), value: t.id }))}
                        value={form.taskTypeId}
                        onChange={option => this.presenter.setTaskType(option.value)}
                        hasError={'taskType' in form.errors}
                    />
                </FormField>
                <FormField label="Lote" error={form.errors['field']}>
                    <DropDown
                        options={form.fields.map(f => ({ label: f.name, value: f.id }))}
                        value={form.fieldId}
                        onChange={option => this.presenter.setField(option.value)}
                        hasError={'field' in form.errors}
                    />
                </FormField>
                <HStack>
                    <FormField label="Fecha" error={form.errors['startDate']}>
                        <DateInput
                            value={form.startDate}
                            onChange={e => this.presenter.setStartDate(e.target.value)}
                            hasError={'startDate' in form.errors}
                        />
                    </FormField>
                    {
                        form.fields.firstOrNull(f => f.id === form.fieldId)?.canEditGrain && <FormField label="Cultivo" className="grain" error={form.errors['grain']}>
                            <DropDown
                                options={form.grains.filter(g => g.fieldId === form.fieldId).map(g => ({ label: g.name, value: g.id }))}
                                value={form.grainId}
                                onChange={option => this.presenter.setGrain(option.value)}
                                hasError={'grain' in form.errors}
                            />
                        </FormField>
                    }
                </HStack>
            </div>
        </>;
    }

    render() {
        if (this.props.showModal) {
            return <ModalRender>
                <StyledModal>
                    <ModalContent>
                        <ModalBody>
                            {this.renderForm()}
                        </ModalBody>
                        <ModalFooter>
                            <LinkButton onClick={() => this.close()}>Cancelar</LinkButton>
                            <PrimaryButton onClick={async () => {
                                await this.presenter.submit();
                            }}>Crear</PrimaryButton>
                        </ModalFooter>
                        {this.state.isLoading ? <ModalOverlay> <Loader /> </ModalOverlay> : <></> }
                    </ModalContent>
                </StyledModal>
            </ModalRender>;
        }
        return <></>;
    }
}

const StyledModal = styled.div`
  background-color: #000000bf;
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ModalContent = styled.div`
  background-color: #fff;
  position: relative;
  display: flex;
  width: 486px;
  height: 460px;
  flex-direction: column;
  pointer-events: auto;
  background-clip: padding-box;
  border-radius: 0.3rem;
  outline: 0;
`;

const ModalBody = styled.div`
  display: flex;
  flex-direction: column;
  padding: 25px 26px 0;
  .form {
    .title {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding-bottom: 20px;
      font-size: 18px;
      font-weight: 600;
      
      .closeModal {
        cursor: pointer;
      }
    }
  }
`;

const ModalFooter = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  padding: 15px 50px 15px 15px;
  border-bottom-right-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem;
  
  p {
    margin-right: 135px;
    color: #f36666;
  }
  
  > a:first-child {
    flex-grow: 0;
    flex-shrink: 0;
    width: 50%;
  }
  
  > a:last-child {
    flex-shrink: 1;
    flex-grow: 1;
    margin-left: 10px;
  }
`;

const HStack = styled.div`
  display: flex;
  flex-direction: row;
  
  .grain {
    margin-left: 27px;
    flex-grow: 1;
    flex-shrink: 1;
  }
`;
