import React from 'react';
import styled from 'styled-components';
import { LinkButton } from '../../buttons/LinkButton';
import { PrimaryButton } from '../../buttons/PrimaryButton';
import { ModalOverlay } from '../ModalOverlay';
import { Loader } from '../../common/Loader';
import { ModalRender } from '../ModalRender';
import iconDelete from './icon-delete.png';
import iconFinish from './icon-finish.png';
import iconReopen from './icon-reopen.png';
import iconWarning from './icon-warning.png';
import { HStack, VStack } from '../../common/Stack';

interface Props {
    open: boolean;
    action: () => void;
    onCancel?: () => void;
    content?: JSX.Element;
    actionLabel?: string;
    icon?: string;
}

interface State { isLoading: boolean; }
class WarningModal extends React.Component<Props, State> {
    state: State = { isLoading: false };

    private async action() {
        this.setState({ isLoading: true });
        await this.props.action();
        this.setState({ isLoading: false });
    }

    private onCancel() {
        this.props.onCancel!();
        this.setState({ isLoading: false });
    }

    render() {
        if (!this.props.open) { return null; }
        return (
            <ModalRender>
                <StyledModal>
                    <ModalContent>
                        <ModalBody>
                            <VStack className="header">
                                {this.props.onCancel && <CloseButton onClick={() => this.onCancel()} />}
                            </VStack>
                            <VStack className="body">
                                <WarningIcon src={this.props.icon} />
                                {this.props.content}
                            </VStack>
                        </ModalBody>
                        <ModalFooter>
                            {this.props.onCancel && <LinkButton onClick={() => this.onCancel()}>Cancelar</LinkButton>}
                            <PrimaryButton onClick={async () => await this.action()}>
                                {this.props.actionLabel}
                            </PrimaryButton>
                        </ModalFooter>
                        {this.state.isLoading && <ModalOverlay><Loader /></ModalOverlay>}
                    </ModalContent>
                </StyledModal>
            </ModalRender>
        );
    }
}

export const DeleteTaskWarning: React.FC<Props> = (props) => (
    <WarningModal
        {...props}
        icon={iconDelete}
        content={<p>¿Estás seguro que deseas <br/><strong>borrar</strong> la tarea?</p>}
        actionLabel={props.actionLabel || 'Borrar'}
    />
);

export const FinishTaskWarning: React.FC<Props> = (props) => (
    <WarningModal
        {...props}
        icon={iconFinish}
        content={<p>¿Estás seguro que deseas <br/><strong>finalizar</strong> la tarea?</p>}
        actionLabel={props.actionLabel || 'Finalizar'}
    />
);

export const ReopenTaskWarning: React.FC<Props> = (props) => (
    <WarningModal
        {...props}
        icon={iconReopen}
        content={<p>¿Estás seguro que deseas <br/><strong>reabrir</strong> la tarea?</p>}
        actionLabel={props.actionLabel || 'Reabrir'}
    />
);

export const TaskAlreadyFinishedModal: React.FC<Props> = (props) => (
    <WarningModal
        {...props}
        icon={iconWarning}
        content={<p>La tarea que estás intentando finalizar ya ha sido finalizada.</p>}
        actionLabel={props.actionLabel || 'Aceptar'}
    />
);

export const TaskNotFoundModal: React.FC<Props> = (props) => (
    <WarningModal
        {...props}
        icon={iconWarning}
        content={<p>La tarea no fue actualizada ya que fue borrada previamente.</p>}
        actionLabel={props.actionLabel || 'Aceptar'}
    />
);

export const CannotEditTaskModal: React.FC<Props> = (props) => (
    <WarningModal
        {...props}
        icon={iconWarning}
        content={props.content || <p>La tarea a editar fue finalizada.<br />Tienes que reabrirla para modificarla.</p>}
        actionLabel={props.actionLabel || 'Volver'}
    />
);

const StyledModal = styled.div`
  background-color: #000000bf;
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ModalContent = styled(VStack)`
  align-content: end;
  background-clip: padding-box;
  background-color: white;
  border-radius: 0.3rem;
  height: 387px;
  outline: 0;
  pointer-events: auto;
  position: relative;
  width: 408px;
`;

const ModalBody = styled(VStack)`
  flex-grow: 1;
  flex-shrink: 1;
  padding: 25px 26px 0;

  .body {
    flex-grow: 1;
    flex-shrink: 1;
    justify-content: center;
    text-align: center;
    font-size: 18px;
    font-weight: 500;
    color: #1b4c74;
  }
`;

const WarningIcon = styled.img`
  align-self: center;
  height: 90px;
  margin-bottom: 10px;
  width: 90px;
`;

const ModalFooter = styled(HStack)`
  flex: none;
  padding: 15px 32px 40px;

  > a:first-child { flex: none; width: 50%; }
  > a:last-child { flex: auto; }
`;

const CloseButton = styled.div`
    align-self: flex-end;
    box-sizing: border-box;
    position: relative;
    display: block;
    transform: scale(var(--ggs,1));
    width: 22px;
    height: 22px;
    border: 2px solid transparent;
    border-radius: 40px;
    cursor: pointer;
    ::after, ::before {
        content: "";
        display: block;
        box-sizing: border-box;
        position: absolute;
        width: 16px;
        height: 2px;
        background: currentColor;
        transform: rotate(45deg);
        border-radius: 5px;
        top: 8px;
        left: 1px;
    }
    ::after {
      transform: rotate(-45deg)
    }
`;
