import { CoreConfig } from './CoreConfig';
import { Login } from '../app/useCases/Login';
import { HttpUserService } from './httpServices/HttpUserService';
import { GetTasks } from '../app/useCases/task/GetTasks';
import { HttpTaskService } from './httpServices/HttpTaskService';
import { HttpPlanService } from './httpServices/HttpPlanService';
import { GetTaskEditForm } from '../app/useCases/task/GetTaskEditForm';
import { UpdateTask } from '../app/useCases/task/UpdateTask';
import { GetTask } from '../app/useCases/task/GetTask';
import { GetCostCenters } from '../app/useCases/GetCostCenters';
import { HttpCostService } from './httpServices/HttpCostService';
import { UpdateTaskApplication } from '../app/useCases/task/UpdateTaskApplication';
import { AddTaskApplication } from '../app/useCases/task/AddTaskApplication';
import { DeleteTaskApplication } from '../app/useCases/task/DeleteTaskApplication';
import { DeleteTask } from '../app/useCases/task/DeleteTask';
import { FinishTask } from '../app/useCases/task/FinishTask';
import { GetCreateTaskForm } from '../app/useCases/task/GetCreateTaskForm';
import { CreateTask } from '../app/useCases/task/CreateTask';
import { ReopenTask } from '../app/useCases/task/ReopenTask';
import { Logout } from '../app/useCases/Logout';
import { GetPlans } from '../app/useCases/GetPlans';
import { SelectPlan } from '../app/useCases/SelectPlan';
import { GetSelectedPlan } from '../app/useCases/GetSelectedPlan';
import { GetSeasonReportAccess } from '../app/useCases/GetSeasonReportAccess';
import { GetFinancialReport } from '../app/useCases/financials/GetFinancialReport';
import { HttpFinancialsService } from './httpServices/HttpFinancialsService';
import { SearchTasks } from '../app/useCases/task/SearchTasks';
import { GetTaskSuggestions } from '../app/useCases/task/GetTaskSuggestions';
import { UpdateApplication } from '../app/useCases/task/UpdateApplication';
import { GetUserInfo } from '../app/useCases/GetUserInfo';

export class Core {
    private useCaseFactories: Map<any, () => any> = new Map();
    private readonly userService = new HttpUserService(this.config.httpClient);
    private readonly planService = new HttpPlanService(this.config.httpClient);
    private readonly taskService = new HttpTaskService(this.config.httpClient);
    private readonly costCenterService = new HttpCostService(this.config.httpClient);
    private readonly financialsService = new HttpFinancialsService(this.config.httpClient);

    constructor(private config: CoreConfig) {
        this.registerUseCases();
    }

    private registerUseCases() {
        this.registerUseCase(Login, () => new Login(this.config.sessionStorage, this.userService, this.planService));
        this.registerUseCase(GetTasks, () => new GetTasks(this.taskService, this.config.sessionStorage));
        this.registerUseCase(SearchTasks, () => new SearchTasks(this.taskService, this.config.sessionStorage));
        this.registerUseCase(GetTask, () => new GetTask(this.taskService));
        this.registerUseCase(GetTaskEditForm, () => new GetTaskEditForm(this.taskService));
        this.registerUseCase(UpdateTask, () => new UpdateTask(this.taskService));
        this.registerUseCase(GetCostCenters, () => new GetCostCenters(this.costCenterService));
        this.registerUseCase(UpdateTaskApplication, () => new UpdateTaskApplication(this.taskService));
        this.registerUseCase(AddTaskApplication, () => new AddTaskApplication(this.taskService));
        this.registerUseCase(DeleteTaskApplication, () => new DeleteTaskApplication(this.taskService));
        this.registerUseCase(DeleteTask, () => new DeleteTask(this.taskService));
        this.registerUseCase(FinishTask, () => new FinishTask(this.taskService));
        this.registerUseCase(GetCreateTaskForm, () => new GetCreateTaskForm(this.taskService, this.config.sessionStorage));
        this.registerUseCase(CreateTask, () => new CreateTask(this.taskService));
        this.registerUseCase(ReopenTask, () => new ReopenTask(this.taskService));
        this.registerUseCase(Logout, () => new Logout(this.config.sessionStorage));
        this.registerUseCase(GetPlans, () => new GetPlans(this.planService));
        this.registerUseCase(SelectPlan, () => new SelectPlan(this.config.sessionStorage, this.config.eventBus));
        this.registerUseCase(GetSelectedPlan, () => new GetSelectedPlan(this.config.sessionStorage));
        this.registerUseCase(GetFinancialReport, () => new GetFinancialReport(this.config.sessionStorage, this.financialsService));
        this.registerUseCase(GetSeasonReportAccess, () => new GetSeasonReportAccess(this.config.sessionStorage, this.financialsService));
        this.registerUseCase(GetTaskSuggestions, () => new GetTaskSuggestions(this.config.sessionStorage, this.taskService));
        this.registerUseCase(UpdateApplication, () => new UpdateApplication(this.taskService, this.config.sessionStorage));
        this.registerUseCase(GetUserInfo, () => new GetUserInfo(this.config.sessionStorage));
    }

    useCase<T>(clazz): T {
        return this.useCaseFactories.get(clazz)!.call(this);
    }

    private registerUseCase(clazz, factory: () => any) {
        this.useCaseFactories.set(clazz, factory);
    }
}
