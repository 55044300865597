import React from 'react';
import { Presentable } from '../../../components/Presentable';
import { RouterProps } from '../../../lib/navigation/RouterProps';
import { TaskFilteringOptions, TasksPresenter, TasksView } from './TasksPresenter';
import { TaskSummaryGroupVM, TaskSummaryVM } from './TaskSummaryVM';
import styled from 'styled-components';
import { Loader } from '../../../components/common/Loader';
import { PageContainer } from '../../../components/Layout/PageContainer/PageContainer';
import { DeleteTaskWarning, FinishTaskWarning, ReopenTaskWarning, TaskAlreadyFinishedModal, TaskNotFoundModal } from '../../../components/Modal/Warning/WarningModal';
import { Grain } from './Grain';
import { IconButton, Props as ButtonProps } from '../../../components/buttons/IconButton';
import { Icon } from '../../../components/common/Icon';
import iconTaskFinished from './icon-task-finished.png';
import iconTaskPending from './icon-task-pending.png';
import buttonCheck from '../../../components/buttons/button-small-check.png';
import buttonReopen from '../../../components/buttons/button-small-reopen.png';
import buttonEdit from '../../../components/buttons/button-small-edit.png';
import buttonDelete from '../../../components/buttons/button-small-delete.png';
import { PrimaryButton } from '../../../components/buttons/PrimaryButton';
import { CreateTaskModal } from '../CreateTask/CreateTaskModal';
import { HStack } from '../../../components/common/Stack';
import { DropDown, Option } from '../../../components/forms/DropDown';
import buttonClear from './button-clear.png';

interface State {
    isLoading: boolean;
    tasks: TaskSummaryVM[];
    taskGroups: TaskSummaryGroupVM[];
    filteringOptions: TaskFilteringOptions;
    selectedType: Nullable<Option>,
    selectedGrain: Nullable<Option>;
    selectedField: Nullable<Option>,
    isDeleteTaskModalOpen: boolean;
    deletingTaskId: Nullable<number>;
    isFinishTaskModalOpen: boolean;
    finishingTaskId: Nullable<number>;
    isTaskAlreadyFinishedModalOpen: boolean;
    isTaskNotFoundModalOpen: boolean;
    isCreateTaskOpen: boolean;
    isReopenTaskModalOpen: boolean;
    reopeningTaskId: Nullable<number>;
}

export class TasksPage extends Presentable<TasksPresenter, RouterProps, State> implements TasksView {
    state: State = {
        isLoading: true,
        tasks: [],
        taskGroups: [],
        filteringOptions: { types: [], grains: [], fields: [] },
        selectedType: null,
        selectedField: null,
        selectedGrain: null,
        isDeleteTaskModalOpen: false,
        deletingTaskId: null,
        isFinishTaskModalOpen: false,
        finishingTaskId: null,
        isTaskAlreadyFinishedModalOpen: false,
        isTaskNotFoundModalOpen: false,
        isCreateTaskOpen: false,
        isReopenTaskModalOpen: false,
        reopeningTaskId: null,
    };

    async componentDidMount() {
        await this.presenter.start();
    }

    componentWillUnmount() {
        this.presenter.stop();
    }

    showLoading() { this.setState({ isLoading: true }); }

    showFilteringOptions(options: TaskFilteringOptions) { this.setState({ filteringOptions: options }); }

    private isListFiltered(): boolean {
        return this.state.selectedType != null
            || this.state.selectedGrain != null
            || this.state.selectedField != null;
    }

    showTasks(tasks: TaskSummaryGroupVM[]) { this.setState({ taskGroups: tasks, isLoading: false }); }

    private openDeleteTaskModal(id: number) {
        this.setState({ deletingTaskId: id });
        this.setState({ isDeleteTaskModalOpen: true });
    }

    private closeDeleteTaskModal() { this.setState({ isDeleteTaskModalOpen: false }); }

    private async submitDeleteTask() {
        await this.presenter.delete(this.state.deletingTaskId!);
        this.closeDeleteTaskModal();
    }

    private openFinishTaskModal(id: number) {
        this.setState({ finishingTaskId: id });
        this.setState({ isFinishTaskModalOpen: true });
    }

    private closeFinishTaskModal() { this.setState({ isFinishTaskModalOpen: false }); }

    private async submitFinishTask() {
        await this.presenter.finish(this.state.finishingTaskId!);
        this.closeFinishTaskModal();
    }

    private openReopenTaskModal(id: number) {
        this.setState({ reopeningTaskId: id });
        this.setState({ isReopenTaskModalOpen: true });
    }

    private closeReopenTaskModal() { this.setState({ isReopenTaskModalOpen: false }); }

    private async submitReopenTask() {
        await this.presenter.reopen(this.state.reopeningTaskId!);
        this.closeReopenTaskModal();
    }

    showTaskAlreadyFinishedMessage() { this.setState({ isTaskAlreadyFinishedModalOpen: true }); }

    private closeTaskAlreadyFinishedModal() { this.setState({ isTaskAlreadyFinishedModalOpen: false }); }

    showTaskNotFoundMessage() { this.setState({ isTaskNotFoundModalOpen: true }); }

    private closeTaskNotFoundModal() { this.setState({ isTaskNotFoundModalOpen: false }); }

    showModal() { this.openDeleteTaskModal(this.state.finishingTaskId!); }

    private openCreateTaskModal() { this.setState({ isCreateTaskOpen: true }); }

    private closeCreateTaskModal() { this.setState({ isCreateTaskOpen: false }); }

    private renderHeaderContent() {
        return <CreateTaskButton onClick={() => this.openCreateTaskModal()}>Crear</CreateTaskButton>;
    }

    private renderActionsFor(task: TaskSummaryVM): JSX.Element[] {
        let key = 0;
        if(task.status === 'finished') {
            return [
                <ReopenButton size={18} title="Reabrir" onClick={() => this.openReopenTaskModal(task.id)} key={key++} />,
                <EditButton disabled size={18} title="Debes reabrir la tarea&#10;para poder modificarla" onClick={() => {}} key={key++} />,
                <DeleteButton size={18} title="Borrar" onClick={() => this.openDeleteTaskModal(task.id)} key={key++} />
            ];
        }

        return [
            <FinishButton size={18} title="Finalizar" onClick={() => this.openFinishTaskModal(task.id)} key={key++} />,
            <EditButton size={18} title="Editar" onClick={() => this.props.navigation.redirect('editTask', { id: task.id })} key={key++} />,
            <DeleteButton size={18} title="Borrar" onClick={() => this.openDeleteTaskModal(task.id)} key={key++} />,
        ];
    }

    private renderStatusIcon(task: TaskSummaryVM): JSX.Element {
        const img = task.status === 'finished' ? iconTaskFinished : iconTaskPending;
        return <Icon size={17} url={img} />;
    }

    private renderEmptyList(): JSX.Element {
        return <EmptyList>
            De momento no tienes tareas{this.isListFiltered() && ' que coincidan con los filtros establecidos'}.
        </EmptyList>;
    }

    private renderTaskList(): JSX.Element {
        return <>
            <div className="headers">
                <div className="header status"/>
                <div className="header id">#</div>
                <div className="header date">Fecha</div>
                <div className="header type">Tipo</div>
                <div className="header grain">Grano</div>
                <div className="header field">Lote</div>
                <div className="header cost">Costo total</div>
                <div className="header actions">Acciones</div>
            </div>
            {this.state.taskGroups.map(taskGroup => (
                <div key={taskGroup.label}>
                    <TaskGroupHeader>
                        <DateGroup>
                            <label>{taskGroup.label}</label>
                        </DateGroup>
                    </TaskGroupHeader>
                    <TaskGroupContent>
                        {taskGroup.tasks.map(task => (
                            <div key={task.id} className={`values ${task.status}`}>
                                <div className="selectable" onClick={() => this.presenter.taskDetail(task.id)}>
                                    <div className="value status">{this.renderStatusIcon(task)}</div>
                                    <div className="value id">{task.number}</div>
                                    <div className="value date">{task.startDate}</div>
                                    <div className="value type">{task.typeName}</div>
                                    <div className="value grain"><Grain grain={task.grainName} color={task.grainColor}/></div>
                                    <div className="value field">{task.fieldName}</div>
                                    <div
                                        className="value cost">{task.totalCostPerHectare.amount} {task.totalCostPerHectare.unit}</div>
                                </div>
                                <div className="value actions">{this.renderActionsFor(task)}</div>
                            </div>
                        ))}
                    </TaskGroupContent>
                </div>
            ))}
        </>;
    }

    render() {
        return this.state.isLoading ? <ActivityIndicator /> : <Root>
            <PageContainer title="Tareas" headerContent={this.renderHeaderContent()}>
                <div className="page-content-cover">
                    <Filters>
                        <DropDown placeholder={'Todas las tareas'}
                            options={this.state.filteringOptions.types}
                            value={this.state.selectedType?.value}
                            isClearable={true}
                            onChange={async option => { this.setState({ selectedType: option });
                                await this.presenter.applyFilter({ typeId: option?.value });
                            }}
                        />
                        <DropDown placeholder={'Todos los cultivos'}
                            options={this.state.filteringOptions.grains}
                            value={this.state.selectedGrain?.value}
                            isClearable={true}
                            onChange={async option => {
                                this.setState({ selectedGrain: option });
                                await this.presenter.applyFilter({ grainId: option?.value });
                            }}
                        />
                        <DropDown placeholder={'Todos los lotes'}
                            options={this.state.filteringOptions.fields}
                            value={this.state.selectedField?.value}
                            isClearable={true}
                            onChange={async option => { this.setState({ selectedField: option });
                                await this.presenter.applyFilter({ fieldId: option?.value });
                            }}
                        />
                        <div className="button-container">
                            <IconButton image={buttonClear} size={23} disabled={!this.isListFiltered()}
                                onClick={async () => {
                                    this.setState({ selectedType: null, selectedGrain: null, selectedField: null });
                                    await this.presenter.clearFilters();
                                }}
                            />
                        </div>
                    </Filters>
                    <div className="simulated-top-page-content"/>
                </div>
                <PageBody>
                    {(this.state.taskGroups.isEmpty() && this.renderEmptyList()) || this.renderTaskList()}
                    <Bottom />
                </PageBody>
                <DeleteTaskWarning open={this.state.isDeleteTaskModalOpen} onCancel={() => this.closeDeleteTaskModal()} action={async () => await this.submitDeleteTask()} />
                <FinishTaskWarning open={this.state.isFinishTaskModalOpen} onCancel={() => this.closeFinishTaskModal()} action={async () => await this.submitFinishTask()} />
                <ReopenTaskWarning open={this.state.isReopenTaskModalOpen} onCancel={() => this.closeReopenTaskModal()} action={async () => await this.submitReopenTask()} />
                <TaskAlreadyFinishedModal open={this.state.isTaskAlreadyFinishedModalOpen} action={async () => {
                    await this.presenter.refresh();
                    this.closeTaskAlreadyFinishedModal();
                }} />
                <TaskNotFoundModal open={this.state.isTaskNotFoundModalOpen} action={async () => {
                    await this.presenter.refresh();
                    this.closeTaskNotFoundModal();
                }} />
                <CreateTaskModal showModal={this.state.isCreateTaskOpen} navigation={this.props.navigation} onCancel={() => this.closeCreateTaskModal()} onSubmit={async () => {
                    await this.presenter.refresh();
                    this.closeCreateTaskModal();
                }} />
            </PageContainer>
        </Root>;
    }
}

const Root = styled.div`
  flex-grow: 1;
  flex-shrink: 1;

  .page-content {
    box-shadow: none;
    
    .page-content-cover {
      background-color: #f5f6f8;
      
      .simulated-top-page-content {
        box-shadow: 0px -8px 18px 0 #0000000d;
        background-color: white;
        border-radius: 4px 4px 0 0;
        height: 10px;
      }
    }
  }
`;

const Filters = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 10px;
  gap: 15px;  

  > *:not(:last-child) { flex-basis: 196px; }
  
  .button-container {
    display: flex;
    align-items: center;
  }
`;

const PageBody = styled.div`
  box-shadow: 8px 8px 18px 0 #0000000d;

  .headers, .values {
    align-items: center;
    display: flex;
    flex-direction: row;

    .header, .value {
      flex: none;
      font-size: 12px;
      padding: 0 14px;

      &.status { width: 49px; }
      &.id { width: 68px; }
      &.date { width: 131px; }
      &.type { flex: auto; }
      &.grain { width: 140px; }
      &.field { width: 129px; }
      &.cost { width: 118px; }
      &.actions { width: 120px; }
    }
  }

  .headers {
    padding-top: 2px;

    .header {
      color: #4c5862;
      font-weight: 700;
      line-height: 39px;
      text-transform: uppercase;

      &.id { padding-left: 19px; }
      &.cost { text-align: right; }
      &.actions { text-align: center; }
    }
  }

  .values {
    align-items: center;
    border-top: 1px solid #dce8f1;
    border-right: 1px solid #dce8f1;
    cursor: pointer;

    &:nth-child(even) { background-color: #f3faff; }
    &:last-child { border-bottom: 1px solid #dce8f1; }
    &.finished { background-color: #e2f5ea; }
    &:hover {
      background-color: #f9f9f9;
      &:nth-child(even) { background-color: #edf4ff; }
      &.finished { background-color: #c5eed6; }
    }
    
    .selectable {
      display: flex;
      flex-direction: row;
      flex: auto;
    }

    .value {
      justify-content: center;
      border-left: 1px solid #dce8f1;
      color: #707683;
      display: flex;
      flex-direction: column;
      font-weight: 400;
      height: 44px;

      &.type {
        color: #1b4c74;
        font-weight: 600;
      }

      &.grain { font-weight: 600; }

      &.cost { text-align: right; }

      &.actions {
        align-items: center;
        cursor: default;
        display: flex;
        flex-direction: row;

        .icon-button:not(:first-child) { margin-left: 18px; }
      }
    }
  }
`;

const Bottom = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding: 24px 15px 18px;
`;

const EmptyList = styled.div`
  color: #9ba1ab;
  padding-top: 25px;
  text-align: center;
`;

const CreateTaskButton = styled(PrimaryButton)`
  width: 80px;
  height: 30px;
  font-size: 11px;
  padding: 0;
  line-height: 30px;
`;

const TaskGroupHeader = styled(HStack)`
  height: 47px;
  padding-top: 17px;
  position: relative;

  &:after {
    background-color: #F3C06D;
    content: close-quote;
    height: 1px;
    margin-top: 14px;
    width: 100%;
  }
`;

const TaskGroupContent = styled.div`
  margin-bottom: 14px;
  margin-top: 13px;
`;

const DateGroup = styled(HStack)`
  background-color: white;
  border: 1px solid #F3C06D;
  border-radius: 15px;
  justify-content: center;
  left: 120px;
  position: absolute;
  width: 125px;
  
  label {
    color: #4F4F4F;
    font-size: 11px;
    line-height: 28px;
  }
`;

const ActivityIndicator = styled(Loader)`padding: 100px;`;

const FinishButton: React.FC<ButtonProps> = (props) => <IconButton {...props} image={buttonCheck} />;
const ReopenButton: React.FC<ButtonProps> = (props) => <IconButton {...props} image={buttonReopen} />;
const EditButton: React.FC<ButtonProps> = (props) => <IconButton {...props} image={buttonEdit} />;
const DeleteButton: React.FC<ButtonProps> = (props) => <IconButton {...props} image={buttonDelete} />;
