import { TaskApplicationInfo, TaskService, UpdateApplicationRequest as ServiceRequest } from '../../model/task/TaskService';
import { SupplyUnits } from '../../model/task/SupplyUnits';
import { ApplicationAreas } from '../../model/task/ApplicationAreas';
import { TaskApplication } from '../../model/task/TaskApplication';
import { SessionStorage } from '../../model/user/auth/SessionStorage';
import { Validator } from '../../../../common/validation/Validator';

export class UpdateApplication {
    constructor(private taskService: TaskService, private sessionStorage: SessionStorage) {}

    async exec(request: UpdateApplicationRequest) {
        this.validate(request);
        const session = this.sessionStorage.get()!;
        const planId = session.selectedPlan.id;
        const serviceRequest: ServiceRequest = {
            planId: planId,
            application: this.uneditedApplication(request),
            editedApplication: this.editedApplication(request),
            tasksToBeEdited: request.taskIds,
        };
        await this.taskService.updateApplication(serviceRequest);
    }

    private validate(request: UpdateApplicationRequest) {
        const validator = new Validator();
        validator.check('supplyName', request.supplyName).notNullOrBlank('Debe ingresar un insumo');
        validator.check('dose', request.dose)
            .notNullOrBlank('Debe ingresar una dosis')
            .zeroOrPositive('La dosis debe ser cero o un número positivo');
        validator.check('costPerUnit', request.costPerUnit)
            .notNullOrBlank('Debe ingresar un costo')
            .zeroOrPositive('El costo debe ser cero o un número positivo');
        validator.throwIfHasErrors();
    }

    private uneditedApplication(request: UpdateApplicationRequest): TaskApplicationInfo {
        const application = request.applications.first(a => a.number === request.number);
        return {
            ...application,
            unit: SupplyUnits[application.unit],
            area: ApplicationAreas[application.area]
        };
    }

    private editedApplication(request: UpdateApplicationRequest): TaskApplicationInfo {
        return {
            ...request,
            dose: parseFloat(request.dose),
            unit: SupplyUnits[request.unit],
            area: ApplicationAreas[request.area],
            costPerUnit: parseFloat(request.costPerUnit),
        };
    }
}

export interface UpdateApplicationRequest {
    number: number;
    supplyName: string;
    dose: string;
    unit: SupplyUnits;
    area: ApplicationAreas;
    costPerUnit: string;
    costCenterId: number;
    applications: TaskApplication[];
    taskIds: number[];
}
