import { HttpClient } from '../../../common/httpClient/HttpClient';
import { ApiClient } from '../api/ApiClient';
import { FinancialsService } from '../../app/model/financials/FinancialsService';
import { PlanNotFoundError } from '../../app/model/plan/PlanNotFoundError';
import { FinancialReport } from '../../app/model/financials/FinancialReport';
import { Money } from '../../../common/Money';

export class HttpFinancialsService implements FinancialsService {
    private api: ApiClient;
    private readonly errorMappings = {
        'PlanNotFoundError': PlanNotFoundError,
    };

    constructor(httpClient: HttpClient) {
        this.api = new ApiClient(httpClient, this.errorMappings);
    }

    async getFinancialReport(planId: number): Promise<FinancialReport> {
        const response = await this.api.get(`/financials/getReport?planId=${planId}`);
        const reportJson = response.body;
        return this.financialReportFromJson(reportJson);
    }

    private financialReportFromJson(json): FinancialReport {
        const planReport = json.report.plan;
        return { plan: {
            netIncome: new Money(planReport.netIncome),
            netIncomePerHectare: new Money(planReport.netIncomePerHectare),
            netMargin: new Money(planReport.netMargin),
            netMarginPerHectare: new Money(planReport.netMarginPerHectare),
            totalCost: new Money(planReport.totalCost),
            totalCostPerHectare: new Money(planReport.totalCostPerHectare),
            roi: planReport.roi,
        } };
    }

    async generateSharingToken(planId: number): Promise<string> {
        const response = await this.api.get(`/financials/generateSharingToken?planId=${planId}`);
        return response.body.token;
    }
}
