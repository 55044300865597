import { SessionStorage } from '../../model/user/auth/SessionStorage';
import { FinancialsService } from '../../model/financials/FinancialsService';
import { FinancialReport } from '../../model/financials/FinancialReport';

export class GetFinancialReport {
    constructor(
        private sessionStorage: SessionStorage,
        private financialsService: FinancialsService
    ) {}

    async exec(): Promise<FinancialReport> {
        const planId = this.sessionStorage.get()!.selectedPlan.id;
        return await this.financialsService.getFinancialReport(planId);
    }
}
