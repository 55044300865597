export class CreateTaskFormVM {
    taskTypes: TaskTypeVM[] = [];
    fields: FieldVM[] = [];
    grains: GrainVM[] = [];
    taskTypeId: Nullable<number> = null;
    fieldId: Nullable<number> = null;
    startDate = '';
    grainId: Nullable<number> = null;
    errors: { [property: string]: string } = {};
}

export class TaskTypeVM {
    id = 0;
    name = '';
}

export class FieldVM {
    id = 0;
    name = '';
    canEditGrain = false;
}

export class GrainVM {
    id = 0;
    name = '';
    fieldId = 0;
}
