import React from 'react';
import { FinancialsPresenter, FinancialsView } from './FinancialsPresenter';
import { Presentable } from '../../components/Presentable';
import { RouterProps } from '../../lib/navigation/RouterProps';
import { FinancialsVM } from './FinancialsVM';
import { PageContainer } from '../../components/Layout/PageContainer/PageContainer';
import styled from 'styled-components';
import { HStack } from '../../components/common/Stack';
import iconDownload from './icon-download.png';
import { Icon } from '../../components/common/Icon';
import { Loader } from '../../components/common/Loader';
import { Value } from './Value';
import bottomBackground from './bottom-background.png';

interface State {
    model: FinancialsVM;
}

export class FinancialsPage extends Presentable<FinancialsPresenter, RouterProps, State> implements FinancialsView {
    state: State = {
        model: new FinancialsVM(),
    };

    modelChanged(model: FinancialsVM) {
        this.setState({ model });
    }

    async componentDidMount() {
        await this.presenter.start();
    }

    render() {
        return (
            this.state.model.isLoading ? <ActivityIndicator /> : <PageContainer title="Finanzas">
                <PageBody>
                    <Header>
                        <div className="title">Indicadores de Campaña</div>
                        <SeasonReportButton onClick={e => this.downloadReport(e)} target="_blank" rel="noreferrer">
                            <Icon size={27} url={iconDownload}/>
                            <label>Descargar Reporte</label>
                        </SeasonReportButton>
                    </Header>
                    <Indicators>
                        <Indicator>
                            <div className="label">Ingreso Neto</div>
                            <Value {...this.state.model.netIncome} />
                            <PerHectareValue {...this.state.model.netIncomePerHectare} />
                            <Info title="Ingreso bruto - Costo cosecha - Gastos de comercialización" />
                        </Indicator>
                        <Spacer />
                        <Indicator>
                            <div className="label">Costo Total</div>
                            <Value {...this.state.model.totalCost} />
                            <PerHectareValue {...this.state.model.totalCostPerHectare} />
                            <Info title="Costos directos (tareas) + Alquiler + Costo gerenciamiento productivo" />
                        </Indicator>
                        <Spacer />
                        <Indicator>
                            <div className="label">Margen Neto</div>
                            <Value {...this.state.model.netMargin} />
                            <PerHectareValue {...this.state.model.netMarginPerHectare} />
                            <Info title="Margen bruto - Alquiler - Costo gerenciamiento productivo" />
                        </Indicator>
                        <Spacer />
                        <Indicator>
                            <div className="label">Rentabilidad</div>
                            <Value abbreviatedValue={this.state.model.roi} />
                            <PerHectareValue abbreviatedValue="" />
                            <Info title="Margen neto / (Costos directos  + Alquiler + Gerenciamiento productivo)" />
                        </Indicator>
                    </Indicators>
                    <Bottom>
                        <div className="overlay">
                            <div className="message">
                                Próximamente podrás ver aquí la información comparativa de
                                campañas y comparativa de cultivos que existe en la aplicación movil
                            </div>
                        </div>
                    </Bottom>
                </PageBody>
            </PageContainer>
        );
    }

    private downloadReport(e: React.MouseEvent<HTMLAnchorElement>) {
        e.preventDefault();
        this.presenter.logFinancialReportDownloadedEvent();
        window.open(this.state.model.seasonReportAccess);
    }
}

const PageBody = styled.div`padding-top: 16px;`;

const Header = styled(HStack)`
  align-items: center;
  padding: 4px 20px 15px 20px;

  .title {
    color: #333333;
    flex: auto;
    font-size: 18px;
    font-weight: 600;
  }
`;

const SeasonReportButton = styled.a`
  align-items: center;
  background-color: #f2f4f6;
  border-radius: 10px;
  display: flex;
  flex: none;
  height: 60px;
  padding: 0 15px;
  text-decoration: none;
  width: 180px;
  cursor: pointer;
  
  :hover { background-color: #e1e9f1; }
  
  label {
    font-size: 12px;
    font-weight: 600;
    color: #67748e;
    cursor: pointer; 
  }
  
  i { margin-right: 5px; }
`;

const Indicators = styled(HStack)`
  height: 175px;
  padding: 0 20px;
`;

const Indicator = styled.div`
  border: 1px solid #bdbdbd;
  border-radius: 10px;
  height: 150px;
  flex-basis: 0;
  flex-grow: 20;
  flex-shrink: 20;
  padding: 20px;

  .label {
    color: #72788C;
    font-size: 14px;
    font-weight: 500;
    line-height: 1.2em;
    margin-bottom: 3px;
    text-transform: uppercase;
  }
`;

const PerHectareValue = styled(Value)`
  color: #323C47;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  margin-top: -5px;
  margin-bottom: 7px;

  .abbreviated-value {
    font-size: 18px;
    font-weight: 600;
    margin-right: 5px;

    .abbreviation {
      font-size: 15px;
      font-weight: 400;
    }
  }
`;

const Info = styled.div`
  border: 1px solid #ffb129;
  border-radius: 13px;
  cursor: help;
  display: flex;
  height: 26px;
  justify-content: center;
  width: 26px;

  &:after {
    color: #ffb129;
    content: 'i';
    font-size: 17px;
    font-weight: 600;
  }
`;

const Spacer = styled.div`flex: auto;`;

const Bottom = styled.div`
  background-image: url(${bottomBackground});
  background-repeat: no-repeat;
  height: 175px;
  background-position: center;
  background-size: cover;

  .overlay {
    align-items: center;
    background-color: #ffffffcc;
    display: flex;
    height: 100%;
    justify-content: center;

    .message {
      align-items: center;
      background-color: white;
      border-radius: 10px;
      color: black;
      display: flex;
      font-size: 16px;
      font-weight: 500;
      height: 100px;
      line-height: 1.4em;
      padding: 0 20px;
      text-align: center;
      width: 616px;
    }
  }
`;

const ActivityIndicator = styled(Loader)`padding: 100px;`;
