import { CostCenter } from '../model/task/CostCenter';
import { CostCenterService } from '../model/task/CostCenterService';

export class GetCostCenters {
    private LABOR_COST_CENTER_NAME = 'Labores';

    constructor(private costCenterService: CostCenterService) {}

    async exec(): Promise<CostCenter[]> {
        const costCenters = await this.costCenterService.getAll();
        return costCenters.filter(cc => cc.name !== this.LABOR_COST_CENTER_NAME);
    }
}
