import React from 'react';
import styled from 'styled-components';

interface Props {
    icon: JSX.Element;
    label: string;
    selected?: boolean;
    onClick?: () => void;
    showTooltip?: boolean;
}

export class MenuItem extends React.Component<Props> {
    private onClick(e) {
        e.preventDefault();
        this.props.onClick && this.props.onClick();
    }

    render() {
        return (
            <Root className={this.props.selected ? 'selected' : ''}>
                <a href="#" onClick={ e => this.onClick(e)}>
                    {this.props.icon}
                    <label>{this.props.label}</label>
                </a>
                {this.props.showTooltip && <ToolTip className="tooltip">Próximamente</ToolTip>}
            </Root>
        );
    }
}

const Root = styled.li`
  border-radius: 4px;
  cursor: pointer;
  height: 38px;
  list-style: none;
  padding: 7px 21px;

  a {
    align-items: center;
    display: flex;
    flex-direction: row;
    text-decoration: none;

    label {
      color: #5d636f;
      cursor: pointer;
      font-size: 14px;
      font-weight: 500;
      letter-spacing: -0.05em;
      margin-left: 9px;
    }
  }

  .tooltip { margin-top: 11px; }

  &:not(:hover) > div { display: none; }

&:not(:first-child) { margin-top: 11px; }

  &.selected {
    background-color: #6193b71a;

    a {
      i { background-position-y: bottom }

      label {
        color: #1c4d75;
        font-weight: 600;
        letter-spacing: normal;
      }
    }
  }
`;

const ToolTip = styled.div`
  background-color: #ffface;
  border: 1px solid #fede4b;
  border-radius: 3px;
  box-shadow: 0 0 10px 0 #0000000d;
  color: #807979;
  font-size: 11px;
  font-weight: 500;
  height: 24px;
  line-height: 18px;
  padding: 2px;
  position: relative;
  text-align: center;
  width: 120px;
  z-index: 1;

  &:after {
    border: 6px solid transparent;
    border-bottom-color: #fede4b;
    border-top: 0;
    content: '';
    height: 0;
    left: 50%;
    margin-left: -6px;
    margin-top: -6px;
    position: absolute;
    top: 0;
    width: 0;
  }
`;
