import { PlanInfo } from '../model/plan/PlanInfo';
import { SessionStorage } from '../model/user/auth/SessionStorage';

export class GetSelectedPlan {
    constructor(private sessionStorage: SessionStorage) {}

    exec(): PlanInfo {
        const session = this.sessionStorage.get()!;
        return session.selectedPlan;
    }
}
