import { LocalDate } from '../../../common/time/LocalDate';

export class DateFormatter {
    static shortDate(date: LocalDate | null): string {
        if (date === null) { return ''; }
        return date.day + ' ' + this.shortMonthName(date.month);
    }

    static shortMonthName(month: number): string {
        switch (month) {
            case 1: return 'Ene';
            case 2: return 'Feb';
            case 3: return 'Mar';
            case 4: return 'Abr';
            case 5: return 'May';
            case 6: return 'Jun';
            case 7: return 'Jul';
            case 8: return 'Ago';
            case 9: return 'Sep';
            case 10: return 'Oct';
            case 11: return 'Nov';
            case 12: return 'Dic';
            default: throw new Error('Invalid month: ' + month);
        }
    }

    static monthName(month: number): string {
        switch (month) {
            case 1: return 'Enero';
            case 2: return 'Febrero';
            case 3: return 'Marzo';
            case 4: return 'Abril';
            case 5: return 'Mayo';
            case 6: return 'Junio';
            case 7: return 'Julio';
            case 8: return 'Agosto';
            case 9: return 'Septiembre';
            case 10: return 'Octubre';
            case 11: return 'Noviembre';
            case 12: return 'Diciembre';
            default: throw new Error('Invalid month: ' + month);
        }
    }

    static fullDate(date: LocalDate) {
        return date.day + '/' + date.month + '/' + date.year;
    }

    static fullPaddedDate(date: LocalDate) {
        const day = date.day.toString(10).padStart(2, '0');
        const month = date.month.toString(10).padStart(2, '0');
        return day + '/' + month + '/' + date.year;
    }
}
