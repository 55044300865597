import React, { InputHTMLAttributes } from 'react';
import styled from 'styled-components';

export const RoundedInput: React.FC<Props> = (props) => {
    return <Container className={props.className}>
        <Input {...props} className={props.error ? 'has-error' : ''} />
        {props.error && <Error>{props.error}</Error>}
    </Container>;
};

const Container = styled.div`
  width: 100%;
  align-self: stretch;
`;

const Input = styled.input`
  width: 100%;
  align-self: stretch;
  border: 1px solid #C9C9C9;
  border-radius: 20px;
  color: #1B4C74;
  font-size: 14px;
  line-height: 18px;
  padding: 14px;
  
  &:focus {
    border-color: #2a76b4;
  }

  &.has-error {
    border-color: #CB1E40;
  }
`;

const Error = styled.div`
  color: #CB1E40;
  font-size: 12px;
  margin-top: 2px;
  margin-left: 14px;
`;

interface Props extends InputHTMLAttributes<HTMLInputElement> {
    error?: string;
}
