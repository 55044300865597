import React from 'react';
import styled from 'styled-components';
import { PlanNavView } from './PlanNavPresenter';
import { DropDown, Option } from '../../../forms/DropDown';
import { Navigation } from '../../../../lib/navigation/Navigation';
import AppContext from '../../../../lib/AppContext';
import { Icon } from '../../../common/Icon';
import iconCalendar from './icon-calendar.png';
import { HStack } from '../../../common/Stack';

interface Props {
    navigation: Navigation;
}

interface State {
    options: Option[];
    selectedOption: Option | null;
}

export class PlanNav extends React.Component<Props, State> implements PlanNavView {
    private presenter = AppContext.presenters.planNav(this);

    state: State = {
        options: [],
        selectedOption: null,
    };

    async componentDidMount() {
        await this.presenter.start();
    }

    showOptions(options: Option[], selectedOption: Option) {
        this.setState({ options, selectedOption });
    }

    render() {
        return (
            <Root>
                <Icon size={16} url={iconCalendar}/>
                <DropDown
                    value={this.state.selectedOption?.value}
                    options={this.state.options}
                    onChange={async option => {
                        await this.presenter.select(option.value);
                        this.setState({ selectedOption: option });
                    }}
                />
            </Root>
        );
    }
}

const Root = styled(HStack)`
  align-items: center;
  
  .react-select__control {
    width: 200px;
    
    &, &:hover, &.react-select__control--is-focused {
      border: 1px solid transparent;
    }
    
    .react-select__single-value {
      color: #90A0B7;
      font-weight: 500;
    }

    .react-select__indicator {
      color: #90A0B7;
    }
  }
`;
