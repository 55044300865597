import React from 'react';
import { ModalRender } from '../../../components/Modal/ModalRender';
import styled from 'styled-components';
import { PrimaryButton } from '../../../components/buttons/PrimaryButton';
import { EditApplicationPresenter, EditApplicationView, TaskSuggestionVM } from './EditApplicationPresenter';
import { ApplicationFormVM } from './ApplicationFormVM';
import { Navigation } from '../../../lib/navigation/Navigation';
import { Loader } from '../../../components/common/Loader';
import { LinkButton } from '../../../components/buttons/LinkButton';
import { Input } from '../../../components/forms/Input';
import { FormField } from '../../../components/forms/FormField';
import { DropDown } from '../../../components/forms/DropDown';
import { ModalOverlay } from '../../../components/Modal/ModalOverlay';
import { HStack } from '../../../components/common/Stack';
import { CheckBox } from '../../../components/forms/CheckBox';
import { TaskCard } from './TaskCard';
import AppContext from '../../../lib/AppContext';

interface State {
    form: ApplicationFormVM;
    isLoading: boolean;
    isAllOptionChecked: boolean;
    suggestedTasks: TaskSuggestionVM[];
    isOutOfSyncErrorShown: boolean;
}

interface EditApplicationModalProps {
    taskId: number;
    applicationNumber: Nullable<number>;
    navigation: Navigation;
    showModal: boolean;
    onCancel: () => void;
    onSubmit: () => void;
    onError: (e: Error) => void;
}

export class EditApplicationModal extends React.Component<EditApplicationModalProps, State> implements EditApplicationView {
    private presenter: EditApplicationPresenter = AppContext.presenters.editApplication(this);

    state: State = {
        form: new ApplicationFormVM(),
        isLoading: false,
        isAllOptionChecked: false,
        suggestedTasks: [],
        isOutOfSyncErrorShown: false,
    };

    async componentDidMount() {
        await this.presenter.start(this.props.taskId, this.props.applicationNumber);
    }

    showForm(form: ApplicationFormVM) {
        this.setState({ isLoading: false });
        this.setState({ form });
    }

    showLoading() { this.setState({ isLoading: true }); }

    showTaskSuggestions(tasks: TaskSuggestionVM[], isAllOptionChecked: boolean) {
        this.setState({ suggestedTasks: tasks, isAllOptionChecked });
    }

    showOutOfSyncError() {
        this.setState({ isOutOfSyncErrorShown: true });
    }

    closeLoading() { this.setState({ isLoading: false }); }

    async onSuccessfulSubmit() { await this.props.onSubmit(); }

    async onTaskNotFoundError(e: Error) {
        await this.props.onError(e);
    }

    private isEditing(): boolean { return !!this.state.form.number; }

    renderForm() {
        return <>
            <div className="form">
                <div className="title">
                    {!this.state.isLoading && <label>{ this.isEditing() ? 'Editar' : 'Agregar' } aplicación</label>}
                    <div className="closeModal" onClick={() => this.props.onCancel()}>X</div>
                </div>
                <Row>
                    <FormField label="Insumo" error={this.state.form.errors['supplyName']}>
                        <Input
                            type="text"
                            value={this.state.form.supplyName}
                            onChange={e => this.presenter.setSupplyName(e.target.value) }
                            hasError={'supplyName' in this.state.form.errors}
                        />
                    </FormField>
                </Row>
                <Row className="dose">
                    <FormField label="Dosis" error={this.state.form.errors['dose']}>
                        <Input
                            type="text"
                            value={this.state.form.dose}
                            onChange={e => this.presenter.setDose(e.target.value) }
                            hasError={'dose' in this.state.form.errors}
                        />
                    </FormField>
                    <FormField label="Unidad" error={this.state.form.errors['unit']}>
                        <DropDown
                            options={this.state.form.units.map(o => ({ label: o.label, value: o.key }))}
                            value={this.state.form.unit}
                            onChange={option => this.presenter.setUnit(option.value)}
                            hasError={'unit' in this.state.form.errors}
                        />
                    </FormField>
                    <FormField label="&nbsp;" error={this.state.form.errors['area']}>
                        <DropDown
                            options={this.state.form.areas.map(o => ({ label: o.label, value: o.key }))}
                            value={this.state.form.area}
                            onChange={option => this.presenter.setArea(option.value)}
                            hasError={'area' in this.state.form.errors}
                        />
                    </FormField>
                </Row>
                <Row className="cost">
                    <FormField label="Costo" error={this.state.form.errors['costPerUnit']}>
                        <Input
                            type="text" value={this.state.form.costPerUnit}
                            unit={this.state.form.costUnitLabel}
                            onChange={e => this.presenter.setCostPerUnit(e.target.value) }
                            hasError={'costPerUnit' in this.state.form.errors}
                        />
                    </FormField>
                    <FormField label="Centro de Costos" error={this.state.form.errors['costCenterId']}>
                        <DropDown
                            options={this.state.form.costCenters.map(o => ({ label: o.label, value: o.key }))}
                            value={this.state.form.costCenterId}
                            onChange={option => this.presenter.setCostCenterId(option.value)}
                            hasError={'costCenterId' in this.state.form.errors}
                        />
                    </FormField>
                </Row>
            </div>
        </>;
    }

    render() {
        if (this.props.showModal) {
            return <ModalRender>
                <Modal>
                    <ModalContent>
                        <ModalBody>{this.renderForm()}</ModalBody>
                        <CalculatedCosts>
                            <p>U$S / ha: <span className="highlighted">{this.state.form.totalCostPerHectare}</span></p>
                            <p>U$S / totales: <span className="highlighted">{this.state.form.totalCost}</span></p>
                        </CalculatedCosts>
                        {
                            this.isEditing() && (!this.state.suggestedTasks.isEmpty() || this.state.isOutOfSyncErrorShown) && (
                                <TaskSuggestions>
                                    {
                                        (this.state.isOutOfSyncErrorShown && <div className="error">Alguna de las tareas seleccionadas no pudo modificarse, por favor realiza la selección nuevamente</div>)
                                        || <div className="title">Aplicar también en las siguientes tareas:</div>
                                    }
                                    {!this.state.suggestedTasks.isEmpty() && <>
                                        <HStack className="all-option">
                                            <CheckBox onChange={() => this.presenter.toggleAllOption()} checked={this.state.isAllOptionChecked}/>
                                            <label>Todas</label>
                                        </HStack>
                                        <ul className="task-list">
                                            {this.state.suggestedTasks.map(task => (
                                                <TaskCard key={task.id}
                                                    model={task}
                                                    onToggle={() => this.presenter.toggleSuggestedTask(task.id)}
                                                />
                                            ))}
                                        </ul>
                                    </>}
                                </TaskSuggestions>
                            )
                        }
                        <ModalFooter>
                            <LinkButton onClick={() => this.props.onCancel()}>Cancelar</LinkButton>
                            <PrimaryButton onClick={async () => await this.presenter.submit()}>
                                Guardar
                            </PrimaryButton>
                        </ModalFooter>
                        {this.state.isLoading && <ModalOverlay><Loader /></ModalOverlay> }
                    </ModalContent>
                </Modal>
            </ModalRender>;
        }
        return <></>;
    }
}

const Modal = styled.div`
  align-items: center;
  background-color: #000000bf;
  display: flex;
  height: 100%;
  justify-content: center;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
`;

const ModalContent = styled.div`
  background-clip: padding-box;
  background-color: white;
  border-radius: 0.3rem;
  display: flex;
  flex-direction: column;
  max-height: 100%;
  outline: 0;
  overflow-y: auto;
  pointer-events: auto;
  position: relative;
  width: 486px;
`;

const ModalBody = styled.div`
  display: flex;
  flex-direction: column;
  padding: 25px 26px 0;

  .form {
    .title {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding-bottom: 20px;
      font-size: 18px;
      font-weight: 600;

      .closeModal {
        cursor: pointer;
      }
    }
  }
`;

const ModalFooter = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  padding: 15px 50px 15px 15px;
  border-bottom-right-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem;
  
  p {
    margin-right: 135px;
    color: #f36666;
  }
  
  > a:first-child {
    flex-grow: 0;
    flex-shrink: 0;
    width: 50%;
  }
  
  > a:last-child {
    flex-shrink: 1;
    flex-grow: 1;
    margin-left: 10px;
  }
`;

const Row = styled(HStack)`
  display: flex;
  flex-direction: row;
  
  > div {
    flex-grow: 1;
    flex-shrink: 1;
  }
  
  &.dose {
    > div:not(:last-child) {
      width: 116px;
      flex-grow: 0;
      flex-shrink: 0;
      margin-right: 18px;
    }
  }
  
  &.cost {
    > div:first-child {
      flex-grow: 0;
      flex-shrink: 0;
      margin-right: 18px;
      
      input {
        width: 80px;
      }
    }
  }
`;

const CalculatedCosts = styled.div`
  height: 74px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  background-color: #f9f8fc;
  
    p {
      color: #289B7C;
    }
    .highlighted {
      font-weight: 600;
    }
`;

const TaskSuggestions = styled.div`
  background-color: #f9f8fc;
  margin-top: 5px;
  padding: 22px 31px;

  .title, .error {
    font-size: 16px;
    font-weight: 600;
    line-height: 1.32em;
  }

  .title { color: black; }

  .error { color: red; }

  .all-option {
    align-items: center;
    margin-top: 20px;

    label {
      color: #325e82;
      font-size: 14px;
      font-weight: 600;
      line-height: 1.2em;
      margin-left: 10px;
    }
  }

  .task-list {
    height: 120px;
    margin-top: 6px;
    overflow: auto;
  }
`;
