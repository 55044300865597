import styled from 'styled-components';
import { Size } from '../../lib/Size';

export const Icon = styled.i<{ url: string; size: Size|number; }>`
  background-image: url(${props => props.url});
  background-position-y: top;
  background-size: cover;
  display: block;
  flex: none;
  ${props => size(props.size)};
`;

const size = (size: Size|number): string => {
    const [h, w] = typeof size == 'number' ? [size, size] : [size.h, size.w];
    return `height: ${h}px; width: ${w}px;`;
};
