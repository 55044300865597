import { TaskApplicationInfo, TaskService } from '../../model/task/TaskService';
import { TaskApplication } from '../../model/task/TaskApplication';
import { SupplyUnits } from '../../model/task/SupplyUnits';
import { ApplicationAreas } from '../../model/task/ApplicationAreas';

export interface DeleteTaskApplicationRequest {
    taskId: number;
    applicationNumber: number;
    applications: TaskApplication[];
}

export class DeleteTaskApplication {
    constructor(private taskService: TaskService) {}

    async exec(request: DeleteTaskApplicationRequest): Promise<void> {
        const applications = request.applications.filter(application => application.number !== request.applicationNumber);
        await this.taskService.updateApplications({
            taskId: request.taskId,
            applications: applications.map(this.toTaskApplicationInfo.bind(this))
        });
    }

    private toTaskApplicationInfo(application: TaskApplication): TaskApplicationInfo {
        return {
            supplyName: application.supplyName,
            dose: application.dose,
            unit: SupplyUnits[application.unit],
            area: ApplicationAreas[application.area],
            costPerUnit: application.costPerUnit,
            costCenterId: application.costCenterId
        };
    }
}
