import { TaskSummary } from '../../model/task/TaskSummary';
import { TaskService } from '../../model/task/TaskService';
import { SessionStorage } from '../../model/user/auth/SessionStorage';

export class GetTasks {
    constructor(private taskService: TaskService, private sessionStorage: SessionStorage) {}

    async exec(): Promise<TaskSummary[]> {
        const session = this.sessionStorage.get();
        return this.taskService.getByPlan(session!.selectedPlan.id);
    }
}
