import { SupplyUnits } from '../../../../core/app/model/task/SupplyUnits';
import { ApplicationAreas } from '../../../../core/app/model/task/ApplicationAreas';

export class ApplicationFormVM {
    number: Nullable<number> = null;
    supplyName = '';
    dose = '';
    costPerUnit = '';
    unit: Nullable<SupplyUnits> = null;
    area: Nullable<ApplicationAreas> = null;
    costCenterId: Nullable<number> = null;
    units: SupplyUnitVM[] = [];
    areas: ApplicationAreaVM[] = [];
    costCenters: CostCenterVM[] = [];
    totalCost = '';
    totalCostPerHectare = '';
    errors: { [property: string]: string } = {};
    costUnitLabel = '';
}

interface SupplyUnitVM {
    key: SupplyUnits;
    label: string;
}

interface ApplicationAreaVM {
    key: ApplicationAreas;
    label: string;
}

interface CostCenterVM {
    key: number;
    label: string;
}
