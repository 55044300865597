import React, { ReactElement, useContext } from 'react';
import styled from 'styled-components';
import { MainMenu } from './MainMenu/MainMenu';
import { RouterProps } from '../../lib/navigation/RouterProps';
import { HStack, ReversedHStack, VStack } from '../common/Stack';
import { PlanNav } from './UserMenu/PlanNav/PlanNav';
import { UserNav } from './UserMenu/UserNav/UserNav';
import { MyFarm } from './UserMenu/MyFarm/MyFarm';
import { SessionContext } from '../contexts/SessionContext';

export const Layout: React.FC<RouterProps> = (props): ReactElement => {
    const session = useContext(SessionContext)?.get();

    function farmName(): string {
        return session?.farm.name ? session!.farm.name : '';
    }

    function username(): string {
        return session?.user.screenName ? session!.user.screenName : '';
    }

    function email(): string {
        return session?.user.email ? session!.user.email : '';
    }

    return (
        <Root>
            <MainArea>
                <Header>
                    <MyFarm name={farmName()} />
                    <Separator/>
                    <PlanNav navigation={props.navigation} />
                    <div className="header-right">
                        <UserNav name={username()} email={email()} navigation={props.navigation} />
                    </div>
                </Header>
                <Content>{props.children}</Content>
            </MainArea>
            <MainMenu navigation={props.navigation}/>
        </Root>
    );
};

const Root = styled(ReversedHStack)`
  background-color: #f5f6f8;
  min-height: 100vh;
`;

const MainArea = styled.div`
  flex-grow: 1;
  flex-shrink: 1;
`;

const Header = styled(HStack)`
  height: 75px;
  background-color: white;
  align-items: center;
  padding-left: 52px;

  .header-right {
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    flex-shrink: 1;
    height: 100%;
    justify-content: flex-end;
  }
`;

const Content = styled(VStack)`
  flex-grow: 1;
  flex-shrink: 1;
`;

const Separator = styled.div`
  background-color: #1b4c7480;
  flex-grow: 0;
  flex-shrink: 0;
  height: 27px;
  margin: 0 18px 0 17px;
  width: 1px;
`;
