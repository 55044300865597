export class UserAgent {
    private iOSPatterns = [/iPhone/i, /iP[ao]d/i];
    private androidPatterns = [/Android/i];
    private mobilePatterns = [
        ...this.iOSPatterns,
        ...this.androidPatterns,
        /BlackBerry/i,
        /Windows Phone/i,
    ];

    isMobile(): boolean {
        return this.mobilePatterns.some(pattern => navigator.userAgent.match(pattern));
    }

    isIOS(): boolean {
        return this.iOSPatterns.some(pattern => navigator.userAgent.match(pattern));
    }

    isAndroid(): boolean {
        return this.androidPatterns.some(pattern => navigator.userAgent.match(pattern));
    }
}
