import { TaskService, UpdateTaskRequest as UpdateTaskServiceRequest } from '../../model/task/TaskService';
import { Validator } from '../../../../common/validation/Validator';
import { LocalDate } from '../../../../common/time/LocalDate';

export interface UpdateTaskRequest {
    taskId: number;
    typeId: Nullable<number>;
    startDate: string;
    hectares: string;
    laborCostPerHectare: string;
    assignee: Nullable<string>;
    observations: string;
}

export class UpdateTask {
    constructor(private taskService: TaskService) {}

    async exec(request: UpdateTaskRequest) {
        this.validate(request);
        await this.taskService.update(this.serviceRequestFrom(request));
    }

    private validate(request: UpdateTaskRequest) {
        const validator = new Validator();
        validator.check('typeId', request.typeId).notNull('Debe seleccionar un tipo de tarea');
        validator.check('startDate', request.startDate)
            .date('DD/MM/YYYY', 'La fecha es inválida');
        validator.check('hectares', request.hectares)
            .notNullOrEmpty('Debe ingresar las hectáreas')
            .integer('Las hectáreas deben ser números enteros')
            .zeroOrPositive('Las hectáreas deben ser positivas');
        validator.check('laborCostPerHectare', request.laborCostPerHectare)
            .notNullOrEmpty('Debe ingresar el costo de la labor')
            .zeroOrPositive('El costo de la labor debe ser positivo');
        validator.throwIfHasErrors();
    }

    private serviceRequestFrom(request: UpdateTaskRequest): UpdateTaskServiceRequest {
        return {
            taskId: request.taskId,
            typeId: request.typeId!,
            startDate: this.toLocalDate(request.startDate),
            hectares: parseInt(request.hectares, 10),
            laborCostPerHectare: parseFloat(request.laborCostPerHectare),
            assignee: request.assignee,
            observations: request.observations,
        };
    }

    private toLocalDate(dateString: string): LocalDate {
        const parts = dateString.split('/');
        return LocalDate.of(parseInt(parts[2], 10), parseInt(parts[1], 10), parseInt(parts[0], 10));
    }
}
