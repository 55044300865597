import { SessionStorage } from '../../app/model/user/auth/SessionStorage';
import { Session } from '../../app/model/user/auth/Session';

const SESSION_KEY = 'user_session';

export class BrowserSessionStorage extends SessionStorage {
    store(session: Session) {
        sessionStorage.setItem(SESSION_KEY, JSON.stringify(session));
    }

    get(): Session {
        const json = sessionStorage.getItem(SESSION_KEY);
        return JSON.parse(json!);
    }

    hasSession(): boolean {
        return sessionStorage.getItem(SESSION_KEY) !== null;
    }

    removeSession() {
        sessionStorage.removeItem(SESSION_KEY);
    }
}
