import React from 'react';
import styled from 'styled-components';

interface Props {
    className?: string;
    amount: string;
    unit: string;
}

export const CompositeValue: React.FC<Props> = (props) =>
    <Root className={props.className}>
        <span className="amount">{props.amount}</span> {props.unit}
    </Root>;

const Root = styled.div`
  font-weight: normal;
  color: #828282;

  .amount {
    color: black;
    font-weight: 600;
    margin-right: 10px;
  }
`;
