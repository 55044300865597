import { SessionStorage } from '../model/user/auth/SessionStorage';
import { FinancialsService } from '../model/financials/FinancialsService';

export class GetSeasonReportAccess {
    private readonly apiUrl = '/api';

    constructor(private sessionStorage: SessionStorage, private financialsService: FinancialsService) {}

    async exec(): Promise<GetSeasonReportAccessResponse> {
        const selectedPlanId = this.getSelectedPlanId();
        const token = await this.financialsService.generateSharingToken(selectedPlanId);
        return { access: `${this.apiUrl}/reporte?token=${token}` };
    }

    private getSelectedPlanId() {
        const session = this.sessionStorage.get();
        return session!.selectedPlan.id;
    }
}

export interface GetSeasonReportAccessResponse {
    access: string;
}
