import { SessionStorage } from '../../model/user/auth/SessionStorage';
import { TaskService } from '../../model/task/TaskService';
import { TaskSuggestion } from '../../model/task/TaskSuggestion';
import { Application } from '../../model/task/Application';

export class GetTaskSuggestions {
    constructor(private sessionStorage: SessionStorage, private taskService: TaskService) {}

    async execute(application: Application): Promise<TaskSuggestion[]> {
        const session = this.sessionStorage.get()!;
        return await this.taskService.searchTasksByApplication({ planId: session.selectedPlan.id, application });
    }
}
