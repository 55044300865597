import { TaskApplication } from '../../model/task/TaskApplication';
import { TaskApplicationInfo, TaskService, UpdateApplicationsRequest } from '../../model/task/TaskService';
import { SupplyUnits } from '../../model/task/SupplyUnits';
import { ApplicationAreas } from '../../model/task/ApplicationAreas';
import { Validator } from '../../../../common/validation/Validator';

export interface AddTaskApplicationRequest {
    taskId: number;
    applications: TaskApplication[];
    supplyName: string;
    dose: string;
    unit: SupplyUnits;
    area: ApplicationAreas;
    costPerUnit: string;
    costCenterId: number;
}

export class AddTaskApplication {
    constructor(private taskService: TaskService) {}

    async exec(request: AddTaskApplicationRequest): Promise<void> {
        this.validate(request);
        await this.taskService.updateApplications(this.createServiceRequestFrom(request));
    }

    private validate(request: AddTaskApplicationRequest) {
        const validator = new Validator();
        validator.check('supplyName', request.supplyName).notNullOrBlank('Debe ingresar un insumo');
        validator.check('costCenterId', request.costCenterId).notNull('Debe seleccionar un centro de costos');
        validator.check('unit', request.unit).notNull('Debe seleccionar una unidad');
        validator.check('area', request.area).notNull('Debe seleccionar un área');
        validator.check('dose', request.dose)
            .notNullOrBlank('Debe ingresar una dosis')
            .zeroOrPositive('La dosis debe ser cero o un número positivo');
        validator.check('costPerUnit', request.costPerUnit)
            .notNullOrBlank('Debe ingresar un costo')
            .zeroOrPositive('El costo debe ser cero o un número positivo');
        validator.throwIfHasErrors();
    }

    private createServiceRequestFrom(request: AddTaskApplicationRequest): UpdateApplicationsRequest {
        const addedApplication: TaskApplication = {
            number: request.applications.length + 1,
            supplyName: request.supplyName,
            dose: parseFloat(request.dose),
            unit: request.unit,
            area: request.area,
            costPerUnit: parseFloat(request.costPerUnit),
            costCenterId: request.costCenterId,
        };
        return {
            taskId: request.taskId,
            applications: this.applicationsToApplicationInfo(this.mergeApplications(request, addedApplication)),
        };
    }

    private mergeApplications(request: AddTaskApplicationRequest, addedApplication: TaskApplication): TaskApplication[] {
        request.applications.push(addedApplication);
        return request.applications;
    }

    private applicationsToApplicationInfo(applications: TaskApplication[]) {
        return applications.map((application) => this.toTaskApplicationInfo(application));
    }

    private toTaskApplicationInfo(application: TaskApplication): TaskApplicationInfo {
        return {
            supplyName: application.supplyName,
            dose: application.dose,
            unit: SupplyUnits[application.unit],
            area: ApplicationAreas[application.area],
            costPerUnit: application.costPerUnit,
            costCenterId: application.costCenterId
        };
    }
}
