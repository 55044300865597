import React from 'react';
import styled from 'styled-components';
import { Presentable } from '../../components/Presentable';
import { RouterProps } from '../../lib/navigation/RouterProps';
import { SignUpPresenter, SignUpView } from './SignUpPresenter';
import header from './header.jpg';
import logo from './logo.png';
import arrowDown from './arrow-down.png';
import step1 from './step-1.jpg';
import step2 from './step-2.jpg';
import step3 from './step-3.jpg';
import step4 from './step-4.jpg';
import { VStack } from '../../components/common/Stack';
import { Step } from './Step';

interface State {
}

export class SignUpPage extends Presentable<SignUpPresenter, RouterProps, State> implements SignUpView {
    state: State = {
    };

    render() {
        return <Root>
            <VStack className="header">
                <div className="logo"><img alt="Culti" src={logo} /></div>
                <h1 className="title">Crea tu cuenta</h1>
                <p className="subtitle">Para comenzar a utilizar CULTI en tu computadora debes realizar los sigiuentes pasos</p>
                <img alt="see bellow" src={arrowDown} className="arrow-down" />
            </VStack>
            <VStack className="steps">
                <Step className="step-1" number="1" subtitle="Descarga la aplicaci&oacute;n en tu dispositivo m&oacute;vil">
                    <img alt="1er paso" src={step1} />
                </Step>
                <Step className="step-2" number="2" subtitle="Crea tu cuenta en Culti">
                    <GrayLine />
                    <img alt="2do paso" src={step2} />
                </Step>
                <Step className="step-3" number="3" subtitle="Crea tu campo, al menos un lote y realiza una planificaci&oacute;n inicial">
                    <GrayLine />
                    <img alt="3er paso" src={step3} />
                </Step>
                <Step className="step-4" number="4" title="&iexcl;Listo!" subtitle="Ahora puedes gestionar tu campo desde la computadora o la app.">
                    <img alt="4to paso" src={step4} />
                </Step>
            </VStack>
        </Root>;
    }
}

const Root = styled(VStack)`
  .header {
    align-items: center;
    background: transparent url(${header}) no-repeat top center;
    background-size: cover;
    padding: 80px 0 12px;

    > * { flex: none; }

    .logo {
      flex: auto;

      img {
        margin-right: 20px;
        width: 178px;
      }
    }

    .title, .subtitle {
      color: white;
      flex: none;
      line-height: 1.4em;
    }

    .title {
      font-size: 48px;
      font-weight: 700;
      margin-top: 78px;
      text-transform: uppercase;
    }

    .subtitle {
      font-size: 18px;
      font-weight: 600;
      margin-top: 13px;
      text-align: center;
      max-width: 500px;
    }

    .arrow-down {
      margin-top: 25px;
      width: 26px;
    }
  }

  .steps {
    align-items: center;
    background-color: #f5f6f8;
    padding: 0 30px 100px;

    > * { margin-top: 60px; }
    .step-1 img { margin-top: 15px; width: 608px; }
    .step-2 img { margin-top: 20px; width: 650px; }
    .step-3 img { margin-top: 20px; width: 650px; }
    .step-4 img { margin-top: 15px; width: 673px; }
  }

  @media(max-width: 599px) {
    .header {
      .title { font-size: 30px; }
      .subtitle {
        font-size: 12px;
        max-width: 350px;
      }
    }
  }

  @media(max-width: 349px) {
    .header {
      .title { font-size: 24px; }
      .subtitle { max-width: 250px; }
    }
  }
`;

const GrayLine = styled.div`
  background-color: #c9c9c9;
  height: 1px;
`;
