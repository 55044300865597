import { GetCreateTaskForm } from '../../../../core/app/useCases/task/GetCreateTaskForm';
import { CreateTaskForm } from '../../../../core/app/model/task/TaskService';
import { CreateTask } from '../../../../core/app/useCases/task/CreateTask';
import { Navigation } from '../../../lib/navigation/Navigation';
import { ValidationsError } from '../../../../common/validation/ValidationsError';
import { CreateTaskFormVM } from './CreateTaskFormVM';
import { EventBus } from '../../../../core/infrastructure/eventBus/EventBus';
import { SelectedPlanChanged } from '../../../../core/app/events/SelectedPlanChanged';

export interface CreateTaskView {
    showForm(form: CreateTaskFormVM);
    showLoading();
    closeLoading();
    close();
}

export class CreateTaskPresenter {
    private form: CreateTaskFormVM = new CreateTaskFormVM();

    constructor(
        private view: CreateTaskView,
        private navigation: Navigation,
        private eventBus: EventBus,
        private getForm: GetCreateTaskForm,
        private createTask: CreateTask,
    ) {
        this.eventBus.subscribe(this, SelectedPlanChanged, this.onSelectedPlanChanged.bind(this));
    }

    async start() {
        const response = await this.getForm.exec();
        this.form = this.toFormVM(response);
        this.view.showForm(this.form);
    }

    private toFormVM(fetchedForm: CreateTaskForm): CreateTaskFormVM {
        const form = new CreateTaskFormVM();
        form.taskTypes = fetchedForm.taskTypes;
        form.fields = fetchedForm.fields;
        form.grains = fetchedForm.grains;
        return form;
    }

    async onSelectedPlanChanged() {
        await this.refresh();
    }

    private async refresh() {
        await this.start();
    }

    setField(fieldId: number) {
        this.updateForm({ fieldId });
    }

    setTaskType(taskTypeId: number) {
        this.updateForm({ taskTypeId });
    }

    setStartDate(startDate: string) {
        this.updateForm({ startDate });
    }

    setGrain(grainId: number) {
        if (!this.canEditGrain()) return;
        this.updateForm({ grainId });
    }

    private updateForm<K extends keyof CreateTaskFormVM>(changes: Pick<CreateTaskFormVM, K>) {
        Object.assign(this.form, changes);
        this.view.showForm(this.form);
    }

    async submit() {
        try {
            this.view.showLoading();
            const taskId = await this.createTask.exec({
                taskTypeId: this.form.taskTypeId,
                fieldId: this.form.fieldId,
                startDate: this.form.startDate,
                grainId: this.getGrainId(),
            });
            this.navigation.redirect('editTask', { id: taskId });
            this.view.closeLoading();
        } catch (e) {
            if (e instanceof ValidationsError) {
                this.updateForm({ errors: e.allErrorMessages() });
                return this.view.closeLoading();
            }
            throw e;
        }
    }

    canEditGrain() {
        return this.form.fields.firstOrNull(field => field.id == this.form.fieldId)?.canEditGrain;
    }

    getGrainId(): Nullable<number> {
        return this.canEditGrain() ? this.form.grainId : this.form.grains.firstOrNull(g => g.fieldId === this.form.fieldId)?.id || null;
    }

    stop() {
        this.eventBus.unsubscribe(this);
    }
}
