import { GetSeasonReportAccess } from '../../../core/app/useCases/GetSeasonReportAccess';
import { FinancialsVM } from './FinancialsVM';
import { GetFinancialReport } from '../../../core/app/useCases/financials/GetFinancialReport';
import { Money } from '../../../common/Money';
import { NumberFormatter } from '../../lib/formatters/NumberFormatter';
import { GetUserInfo } from '../../../core/app/useCases/GetUserInfo';
import { EventsLogger } from '../../../core/infrastructure/logs/EventsLogger';

export interface FinancialsView {
    modelChanged(model: FinancialsVM);
}

export class FinancialsPresenter {
    private model = new FinancialsVM();

    constructor(
        private view: FinancialsView,
        private getSeasonReportAccess: GetSeasonReportAccess,
        private getFinancialReport: GetFinancialReport,
        private getUserInfo: GetUserInfo,
        private eventsLogger: EventsLogger,
    ) {}

    async start() {
        this.set({ isLoading: true });
        const seasonReportAccessResponse = await this.getSeasonReportAccess.exec();
        const report = await this.getFinancialReport.exec();
        this.set({
            seasonReportAccess: seasonReportAccessResponse.access,
            isLoading: false,
            netIncome: this.abbreviateIndicator(report.plan.netIncome, 'U$S'),
            netIncomePerHectare: this.abbreviateIndicator(report.plan.netIncomePerHectare, 'U$S/ha'),
            totalCost: this.abbreviateIndicator(report.plan.totalCost, 'U$S'),
            totalCostPerHectare: this.abbreviateIndicator(report.plan.totalCostPerHectare, 'U$S/ha'),
            netMargin: this.abbreviateIndicator(report.plan.netMargin, 'U$S'),
            netMarginPerHectare: this.abbreviateIndicator(report.plan.netMarginPerHectare, 'U$S/ha'),
            roi: NumberFormatter.percentage(report.plan.roi),
        });
    }

    private abbreviateIndicator(indicator: Money, unit: string) {
        const fixedValue = Number(indicator.toNumber().toFixed(1));
        const abbreviatedValue = NumberFormatter.floatAbbreviation(fixedValue);
        return {
            value: fixedValue,
            abbreviatedValue: abbreviatedValue.value,
            abbreviation: abbreviatedValue.abbreviation,
            unit: unit,
        };
    }

    set<K extends keyof FinancialsVM>(changes: Pick<FinancialsVM, K>) {
        this.model = Object.assign(this.model, changes);
        this.view.modelChanged(this.model);
    }

    logFinancialReportDownloadedEvent() {
        const userInfo = this.getUserInfo.exec();
        this.eventsLogger.logCustomEvent('FinancialReportDownloaded', { user: userInfo.email });
    }
}
