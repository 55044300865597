import React from 'react';
import styled from 'styled-components';

interface Props {
    className?: string;
    abbreviatedValue: string;
    abbreviation?: string;
    unit?: string;
}

export const Value: React.FC<Props> = (props) =>
    <Root className={props.className}>
        <span className="abbreviated-value">
            {props.abbreviatedValue}
            <span className="abbreviation">{props.abbreviation}</span>
        </span>
        {props.unit}
    </Root>;

const Root = styled.div`
  color: #333333;
  font-size: 16px;
  font-weight: 400;
  line-height: 36px;

  .abbreviated-value {
    font-size: 36px;
    font-weight: 600;
    margin-right: 7px;

    .abbreviation {
      font-size: 30px;
      font-weight: 400;
    }
  }
`;
