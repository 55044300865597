import React from 'react';
import styled from 'styled-components';
import { HStack, VStack } from '../../../../components/common/Stack';
import { CompositeValue } from '../../CompositeValue';
import { Row } from './Row';

interface Props {
    task: {
        typeName: string;
        laborCostPerHectare: { amount: string; unit: string; };
        totalCostPerHectare: { amount: string; unit: string; };
    };
    apps: {
        number: number;
        supplyName: string;
        dose: { amount: string; unit: string; };
        costPerHectare: { amount: string; unit: string; };
    }[];
}

export const TaskCosts: React.FC<Props> = (props) => {
    return (
        <Root>
            <CostContainer>
                <label>Costo Labores</label>
                <Row label={props.task.typeName}>
                    <StyledCompositeValue amount={props.task.laborCostPerHectare.amount} unit={props.task.laborCostPerHectare.unit} />
                </Row>
            </CostContainer>
            <CostContainer>
                <label>Costo Aplicaciones</label>
                {props.apps.map(app => (
                    <Row key={app.number} label={app.supplyName}>
                        <StyledCompositeValue amount={app.dose.amount} unit={app.dose.unit} />
                        <StyledCompositeValue amount={app.costPerHectare.amount} unit={app.costPerHectare.unit} />
                    </Row>
                ))}
            </CostContainer>
            <TotalCostContainer>
                <label>Costo Total</label>
                <TotalCost amount={props.task.totalCostPerHectare.amount} unit={props.task.totalCostPerHectare.unit} />
            </TotalCostContainer>
        </Root>
    );
};

const Root = styled(VStack)`
  background-color: #f7f7f7;
  border-radius: 3px;
`;

const CostContainer = styled.div`
  padding: 0 21px 11px 17px;

  > label {
    color: #4C5862;
    display: inline-block;
    font-size: 16px;
    font-weight: 600;
    margin-top: 15px;
    margin-bottom: 17px;
  }
`;

const TotalCostContainer = styled(HStack)`
  align-items: center;
  background-color: #eeeeee;
  margin-top: 22px;
  padding: 0 31px 0 32px;

  > label {
    color: #4c5862;
    flex-grow: 1;
    flex-shrink: 1;
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 14px;
    margin-top: 15px;
  }
`;

const TotalCost = styled(CompositeValue)`
  flex-grow: 0;
  flex-shrink: 0;
  font-size: 14px;

  .amount { font-size: 18px; }
`;

const StyledCompositeValue = styled(CompositeValue)`
  flex-grow: 0;
  flex-shrink: 0;
  font-size: 13px;

  .amount {
    font-size: 13px;
    font-weight: normal;
  }
`;
