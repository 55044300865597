import React, { HTMLAttributes } from 'react';
import styled from 'styled-components';
import { VStack } from '../../components/common/Stack';

interface Props extends HTMLAttributes<HTMLDivElement> {
    number: string;
    title?: string;
    subtitle: string;
}

export const Step: React.FC<Props> = (props) =>
    <Root className={props.className}>
        <CircledNumber>{props.number}</CircledNumber>
        <h1 className="text">
            {props.title && <strong>{props.title}<br /></strong>}
            {props.subtitle}
        </h1>
        <div className="content">{props.children}</div>
    </Root>;

const Root = styled(VStack)`
  align-items: center;
  color: #1b4c74;

  .text {
    font-size: 20px;
    font-weight: 500;
    margin-top: 18px;
    text-align: center;
  }

  .content {
    margin-top: 12px;
  }
`;

const CircledNumber = styled.div`
  background-color: #1b4c74;
  border-radius: 45px;
  color: white;
  font-size: 48px;
  font-weight: 700;
  height: 90px;
  line-height: 90px;
  text-align: center;
  width: 90px;
`;
