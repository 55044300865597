export class NumberFormatter {
    static float(value: number, digits = 1): string {
        if (Number.isNaN(value)) { return ''; }
        return Number(value.toFixed(digits)).toLocaleString('en', { maximumFractionDigits: digits });
    }

    static percentage(value: number, digits = 1): string {
        return this.measure(value, '%', digits);
    }

    static measure(value: number, unit: string, digits = 1): string {
        return this.float(value, digits) + ' ' + unit;
    }

    static floatAbbreviation(value: number, digits = 1): Abbreviated {
        if (value / 1000 < 1) { return { value: this.float(value, digits), abbreviation: '' }; }
        if (value / 1000000 < 1) { return { value: this.float(value / 1000, digits), abbreviation: 'K' }; }
        if (value / 1000000000 < 1) { return { value: this.float(value / 1000000, digits), abbreviation: 'M' }; }

        return { value: this.float(value / 1000000000, digits), abbreviation: 'B' };
    }

    static abbreviatedMeasure(value: number, unit: string, digits = 1): AbbreviatedMeasure {
        const abbreviatedValue = this.floatAbbreviation(value, digits);
        return { value: abbreviatedValue.value, abbreviation: abbreviatedValue.abbreviation, unit };
    }
}

interface Abbreviated {
    value: string;
    abbreviation: string;
}

export interface AbbreviatedMeasure {
    value: string;
    abbreviation: string;
    unit: string;
}
