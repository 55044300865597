import React from 'react';
import { IsAuthenticatedFunc } from './ReactRouterNavigation';

export class AuthGate extends React.Component<Props, any> {
    componentDidMount() {
        if (!this.props.isAuthenticated()) {
            this.props.redirectToAuth();
        }
    }

    render() {
        if (!this.props.isAuthenticated()) return null;
        return <>{this.props.children}</>;
    }
}

interface Props {
    isAuthenticated: IsAuthenticatedFunc;
    redirectToAuth: () => void;
}
