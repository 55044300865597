import React from 'react';
import styled from 'styled-components';
import theme from '../../application/theme';

interface Props {
    label: string;
    error?: string|null;
    hint?: string|null;
    className?: string;
}

export const FormField: React.FC<Props> = (props) => (
    <Root className={props.className}>
        <label className={props.error ? 'has-error' : ''}>{props.label}</label>
        {props.children}
        {props.hint && !props.error && <div className="hint-message">{props.hint}</div>}
        {props.error && <div className="error-message">{props.error}</div>}
    </Root>
);

const Root = styled.div`
  min-height: 88px;
  margin-top: 2px;

  label {
    display: block;
    font-size: 13px;
    color: ${theme.forms.field.labelColor};
    font-weight: 500;
    margin-bottom: 3px;

    &.has-error { color: ${theme.errorColor}; }
  }

  .error-message,
  .hint-message {
    font-size: 11px;
    font-weight: 500;
    margin-bottom: 14px;
    margin-top: 5px;
    padding-right: 5px;
  }

  .error-message {
    color: ${theme.errorColor};
    padding-left: 14px;
  }

  .hint-message {
    color: ${theme.forms.field.hintMessageColor};
  }
`;
