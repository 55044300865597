import styled from 'styled-components';
import { VStack } from './Stack';

export const Avatar = styled(VStack)<{ color: string; size: number; }>`
  align-items: center;
  background-color: ${props => props.color};
  border-radius: ${props => props.size / 2}px;
  flex: none;
  height: ${props => props.size}px;
  justify-content: center;
  width: ${props => props.size}px;

  > * {
    display: block;
    flex-grow: 0;
    flex-shrink: 0;
  }
`;
