import React from 'react';
import styled from 'styled-components';
import theme from '../application/theme';

interface Props { className?: string; error?: string; }
export const FormGeneralError: React.FC<Props> = ({ className, error }) => error ? <Root {...className}>{error}</Root> : null;

const Root = styled.div`
  color: ${theme.errorColor};
  font-size: 14px;
`;
