import React from 'react';
import styled from 'styled-components';
import { Presentable } from '../../../components/Presentable';
import { RouterProps } from '../../../lib/navigation/RouterProps';
import { EditTaskPresenter, EditTaskView } from './EditTaskPresenter';
import { PageContainer } from '../../../components/Layout/PageContainer/PageContainer';
import { Loader } from '../../../components/common/Loader';
import { PrimaryButton } from '../../../components/buttons/PrimaryButton';
import { FormGeneralError } from '../../../components/FormGeneralError';
import { LinkButton } from '../../../components/buttons/LinkButton';
import { FormField } from '../../../components/forms/FormField';
import { Input } from '../../../components/forms/Input';
import { DateInput } from '../../../components/forms/DateInput';
import { TextArea } from '../../../components/forms/TextArea';
import { DropDown } from '../../../components/forms/DropDown';
import { EditApplicationModal } from '../EditApplication/EditApplicationModal';
import { TaskInfoRow } from './TaskInfoRow';
import { TaskEditFormVM } from './TaskEditFormVM';
import { CompositeValue } from '../CompositeValue';
import { HStack, VStack } from '../../../components/common/Stack';
import { Modal } from '../../../components/Modal/Modal';
import { DeleteButton, EditButton } from '../../../components/buttons/IconButton';
import { CannotEditTaskModal } from '../../../components/Modal/Warning/WarningModal';
import { TaskNotFoundError } from '../../../../core/app/model/task/TaskNotFoundError';

interface State {
    form: TaskEditFormVM;
    isLoading: boolean;
    showEditApplicationModal: boolean;
    showDeleteApplicationModal: boolean;
    editingTaskApplicationId: Nullable<number>;
    deletingTaskApplicationId: Nullable<number>;
    isCannotEditTaskModalOpen: boolean;
    isCannotUpdateTaskModalOpen: boolean;
}

export class EditTaskPage extends Presentable<EditTaskPresenter, RouterProps, State> implements EditTaskView {
    state: State = {
        form: new TaskEditFormVM(),
        isLoading: true,
        showEditApplicationModal: false,
        showDeleteApplicationModal: false,
        editingTaskApplicationId: null,
        deletingTaskApplicationId: null,
        isCannotEditTaskModalOpen: false,
        isCannotUpdateTaskModalOpen: false,
    };

    async componentDidMount() { await this.presenter.start(); }

    showLoading() { this.setState({ isLoading: true }); }

    showForm(form: TaskEditFormVM) { this.setState({ form, isLoading: false }); }

    showEditApplicationForm(applicationId: number) {
        this.setState({ editingTaskApplicationId: applicationId });
        this.showEditApplicationModal();
    }

    showAddApplicationForm() {
        this.setState({ editingTaskApplicationId: null });
        this.showEditApplicationModal();
    }

    showCannotEditTaskMessage() {
        this.setState({ isCannotEditTaskModalOpen: true });
    }

    showCannotUpdateTaskMessage() {
        this.setState({ isCannotUpdateTaskModalOpen: true, isLoading: false });
    }

    private showEditApplicationModal() { this.setState({ showEditApplicationModal: true }); }

    private closeEditApplicationModal() { this.setState({ showEditApplicationModal: false }); }

    private closeDeleteApplicationModal() { this.setState({ showDeleteApplicationModal: false }); }

    private async submitEditApplicationModal() {
        await this.presenter.refreshApplications();
        this.setState({ showEditApplicationModal: false });
    }

    private showDeleteApplicationModal(id: number) {
        this.setState({ deletingTaskApplicationId: id });
        this.setState({ showDeleteApplicationModal: true });
    }

    private async submitDeleteApplications() {
        await this.presenter.deleteApplication(this.state.deletingTaskApplicationId!);
        this.closeDeleteApplicationModal();
    }

    renderEditApplicationModal() {
        return <EditApplicationModal
            taskId={this.state.form.taskId}
            applicationNumber={this.state.editingTaskApplicationId}
            showModal={this.state.showEditApplicationModal}
            navigation={this.props.navigation}
            onCancel={() => this.closeEditApplicationModal()}
            onSubmit={async () => await this.submitEditApplicationModal()}
            onError={(e: Error) => { this.handleError(e); }}
        />;
    }

    private handleError(e: Error) {
        if(!(e instanceof TaskNotFoundError)) throw e;
        this.closeEditApplicationModal();
        this.showCannotUpdateTaskMessage();
    }

    renderDeleteApplicationModal() {
        return <Modal
            title={'¿Desea eliminar la aplicación?'}
            showModal={this.state.showDeleteApplicationModal}
            onCancel={() => this.closeDeleteApplicationModal()}
            onSubmit={async () => await this.submitDeleteApplications()}
        />;
    }

    render() {
        return this.state.isLoading ? <ActivityIndicator /> : (
            <PageContainer
                title={`Edición tarea #${this.state.form.number.toString().padStart(3, '0')}`}
                navigation={this.props.navigation}
            >
                {this.renderBody()}
                {this.state.showEditApplicationModal && this.renderEditApplicationModal()}
                {this.state.showDeleteApplicationModal && this.renderDeleteApplicationModal()}
                <CannotEditTaskModal open={this.state.isCannotEditTaskModalOpen} action={() => this.props.navigation.goBack() } />
                <CannotEditTaskModal open={this.state.isCannotUpdateTaskModalOpen} action={() => this.navigateToTasks() }
                    content={<p>La tarea no fue actualizada ya que ha sido finalizada o eliminada previamente.</p>}
                    actionLabel="Ir al listado"
                />
            </PageContainer>
        );
    }

    private navigateToTasks() { this.props.navigation.redirect('tasks'); }

    renderBody() {
        return (
            <PageBody>
                <Form>
                    <FormGeneralError className="general-error" error={this.state.form.errors['']} />
                    <HStack className="columns">
                        <VStack className="column">
                            <FormField label="Tipo de tarea" error={this.state.form.errors['typeId']}>
                                {this.state.form.canEditType ? (
                                    <DropDown
                                        options={this.state.form.taskTypes.map(o => ({ label: o.name, value: o.id }))}
                                        value={this.state.form.typeId}
                                        onChange={option => this.presenter.setTaskTypeId(option.value)}
                                        hasError={'typeId' in this.state.form.errors}
                                    />
                                ) : (
                                    <Input type="text" readOnly value={this.state.form.typeName} />
                                )}
                            </FormField>
                            <FormField label="Fecha" className="field-date" error={this.state.form.errors['startDate']} hint={`*sugerida: ${this.state.form.suggestedStartDate}`}>
                                <DateInput
                                    value={this.state.form.startDate}
                                    onChange={e => this.presenter.setStartDate(e.target.value)}
                                    hasError={'startDate' in this.state.form.errors}
                                />
                            </FormField>
                        </VStack>
                        <VStack className="column">
                            <FormField label="Lote">
                                <Input type="text" readOnly value={this.state.form.fieldName} />
                            </FormField>
                            <FormField label="Superficie" className="field-area" error={this.state.form.errors['hectares']}>
                                <Input
                                    type="text"
                                    value={this.state.form.hectares}
                                    unit="has"
                                    onChange={e => this.presenter.setHectares(e.target.value)}
                                    hasError={'hectares' in this.state.form.errors}
                                />
                            </FormField>
                        </VStack>
                        <VStack className="column">
                            <FormField label="Cultivo">
                                <Input type="text" readOnly value={this.state.form.grainName} />
                            </FormField>
                        </VStack>
                    </HStack>
                </Form>
                <TaskCosts>
                    <CostContainer>
                        <label>Costo Labores</label>
                        <TaskInfoRow label={this.state.form.typeName} buttons={[]}>
                            <Input
                                type="text"
                                value={this.state.form.laborCostPerHectare}
                                unit="U$S/ha"
                                onChange={e => this.presenter.setLaborCostPerHectare(e.target.value)}
                                hasError={'laborCostPerHectare' in this.state.form.errors}
                            />
                        </TaskInfoRow>
                    </CostContainer>
                    <CostContainer>
                        <label>Costo Aplicaciones</label>
                        {this.state.form.applications.map(application => (
                            <TaskInfoRow key={application.id} label={application.supplyName} buttons={[
                                <EditButton title="modificar" size={24} onClick={() => this.presenter.editApplication(application.id)} key="0" />,
                                <DeleteButton title="eliminar" size={24} onClick={() => this.showDeleteApplicationModal(application.id)} key="1" />,
                            ]}>
                                <StyledCompositeValue className="dose" amount={application.dose.amount} unit={application.dose.unit} />
                                <StyledCompositeValue className="dose" amount={application.cost.amount} unit={application.cost.unit} />
                            </TaskInfoRow>
                        ))}
                    </CostContainer>
                    <Link onClick={() => this.presenter.addApplication()}>+ Agregar aplicación</Link>
                    <TotalCostContainer>
                        <label>Costo Total</label>
                        <TotalCost amount={this.state.form.totalCostPerHectare.amount} unit={this.state.form.totalCostPerHectare.unit} />
                    </TotalCostContainer>
                </TaskCosts>
                <div className="footer">
                    <label>Responsable:</label>
                    <FormField label="">
                        <Input
                            type="text"
                            placeholder="Nombre"
                            value={this.state.form.assignee ?? ''}
                            onChange={e => this.presenter.setResponsible(e.target.value)}
                        />
                    </FormField>
                    <label>Observaciones:</label>
                    <FormField className="full-width" label="" error={this.state.form.errors['observations']}>
                        <TextArea
                            value={this.state.form.observations}
                            onChange={e => this.presenter.setObservations(e.target.value)}
                            rows={6}
                        />
                    </FormField>
                </div>
                <Actions>
                    <LinkButton onClick={() => this.props.navigation.goBack()}>Cancelar</LinkButton>
                    <PrimaryButton onClick={async () => await this.presenter.submit()}>Guardar</PrimaryButton>
                </Actions>
            </PageBody>
        );
    }
}

const PageBody = styled.div`
  padding-left: 7px;
  padding-right: 12px;
  position: relative;

  .footer {
    font-size: 18px;
    margin-bottom: 4px;
    padding: 28px 21px 0 20px;

    > label {
      color: black;
      display: block;
      font-size: 16px;
      font-weight: 400;
      margin-bottom: 12px;
    }
    
    input {
      width: 50%;
    }
  }
`;

const Form = styled.div`
  padding: 0 8px 11px 13px;

  .general-error {
    font-weight: 600;
    margin: 20px 0 1px;
  }

  .columns {
    gap: 34px;
    padding-top: 27px;

    .column {
      flex-grow: 1;
      flex-shrink: 1;
      flex-basis: 0;

      .field-date { width: 145px; }
      .field-area { width: 113px; }
    }
  }
`;

const TaskCosts = styled.div`
  background-color: #f7f7f7;
  border-radius: 3px;
`;

const CostContainer = styled.div`
  padding: 0 21px 11px 17px;

  > label {
    color: #4C5862;
    display: inline-block;
    font-size: 16px;
    font-weight: 600;
    margin-top: 15px;
    margin-bottom: 17px;
  }

  .applications {
    display: flex;
    flex-direction: column;
  }
`;

const TotalCostContainer = styled(HStack)`
  align-items: center;
  background-color: #eeeeee;
  margin-top: 27px;
  padding: 0 31px 0 32px;

  > label {
    color: #4c5862;
    flex-grow: 1;
    flex-shrink: 1;
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 14px;
    margin-top: 15px;
  }
`;

const TotalCost = styled(CompositeValue)`
  flex-grow: 0;
  flex-shrink: 0;
  font-size: 14px;
  margin-right: 176px;

  .amount { font-size: 18px; }
`;

const StyledCompositeValue = styled(CompositeValue)`
  &.dose {
    color: #828282;
    flex-grow: 0;
    flex-shrink: 0;
    font-size: 13px;

    .amount {
      font-size: 13px;
      font-weight: normal;
    }
  }
`;

const Link = styled.a`
  color: #1b4c74;
  cursor: pointer;
  display: block;
  font-weight: 600;
  font-size: 14px;
  margin-right: 29px;
  margin-top: 12px;
  text-decoration: underline;
  text-align: right;

  &:hover { color: #2a76b4; }
`;

const Actions = styled(HStack)`
  justify-content: flex-end;
  padding: 34px 21px 39px;

  > a { width: 295px; }
`;

const ActivityIndicator = styled(Loader)`padding: 100px;`;
