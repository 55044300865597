import React from 'react';
import styled from 'styled-components';
import Select from 'react-select';
import theme from '../../application/theme';

export interface Option {
    label: string;
    value: string|number;
}

interface Props {
    options: Option[];
    placeholder?: string;
    value?: string|number|null;
    onChange?: (Option) => void;
    hasError?: boolean;
    disabled?: boolean;
    isClearable?: boolean;
}

export const DropDown: React.FC<Props> = (props) => {
    return (
        <_Select isDisabled={props.disabled}
            options={props.options}
            className={props.hasError ? 'has-error' : ''}
            classNamePrefix="react-select"
            isSearchable={false}
            isClearable={props.isClearable}
            placeholder={props.placeholder || 'Seleccione'}
            value={props.options.find(o => o.value == props.value)}
            onChange={props.onChange}
        />
    );
};

const _Select = styled(Select)`
  .react-select__control {
    height: 45px;
    border-radius: ${theme.forms.field.input.borderRadius};
    border: 1px solid #bdbdbd;
    cursor: pointer;
    
    &.react-select__control--is-focused {
        border: 1px solid #276fa9;
        box-shadow: none;
    }

    &.react-select__control--is-disabled {
      .react-select__dropdown-indicator { color: ${theme.errorColor}; }
    }
  }

  .react-select__value-container {
    height: 43px;
    font-size: 13px;
    color: #828282;
    padding-left: 9px;
    padding-right: 4px;

    .react-select__single-value {
      color: ${theme.forms.field.input.color};
      padding-bottom: 1px;
    }
  }
  
  .react-select__indicator-separator {
    display: none;
  }
  
  .react-select__option {
    font-size: 13px;
    color: #828282;
    padding-left: 13px;
    padding-right: 13px;
    cursor: pointer;
    
    &.react-select__option--is-focused { background-color: #d7eeff; }
    &.react-select__option--is-selected { 
      background-color: #FFFFFF;
      font-weight: 500; 
    }
  }
  
  .react-select__dropdown-indicator {
    color: ${theme.forms.field.dropdown.indicatorColor};
    margin-right: 4px;

    &:hover {
      color: #696F79;
    } 
  }
  
  &.has-error {
    .react-select__control {
        background-color: ${theme.forms.field.input.backgroundColorError};
        border-color: ${theme.errorColor};
    }
  }
`;
