import { Measure } from '../Measure';
import { TaskStatuses } from '../../../../core/app/model/task/TaskStatuses';

export class TaskVM {
    id = 0;
    number = '';
    status: TaskStatuses = TaskStatuses.Pending;
    typeName = '';
    grainName = ' ';
    grainColor = 'white';
    fieldName = '';
    startDate = '';
    assignee = '';
    hectares = '';
    laborCostPerHectare: Measure = { amount: '', unit: '' };
    totalCostPerHectare: Measure = { amount: '', unit: '' };
    observations = '';
    applications: TaskApplicationVM[] = [];
}

export interface TaskApplicationVM {
    number: number;
    supplyName: string;
    dose: Measure;
    costPerHectare: Measure;
}
