import { HttpClient } from './HttpClient';
import axios, { AxiosAdapter, AxiosError, AxiosInstance, AxiosResponse } from 'axios';
import { HttpResponse } from './HttpResponse';
import { AxiosHttpResponse } from './AxiosHttpResponse';
import { HttpMethod } from './HttpMethod';
import { HttpError } from './HttpError';
import { AxiosUrlHelper } from './AxiosUrlHelper';
import { NetworkError } from './NetworkError';
import { AuthenticationError } from './AuthenticationError';

export class AxiosHttpClient implements HttpClient {
    private readonly http: AxiosInstance;
    private readonly baseUrl: string;

    constructor(baseUrl: string, axiosAdapter: AxiosAdapter|undefined = axios.defaults.adapter) {
        this.baseUrl = baseUrl;
        this.http = axios.create({
            baseURL: baseUrl,
            adapter: axiosAdapter,
            withCredentials: true,
            headers: {
                'Client-App': 'web',
            },
        });
    }

    async get<T = any>(url: string): Promise<HttpResponse<T>> {
        try {
            const response = await this.http.get(url);
            return new AxiosHttpResponse<T>(response);
        } catch(e) {
            return this.handleError(e, HttpMethod.GET, url);
        }
    }

    async post<T = any>(url: string, data: any = null): Promise<HttpResponse<T>> {
        try {
            const response = await this.http.post(url, data);
            return new AxiosHttpResponse<T>(response, data);
        } catch(e) {
            return this.handleError(e, HttpMethod.POST, url);
        }
    }

    async put<T = any>(url: string, data: any = null): Promise<HttpResponse<T>> {
        try {
            const response = await this.http.put(url, data);
            return new AxiosHttpResponse<T>(response, data);
        } catch(e) {
            return this.handleError(e, HttpMethod.PUT, url);
        }
    }

    async delete<T = any>(url: string): Promise<HttpResponse<T>> {
        try {
            const response = await this.http.delete(url);
            return new AxiosHttpResponse<T>(response);
        } catch(e) {
            return this.handleError(e, HttpMethod.DELETE, url);
        }
    }

    async patch<T = any>(url: string, data: any = null): Promise<HttpResponse<T>> {
        try {
            const response = await this.http.patch(url, data);
            return new AxiosHttpResponse<T>(response, data);
        } catch(e) {
            return this.handleError(e, HttpMethod.PATCH, url);
        }
    }

    async head<T = any>(url: string): Promise<HttpResponse<T>> {
        try {
            const response = await this.http.head(url);
            return new AxiosHttpResponse<T>(response);
        } catch(e) {
            return this.handleError(e, HttpMethod.HEAD, url);
        }
    }

    private handleError<T>(e: AxiosError, method: HttpMethod, url: string): Promise<HttpResponse<T>> {
        const urlHelper = new AxiosUrlHelper(this.baseUrl, url);
        const absoluteUrl = urlHelper.absoluteUrl;
        this.handleNetworkError(e, method, absoluteUrl);
        if (!e.response) throw e;
        this.handleAuthenticationError(e, method, absoluteUrl);
        throw this.createHttpError(method, absoluteUrl, e.response);
    }

    private handleNetworkError(e: AxiosError<any>, method: HttpMethod, absoluteUrl: string) {
        if (!this.isNetworkError(e)) return;
        throw this.createNetworkError(method, absoluteUrl, e);
    }

    private isNetworkError(e: AxiosError): boolean {
        if (e.message === 'Network Error') return true;
        if (!e.response) return false;
        return [502, 503, 504].contains(e.response.status);
    }

    private createNetworkError(method: HttpMethod, url: string, e: AxiosError) {
        return new NetworkError(method.toString() + ' ' + url, e.response ? e.response.status : null);
    }

    private handleAuthenticationError(e: AxiosError<any>, method: HttpMethod, absoluteUrl: string) {
        if (!e.response || !this.isAuthenticationError(e.response)) return;
        throw this.createAuthenticationError(method, absoluteUrl, e.response);
    }

    private isAuthenticationError(response: AxiosResponse): boolean {
        return [401, 403].contains(response.status);
    }

    private createAuthenticationError(method: HttpMethod, url: string, response: AxiosResponse) {
        return new AuthenticationError(method, url, response.status, response.statusText, response.data, response.headers);
    }

    private createHttpError(method: HttpMethod, url: string, response: AxiosResponse) {
        return new HttpError(method, url, response.status, response.statusText, response.data, response.headers);
    }
}
