import React, { ChangeEventHandler, KeyboardEventHandler } from 'react';
import styled from 'styled-components';

export const TextArea: React.FC<Props> = (props) => (
    <StyledTextArea {...props} className={props.hasError ? 'has-error' : ''} />
);

interface Props {
    readOnly?: boolean;
    hasError?: boolean;
    type?: string;
    rows?: number;
    value?: string | ReadonlyArray<string> | number;
    onChange?: ChangeEventHandler<HTMLTextAreaElement>;
    onKeyDown?: KeyboardEventHandler<HTMLTextAreaElement>;
}

const StyledTextArea = styled.textarea`
  min-height: 45px;
  width: 100%;
  border-radius: 8px;
  border: 1px solid #bdbdbd;
  font-size: 13px;
  line-height: 19px;
  padding: 13px;
  color: #828282;
  resize: none;
    
  &:focus {
    border-color: #276fa9;
  }
  
  &.has-error {
    border-color: #CB1E40;
    background-color: #FDEFF2;
  }
    
  &[readonly] {
    background-color: #F9F9F9;
    border: 1px solid #E0E0E0;
    color: #828282;
  }
`;