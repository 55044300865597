import React from 'react';
import styled from 'styled-components';
import { RouterProps } from '../../../lib/navigation/RouterProps';
import { Presentable } from '../../../components/Presentable';
import { HStack, VStack } from '../../../components/common/Stack';
import { Loader } from '../../../components/common/Loader';
import { PageContainer } from '../../../components/Layout/PageContainer/PageContainer';
import { PrimaryButton } from '../../../components/buttons/PrimaryButton';
import { LinkButton } from '../../../components/buttons/LinkButton';
import { DeleteTaskWarning, FinishTaskWarning, ReopenTaskWarning, TaskAlreadyFinishedModal, TaskNotFoundModal } from '../../../components/Modal/Warning/WarningModal';
import { CompositeValue } from '../CompositeValue';
import { TaskDetailPresenter, TaskDetailView } from './TaskDetailPresenter';
import { TaskProperty } from './TaskProperty';
import { GrainIcon } from './GrainIcon';
import { TaskCosts } from './TaskCosts/TaskCosts';
import { TaskVM } from './TaskVM';
import { CheckButton, DeleteButton, EditButton, ReopenButton } from '../../../components/buttons/IconButton';
import { TaskStatuses } from '../../../../core/app/model/task/TaskStatuses';
import iconCheck from './icon-check.png';

interface State {
    isLoading: boolean,
    task: TaskVM,
    isDeleteModalOpen: boolean,
    isFinishTaskModalOpen: boolean;
    isReopenTaskModalOpen: boolean;
    isTaskAlreadyFinishedModalOpen: boolean;
    isTaskNotFoundModalOpen: boolean;
}

export class TaskDetailPage extends Presentable<TaskDetailPresenter, RouterProps, State> implements TaskDetailView {
    state: State = {
        task: new TaskVM(),
        isLoading: true,
        isDeleteModalOpen: false,
        isFinishTaskModalOpen: false,
        isReopenTaskModalOpen: false,
        isTaskAlreadyFinishedModalOpen: false,
        isTaskNotFoundModalOpen: false,
    };

    async componentDidMount() { await this.presenter.start(); }

    showLoading() { this.setState({ isLoading: true }); }

    showTask(task: TaskVM) { this.setState({ task, isLoading: false }); }

    showTaskAlreadyFinishedMessage() { this.setState({ isTaskAlreadyFinishedModalOpen: true }); }

    private closeTaskAlreadyFinishedModal() { this.setState({ isTaskAlreadyFinishedModalOpen: false }); }

    showTaskNotFoundMessage() { this.setState({ isTaskNotFoundModalOpen: true }); }

    private editTask(id: number) { this.props.navigation.redirect('editTask', { id }); }

    render() {
        const task = this.state.task;
        return this.state.isLoading ? <ActivityIndicator /> : (
            <PageContainer title={`Tarea ${task.number}`} navigation={this.props.navigation}>
                <PageBody>
                    <StyledHStack>
                        <StatusAndProperties>
                            {task.status === TaskStatuses.Finished &&
                                <TaskStatus>
                                    <StatusFinished>
                                        <Checkmark />
                                        <label>Tarea finalizada</label>
                                    </StatusFinished>
                                </TaskStatus>
                            }
                            <Properties>
                                <TaskProperty label="Tipo" className="property-type">{task.typeName}</TaskProperty>
                                <TaskProperty label="Lote" className="property-field">{task.fieldName}</TaskProperty>
                                <TaskProperty label="Grano" className="property-grain">
                                    <GrainIcon className="grain-icon" name={task.grainName} color={task.grainColor}/>
                                    <div className="grain-name">{task.grainName}</div>
                                </TaskProperty>
                                <TaskProperty label="Fecha de inicio" className="property-date">{task.startDate}</TaskProperty>
                                <TaskProperty label="Responsable" className="property-assignee" placeholder="sin responsable asignado">{task.assignee}</TaskProperty>
                                <TaskProperty label="Superficie" className="property-area"><Measure amount={task.hectares} unit="has"/></TaskProperty>
                            </Properties>
                        </StatusAndProperties>
                        <TaskActions>{this.renderActionsFor(task)}</TaskActions>
                    </StyledHStack>
                    <TaskCosts task={task} apps={task.applications}/>
                    <Properties>
                        <TaskProperty label="Observaciones" placeholder="sin observaciones">{task.observations}</TaskProperty>
                    </Properties>
                    <Actions>
                        <LinkButton onClick={() => this.props.navigation.goBack()}>Volver</LinkButton>
                        {this.renderEditButtonFor(task)}
                    </Actions>
                </PageBody>
                <DeleteTaskWarning open={this.state.isDeleteModalOpen} onCancel={() => this.closeDeleteTaskModal()}
                    action={async () => await this.submitDeleteTask()}
                />
                <FinishTaskWarning open={this.state.isFinishTaskModalOpen} onCancel={() => this.closeFinishTaskModal()}
                    action={async () => await this.submitFinishTask()}
                />
                <ReopenTaskWarning open={this.state.isReopenTaskModalOpen}
                    onCancel={() => this.closeReopenTaskModal()}
                    action={async () => {
                        await this.presenter.reopen();
                        this.closeReopenTaskModal();
                    }}
                />
                <TaskAlreadyFinishedModal open={this.state.isTaskAlreadyFinishedModalOpen}
                    action={async () => {
                        await this.presenter.refresh();
                        this.closeTaskAlreadyFinishedModal();
                    }}
                />
                <TaskNotFoundModal open={this.state.isTaskNotFoundModalOpen}
                    action={async () => {
                        await this.presenter.redirectToTasks();
                    }}
                    actionLabel="Volver al listado"
                />
            </PageContainer>
        );
    }

    private renderActionsFor(task: TaskVM) {
        let key = 0;
        if (task.status === TaskStatuses.Finished) { return [
            <ReopenButton size={27} label="Reabrir" onClick={() => this.openReopenTaskModal()} key={key++} />,
            <EditButton disabled size={27} label="Editar" title="Debes reabrir la tarea&#10;para poder modificarla" onClick={() => {}} key={key++} />,
            <DeleteButton size={27} label="Borrar" onClick={() => this.openDeleteTaskModal()} key={key++} />,
        ]; }
        return [
            <CheckButton size={27} label="Finalizar" onClick={() => this.openFinishTaskModal()} key={key++} />,
            <EditButton size={27} label="Editar" onClick={() => this.editTask(task.id)} key={key++} />,
            <DeleteButton size={27} label="Borrar" onClick={() => this.openDeleteTaskModal()} key={key++} />,
        ];
    }

    private renderEditButtonFor(task: TaskVM) {
        if (task.status === TaskStatuses.Finished) {
            return <PrimaryButton disabled title="debes reabrir la tarea&#10;para poder modificarla" onClick={() => {}}>Editar</PrimaryButton>;
        }
        return <PrimaryButton onClick={() => this.editTask(task.id)}>Editar</PrimaryButton>;
    }

    private openDeleteTaskModal() {
        this.setState({ isDeleteModalOpen: true });
    }

    private closeDeleteTaskModal() {
        this.setState({ isDeleteModalOpen: false });
    }

    private async submitDeleteTask() {
        await this.presenter.delete();
        this.closeDeleteTaskModal();
    }

    private openFinishTaskModal() {
        this.setState({ isFinishTaskModalOpen: true });
    }

    private openReopenTaskModal() {
        this.setState({ isReopenTaskModalOpen: true });
    }

    private closeReopenTaskModal() {
        this.setState({ isReopenTaskModalOpen: false });
    }

    private closeFinishTaskModal() {
        this.setState({ isFinishTaskModalOpen: false });
    }

    private async submitFinishTask() {
        await this.presenter.finish();
        this.closeFinishTaskModal();
    }
}

const PageBody = styled(VStack)`
  padding-left: 7px;
  padding-right: 12px;
  position: relative;
`;

const Properties = styled(VStack)`
  color: #323c47;
  font-size: 16px;
  line-height: 40px;
  padding: 27px 26px 24px;

  .property-type, .property-field, .property-grain {
    font-size: 18px;

    .property-value { font-size: 16px; }
  }
  
  .property-date { margin-top: 3px; }

  .property-grain {
    .property-value {
      padding-left: 1px;

      .grain-icon {
        font-size: 18px;
        font-weight: 600;
      }

      .grain-name {
        font-size: 14px;
        font-weight: normal;
        margin-left: 12px;
        text-transform: uppercase;
      }
    }
  }
`;

const Measure = styled(CompositeValue)`
  font-size: 16px;
  font-weight: 300;

  .amount { margin-right: 0; }
`;

const TaskActions = styled(HStack)`
  align-items: center;
  background-color: #f2f4f6;
  border-radius: 10px;
  flex-grow: 0;
  flex-shrink: 0;
  height: 68px;
  justify-content: center;
  padding: 0 15px;

  .icon-button {
    width: 74px;
    padding-top: 4px;
  }
`;

const Actions = styled(HStack)`
  justify-content: flex-end;
  padding: 34px 21px 39px;

  > a { width: 295px; }
`;

const ActivityIndicator = styled(Loader)`padding: 100px;`;

const TaskStatus = styled(HStack)`
  border-radius: 10px;
  flex-grow: 1;
  flex-shrink: 1;
  margin-right: 12px;
  background-color: #289B7C;
  height: 68px;
  justify-content: flex-start;
  align-items: center;
`;

const StyledHStack = styled(HStack)`
  margin: 20px 20px;
`;

const StatusAndProperties = styled(VStack)`
    flex-grow: 1;
    flex-shrink: 1;
`;

const StatusFinished = styled(HStack)`
  align-items: center;
  color: white;
  font-size: 18px;
  font-weight: 600;
`;

const Checkmark = styled.i`
  background-image: url(${ iconCheck });
  background-size: cover;
  display: block;
  flex: none;
  width: 18px;
  height: 15px;
  margin: 0 13px 0 21px;
`;
