import { LocalDateTime } from './LocalDateTime';

export class LocalDate {
    private readonly _day: number;
    private readonly _month: number;
    private readonly _year: number;

    constructor(year: number, month: number, day: number) {
        this._year = year;
        this._month = month;
        this._day = day;
    }

    get day(): number {
        return this._day;
    }

    get month(): number {
        return this._month;
    }

    get year(): number {
        return this._year;
    }

    toDate(): Date {
        return new Date(this.year, this.month - 1, this.day, 0, 0, 0, 0);
    }

    atTime(hours: number, minutes: number, seconds: number): LocalDateTime {
        return LocalDateTime.of(this.year, this.month, this.day, hours, minutes, seconds);
    }

    equals(other: LocalDate | null): boolean {
        if (other === null) return false;
        return this.day === other.day && this.month === other.month && this.year === other.year;
    }

    static of(year: number, month: number, day: number) {
        return new LocalDate(year, month, day);
    }
}
