import { HttpResponse } from './HttpResponse';
import { HttpMethod } from './HttpMethod';
import { AxiosResponse } from 'axios';
import { AxiosUrlHelper } from './AxiosUrlHelper';

export class AxiosHttpResponse<T = any> implements HttpResponse<T> {
    private readonly _method: HttpMethod;
    private readonly _status: number;
    private readonly _body: T;
    private readonly _baseUrl: string;
    private readonly _relativeUrl: string;
    private readonly _absoluteUrl: string;
    private readonly _headers: { [key: string]: string };
    private readonly _requestData: any;

    constructor(axiosResponse: AxiosResponse, requestData: any = null) {
        this._method = this.parseMethod(axiosResponse.config.method);
        this._status = axiosResponse.status;
        this._body = axiosResponse.data;
        const urlHelper = new AxiosUrlHelper(axiosResponse.config.baseURL, axiosResponse.config.url);
        this._baseUrl = urlHelper.baseUrl;
        this._relativeUrl = urlHelper.relativeUrl;
        this._absoluteUrl = urlHelper.absoluteUrl;
        this._headers = axiosResponse.headers;
        this._requestData = requestData;
    }

    get status() {
        return this._status;
    }

    get baseUrl() {
        return this._baseUrl;
    }

    get url() {
        return this._absoluteUrl;
    }

    get relativeUrl() {
        return this._relativeUrl;
    }

    get method() {
        return this._method;
    }

    get body() {
        return this._body;
    }

    get headers() {
        return this._headers;
    }

    get requestData() {
        return this._requestData;
    }

    private parseMethod(method: string | undefined) {
        switch (method?.toUpperCase()) {
            case 'GET':
                return HttpMethod.GET;
            case 'POST':
                return HttpMethod.POST;
            case 'PUT':
                return HttpMethod.PUT;
            case 'PATCH':
                return HttpMethod.PATCH;
            case 'DELETE':
                return HttpMethod.DELETE;
            case 'HEAD':
                return HttpMethod.HEAD;
            default:
                return HttpMethod.UNKNOWN;
        }
    }
}
