import React from 'react';
import styled from 'styled-components';
import { HStack } from '../../../components/common/Stack';

interface Props {
    className?: string;
    label: string;
    placeholder?: string;
}

export const TaskProperty: React.FC<Props> = (props) => {
    const children = props.children
        || (props.placeholder && <Placeholder>{props.placeholder}</Placeholder>)
        || '';

    return (
        <Root className={props.className}>
            <Label className="property-label">{props.label}:</Label>
            <Value className="property-value">{children}</Value>
        </Root>
    );
};

const Root = styled(HStack)`
`;

const Label = styled(HStack)`
  align-items: center;
  flex-shrink: 0;
  flex-grow: 0;
  font-weight: normal;
  width: 160px;
`;

const Value = styled(HStack)`
  align-items: center;
  flex-shrink: 1;
  flex-grow: 1;
  font-weight: 600;
  margin-left: 8px;
`;

const Placeholder: React.FC = (props) => <LightText>&lt;{props.children}&gt;</LightText>;
const LightText = styled.span`color: #bdbdbd;`;
