import Big from 'big.js';

export class Money {
    private readonly _value: Big;

    constructor(value: number|Big) {
        this._value = new Big(value);
    }

    toNumber(): number {
        return this._value.toNumber();
    }

    toString(): string {
        return this._value.toString();
    }

    plus(value: Money): Money {
        return new Money(this._value.plus(value._value));
    }

    minus(value: Money): Money {
        return new Money(this._value.minus(value._value));
    }

    multipliedBy(value: number): Money {
        return new Money(this._value.times(value));
    }

    dividedBy(value: number): Money {
        return new Money(this._value.div(value));
    }

    dividedByMoney(value: Money): number {
        return this._value.div(value._value).toNumber();
    }

    static fromString(value: string): Money {
        return new Money(parseFloat(value));
    }

    static Zero(): Money {
        return new Money(0);
    }
}
