import React from 'react';
import styled from 'styled-components';
import theme from '../../application/theme';
import icon from './icon-check-mark-white.png';

interface Props {
    checked?: boolean;
    readOnly?: boolean;
    hasError?: boolean;
    onChange: (state: boolean) => void;
}

export const CheckBox: React.FC<Props> = (props) => {
    const classNames: string[] = [];
    props.readOnly && classNames.push('read-only');
    props.hasError && classNames.push('has-error');
    props.checked && classNames.push('checked');

    return (
        <Root onClick={() => !props.readOnly && props.onChange(!props.checked)} className={classNames.join(' ')}>
            {props.checked && <img src={icon} height={15} width={15} />}
        </Root>
    );
};

const Root = styled.div`
  background-color: ${theme.forms.field.input.backgroundColor};
  border-color: ${theme.forms.field.input.borderColor};
  border-radius: ${theme.forms.field.checkbox.borderRadius};
  border-style: ${theme.forms.field.input.borderStyle};
  border-width: ${theme.forms.field.input.borderWidth};
  flex-grow: 0;
  flex-shrink: 0;
  height: ${theme.forms.field.checkbox.size};
  width: ${theme.forms.field.checkbox.size};

  &.checked {
    background-color: #1B4C74;
    border: none;
    padding-top: 3px;
    padding-left: 3px;
  }

  &.has-error {
    border-color: ${theme.errorColor};
    .check-mark { background-color: ${theme.errorColor}; }
  }

  &.read-only {
    background-color: ${theme.forms.field.input.readOnly.backgroundColor};
    border-color: ${theme.forms.field.input.readOnly.borderColor};
    .check-mark { background-color: ${theme.forms.field.checkbox.readOnly.color}; opacity: 0.4; }
  }
`;
