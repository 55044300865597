import React from 'react';
import styled from 'styled-components';
import { VStack } from './Stack';

interface Props { className?: string; }
export const Loader: React.FC<Props> = props => <Root {...props}><Spinner /></Root>;

const Root = styled(VStack)`
  align-items: center;
  height: 100%;
  justify-content: center;
  width: 100%;
`;

const Spinner = styled.div`
  animation: spin 0.7s linear infinite;
  -webkit-animation: spin 0.7s linear infinite;
  border-color: #1B4C74 transparent;
  border-radius: 50%;
  border-style: solid;
  border-width: 4px;
  flex: none;
  height: 30px;
  margin: 0 auto;
  width: 30px;  

  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }

  @-webkit-keyframes spin {
    0% { -webkit-transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); }
  } 
`;
