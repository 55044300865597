import { HttpClient } from '../../../common/httpClient/HttpClient';
import { ApiClient } from '../api/ApiClient';
import { InvalidCredentialsError } from '../../app/model/user/auth/error/InvalidCredentialsError';
import { UnverifiedEmailError } from '../../app/model/user/auth/error/UnverifiedEmailError';
import { SetupStatusResponse, UserService } from '../../app/model/user/UserService';
import { User } from '../../app/model/user/User';
import { SetupStatuses } from '../../app/model/user/SetupStatuses';
import { InvalidEmailError } from '../../app/model/user/auth/error/InvalidEmailError';

export class HttpUserService implements UserService {
    private api: ApiClient;
    private readonly errorMappings = {
        'InvalidCredentialsError': InvalidCredentialsError,
        'UnverifiedEmailError': UnverifiedEmailError,
        'InvalidEmailError': InvalidEmailError,
    };

    constructor(httpClient: HttpClient) {
        this.api = new ApiClient(httpClient, this.errorMappings);
    }

    async login(email: string, password: string): Promise<User> {
        const response = await this.api.post('/login', { email, password });
        return response.body.userInfo;
    }

    async setupStatus(): Promise<SetupStatusResponse> {
        const response = await this.api.get('/me/setupStatus');
        return {
            ...response.body,
            status: this.setupStatusFromString(response.body.status),
        };
    }

    private setupStatusFromString(status: string): SetupStatuses {
        switch (status) {
            case 'farm-missing': return SetupStatuses.FarmMissing;
            case 'plan-missing': return SetupStatuses.PlanMissing;
            case 'finished': return SetupStatuses.Finished;
            default: throw new Error(`Invalid setup status "${status}"`);
        }
    }
}
