import React, { KeyboardEvent } from 'react';
import { LoginPresenter, LoginView } from './LoginPresenter';
import { LoginFormVM } from './LoginFormVM';
import { Presentable } from '../../components/Presentable';
import { RouterProps } from '../../lib/navigation/RouterProps';
import { UserAgent } from '../../lib/UserAgent';
import farm from './farm.jpg';
import logo from './logo.png';
import mobileFarm from './mobile-farm.jpg';
import mobileLogo from './mobile-logo.png';
import mobileAppStore from './mobile-app-store.png';
import mobileGooglePlay from './mobile-google-play.png';
import styled from 'styled-components';
import { RoundedInput } from './RoundedInput';
import { PrimaryButton } from '../../components/buttons/PrimaryButton';
import { FormGeneralError } from '../../components/FormGeneralError';
import { Loader } from '../../components/common/Loader';

interface State {
    isLoading: boolean;
    form: LoginFormVM;
}

export class LoginPage extends Presentable<LoginPresenter, RouterProps, State> implements LoginView {
    private userAgent = new UserAgent();
    private appStoreUrl = 'https://apps.apple.com/us/app/culti/id1541043499';
    private googlePlayUrl = 'https://play.google.com/store/apps/details?id=ar.com.culti&hl=es_AR&gl=US';

    state: State = {
        isLoading: false,
        form: new LoginFormVM(),
    };

    showForm(form: LoginFormVM) {
        this.setState({ form, isLoading: false });
    }

    showLoading() {
        this.setState({ isLoading: true });
    }

    private onInputKeyDown(e: KeyboardEvent) {
        if (e.key === 'Enter') {
            e.preventDefault();
            this.presenter.submit();
        }
    }

    private rootClasses(): string {
        const classes: string[] = [];
        this.userAgent.isMobile() && classes.push('mobile');
        this.userAgent.isIOS() && classes.push('ios');
        this.userAgent.isAndroid() && classes.push('android');
        return classes.join(' ');
    }

    render() {
        return <Root className={this.rootClasses()}>
            <div className="web-content">
                <LoginImage />
                <LoginForm>{this.state.isLoading ? <StyledLoader /> : this.renderForm()}</LoginForm>
            </div>
            <div className="mobile-content">
                <img alt="Culti" src={mobileLogo} className="mobile-logo" />
                <div className="store-access">
                    <p>Para comenzar a utilizar</p>
                    <p>Culti desc&aacute;rgate la aplicaci&oacute;n</p>
                    <a href={this.appStoreUrl} className="button-app-store"><img alt="App Store" src={mobileAppStore} /></a>
                    <a href={this.googlePlayUrl} className="button-google-play"><img alt="Google Play" src={mobileGooglePlay} /></a>
                </div>
                <div>
                    <p>Luego de finalizar la planificaci&oacute;n de</p>
                    <p>una campa&ntilde;a podr&aacute;s gestionar tu</p>
                    <p>campo desde tu computadora</p>
                    <p>ingresando a <strong>culti.ag</strong></p>
                </div>
            </div>
        </Root>;
    }

    renderForm() {
        return <>
            <Logo src={logo} />
            <StyledFormGeneralError error={this.state.form.errors['']} />
            <StyledRoundedInput
                placeholder="Email"
                type="text"
                onChange={e => this.presenter.setEmail(e.target.value)}
                onKeyDown={e => this.onInputKeyDown(e) }
                value={this.state.form.email}
                error={this.state.form.errors['email']}
            />
            <StyledRoundedInput
                placeholder="Contrase&ntilde;a"
                type="password"
                onChange={e => this.presenter.setPassword(e.target.value)}
                onKeyDown={e => this.onInputKeyDown(e) }
                value={this.state.form.password}
                error={this.state.form.errors['password']}
            />
            <StyledPrimaryButton onClick={() => this.presenter.submit()}>Ingresar</StyledPrimaryButton>
            <p className='sign-up-access'>
                &iquest;Nuevo en Culti? <a href="/sign-up">Crea tu cuenta</a>
            </p>
        </>;
    }
}

const Root = styled.div`
  background-color: #546675;
  min-height: 100vh;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  .web-content {
    width: 800px;
    display: flex;
    background-color: white;
    height: 530px;
    border-radius: 10px;
  }

  .mobile-content {
    align-items: center;
    display: none;
    flex-direction: column;
    padding-top: 45px;

    .mobile-logo {
      margin-bottom: 13px;
      width: 200px;
    }

    > div {
      align-items: center;
      background-color: #ffffffcc;
      border-radius: 10px;
      color: #1b4c74;
      display: flex;
      flex-direction: column;
      font-size: 14px;
      font-weight: 500;
      line-height: 1.36em;
      margin-top: 12px;
      padding: 12px 23px;
      text-align: center;
      width: 87%;

      &.store-access {
        font-size: 15px;
        font-weight: 600;
        line-height: 1.4em;
        padding: 25px 42px;

        .button-app-store,
        .button-google-play {
          display: block;
          margin-top: 18px;

          img {
            height: 50px;
            width: 148px;
          }
        }
      }
    }
  }

  &.mobile {
    background: transparent url(${mobileFarm}) no-repeat center center;
    background-size: cover;
    display: block;

    .web-content { display: none; }
    .mobile-content { display: flex; }

    &.android .store-access .button-app-store,
    &.ios .store-access .button-google-play { display: none; }
  }

  @media(max-width: 599px) {
    background: transparent url(${mobileFarm}) no-repeat center center;
    background-size: 600px;
    display: block;

    .web-content { display: none; }
    .mobile-content { display: flex; }

    &.android .store-access .button-app-store,
    &.ios .store-access .button-google-play { display: none; }
  }
`;

const LoginImage = styled.div`
  width: 400px;
  height: 530px;
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: 400px;
  background: transparent url(${farm}) no-repeat top left;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
`;

const LoginForm = styled.div`
  width: 400px;
  flex-grow: 1;
  flex-basis: 400px;
  display: flex;
  flex-direction: column;
  padding: 0 63px;
  align-items: center;

  .sign-up-access {
    color: #1b4c74;
    font-size: 13px;
    line-height: 21px;
    margin-top: 21px;

    a {
      color: #1b4c74;
      font-weight: 600;
    }
  }
`;

const Logo = styled.img`
  margin-top: 84px;
  margin-bottom: 45px;
`;

const StyledRoundedInput = styled(RoundedInput)`
  margin-bottom: 26px;
`;

const StyledPrimaryButton = styled(PrimaryButton)`
  align-self: stretch;
  margin-top: 40px;
`;

const StyledFormGeneralError = styled(FormGeneralError)`
  font-weight: 500;
  margin-bottom: 20px;
`;

const StyledLoader = styled(Loader)`flex-grow: 1;`;
