import { GetTasks } from '../../../../core/app/useCases/task/GetTasks';
import { TaskSummary } from '../../../../core/app/model/task/TaskSummary';
import { TaskDateFormatter } from '../../../lib/formatters/TaskDateFormatter';
import { TaskSummaryGroupVM, TaskSummaryVM } from './TaskSummaryVM';
import { Navigation } from '../../../lib/navigation/Navigation';
import { DeleteTask } from '../../../../core/app/useCases/task/DeleteTask';
import { TaskNotFoundError } from '../../../../core/app/model/task/TaskNotFoundError';
import { TaskNumberFormatter } from '../../../lib/formatters/TaskNumberFormatter';
import { NumberFormatter } from '../../../lib/formatters/NumberFormatter';
import { FinishTask } from '../../../../core/app/useCases/task/FinishTask';
import { InvalidOperationError } from '../../../../core/app/model/task/InvalidOperationError';
import { TaskStatuses } from '../../../../core/app/model/task/TaskStatuses';
import { ReopenTask } from '../../../../core/app/useCases/task/ReopenTask';
import { EventBus } from '../../../../core/infrastructure/eventBus/EventBus';
import { SelectedPlanChanged } from '../../../../core/app/events/SelectedPlanChanged';
import { Option } from '../../../components/forms/DropDown';
import { SearchTasks, SearchTasksRequest } from '../../../../core/app/useCases/task/SearchTasks';

export interface TasksView {
    showTasks(tasks: TaskSummaryGroupVM[]);
    showFilteringOptions(options: TaskFilteringOptions);
    showLoading();
    showModal();
    showTaskAlreadyFinishedMessage();
    showTaskNotFoundMessage();
}

export interface TaskFilteringOptions {
    types: Option[];
    grains: Option[];
    fields: Option[];
}

export class TasksPresenter {
    private searchRequest: SearchTasksRequest = { grainId: null, typeId: null, fieldId: null };

    constructor(
        private view: TasksView,
        private navigation: Navigation,
        private eventBus: EventBus,
        private getTasks: GetTasks,
        private searchTasks: SearchTasks,
        private deleteTask: DeleteTask,
        private finishTask: FinishTask,
        private reopenTask: ReopenTask,
    ) {
        this.eventBus.subscribe(this, SelectedPlanChanged, this.onSelectedPlanChanged.bind(this));
    }

    async start() {
        this.view.showLoading();
        await this.refresh();
    }

    async refresh() {
        const tasks = await this.fetchTasks();
        this.generateFilteringOptions(tasks);
        this.showTasks(tasks);
    }

    private showTasks(tasks: TaskSummary[]) {
        this.view.showTasks(this.tasksGroupedByMonth(tasks));
    }

    private tasksGroupedByMonth(tasks: TaskSummary[]): TaskSummaryGroupVM[] {
        const groups: { [key: string]: TaskSummaryVM[] } = {};
        const maxTaskNumber = Math.max(...tasks.map(task => task.number));
        tasks.forEach(task => {
            const model = this.taskSummaryToVM(task, maxTaskNumber);
            const key = TaskDateFormatter.monthNameAndYearFrom(task);
            if (!groups[key]) groups[key] = [];
            groups[key].push(model);
        });
        return Object.entries(groups).map(entry => ({ label: entry[0], tasks: entry[1] }));
    }

    private async fetchTasks(): Promise<TaskSummary[]> { return this.getTasks.exec(); }

    async onSelectedPlanChanged() { await this.refresh(); }

    private taskSummaryToVM(task: TaskSummary, maxTaskNumber: number): TaskSummaryVM {
        const zeroPadLength = Math.max(3, maxTaskNumber.toString().length);
        return {
            ...task,
            status: TaskStatuses[task.status].toLowerCase(),
            number: TaskNumberFormatter.format(task.number, zeroPadLength),
            startDate: TaskDateFormatter.format(task),
            totalCostPerHectare: {
                amount: NumberFormatter.float(task.totalCostPerHectare, 2),
                unit: 'U$S/ha'
            },
        };
    }

    private generateFilteringOptions(tasks: TaskSummary[]) {
        const types = this.generateOptions(tasks, 'typeName', 'typeId');
        const grains = this.generateOptions(tasks, 'grainName', 'grainId');
        const fields = this.generateOptions(tasks, 'fieldName', 'fieldId');
        this.view.showFilteringOptions({ types, grains, fields });
    }

    private generateOptions(tasks: TaskSummary[], labelKey: string, valueKey: string): Option[] {
        const indexedOptions: { [key: string]: Option } = {};
        tasks.forEach(t => { indexedOptions[t[valueKey]] = { label: t[labelKey], value: t[valueKey] }; });
        return Object.entries(indexedOptions).map(entry => entry[1]);
    }

    async applyFilter(filter: Record<string, number>) {
        this.view.showLoading();
        this.searchRequest = { ...this.searchRequest, ...filter };
        const tasks = await this.searchTasks.exec(this.searchRequest);
        this.showTasks(tasks);
    }

    async clearFilters() {
        this.view.showLoading();
        this.searchRequest = { grainId: null, typeId: null, fieldId: null };
        const tasks = await this.searchTasks.exec(this.searchRequest);
        this.showTasks(tasks);
    }

    taskDetail(taskId: number) {
        this.navigation.redirect('taskDetail', { id: taskId });
    }

    async delete(taskId: number) {
        try {
            await this.tryDelete(taskId);
        } catch (e) {
            if(e instanceof TaskNotFoundError) return this.refresh();
            throw e;
        }
    }

    private async tryDelete(taskId: number) {
        await this.deleteTask.exec(taskId);
        await this.refresh();
    }

    async finish(taskId: number) {
        try {
            await this.tryFinish(taskId);
        } catch (e) {
            if(e instanceof InvalidOperationError) return this.view.showTaskAlreadyFinishedMessage();
            if(e instanceof TaskNotFoundError) return this.view.showTaskNotFoundMessage();
            throw e;
        }
    }

    private async tryFinish(taskId: number) {
        await this.finishTask.exec(taskId);
        await this.refresh();
    }

    async reopen(taskId: number) {
        try {
            await this.tryReopen(taskId);
        } catch (e) {
            if(e instanceof InvalidOperationError) return this.refresh();
            if(e instanceof TaskNotFoundError) return this.view.showTaskNotFoundMessage();
            throw e;
        }
    }

    private async tryReopen(taskId: number) {
        await this.reopenTask.exec(taskId);
        await this.refresh();
    }

    stop() {
        this.eventBus.unsubscribe(this);
    }
}
