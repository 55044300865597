import { HttpClient } from '../../../common/httpClient/HttpClient';
import { HttpResponse } from '../../../common/httpClient/HttpResponse';
import { apiErrorMapper } from './apiErrorMapper';
import { LocalDate } from '../../../common/time/LocalDate';
import { TimeZone } from '../../../common/time/TimeZone';

export class ApiClient {
    private httpClient: HttpClient;
    private timezone: TimeZone;
    private readonly errorMappings;

    constructor(httpClient: HttpClient, errorMappings: Record<string, any> = {}, timezone: TimeZone = new TimeZone) {
        this.httpClient = httpClient;
        this.timezone = timezone;
        this.errorMappings = errorMappings;
    }

    async get<T = any>(url: string): Promise<HttpResponse<T>> {
        return apiErrorMapper(async () => {
            return await this.httpClient.get<T>(url);
        }, this.errorMappings);
    }

    async post<T = any>(url: string, data: any): Promise<HttpResponse<T>> {
        return apiErrorMapper(async () => {
            return await this.httpClient.post<T>(url, data);
        }, this.errorMappings);
    }

    toDate(date: LocalDate): number {
        return Date.UTC(date.year, date.month - 1, date.day, 0, 0, 0 , 0) / 1000;
    }

    fromDateTimestamp(timestamp: number|null): LocalDate|null {
        if (timestamp == null) { return null; }
        const date = new Date(timestamp * 1000);
        return LocalDate.of(date.getUTCFullYear(), date.getUTCMonth() + 1, date.getUTCDate());
    }
}
