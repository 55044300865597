import { TaskSummary } from '../../model/task/TaskSummary';
import { TaskService } from '../../model/task/TaskService';
import { SessionStorage } from '../../model/user/auth/SessionStorage';

export class SearchTasks {
    constructor(private taskService: TaskService, private sessionStorage: SessionStorage) {}

    async exec(request: SearchTasksRequest): Promise<TaskSummary[]> {
        const planId = this.sessionStorage.get()!.selectedPlan.id;
        return await this.taskService.search({
            planId,
            grainId: request.grainId,
            typeId: request.typeId,
            fieldId: request.fieldId,
        });
    }
}

export interface SearchTasksRequest {
    grainId: Nullable<number>;
    typeId: Nullable<number>;
    fieldId: Nullable<number>;
}
