import { Session } from './Session';

export abstract class SessionStorage {
    abstract hasSession(): boolean;
    abstract store(session: Session);
    abstract get(): Session | null;
    abstract removeSession();

    do(func: (session: Session) => void) {
        const session = this.get();
        if (!session) throw new Error(`There's no session`);
        func(session);
        this.store(session);
    }
}
