import { CreateTaskForm, TaskService } from '../../model/task/TaskService';
import { SessionStorage } from '../../model/user/auth/SessionStorage';

export class GetCreateTaskForm {
    constructor(private taskService: TaskService, private sessionStorage: SessionStorage) {}

    async exec(): Promise<CreateTaskForm> {
        const session = this.sessionStorage.get();
        const planId = session!.selectedPlan.id;
        return this.taskService.getCreateTaskForm(planId);
    }
}
