import React from 'react';
import ReactDOM from 'react-dom';
import './styles/base.css';
import { ReactRouterNavigation } from '../lib/navigation/reactRouter/ReactRouterNavigation';
import { PresenterFactory } from './PresenterFactory';
import { NotFound } from '../components/errors/NotFound';
import { WithPresenter } from '../components/WithPresenter';
import { LoginPage } from '../pages/Login/LoginPage';
import { Core } from '../../core/infrastructure/Core';
import { AxiosHttpClient } from '../../common/httpClient/AxiosHttpClient';
import { Config } from '../lib/Config';
import { CoreConfig } from '../../core/infrastructure/CoreConfig';
import { TasksPage } from '../pages/Task/Tasks/TasksPage';
import { NetworkError } from '../../common/httpClient/NetworkError';
import { NetworkErrorComponent } from '../components/errors/NetworkError';
import { RouteConfig } from '../lib/navigation/reactRouter/RouteConfig';
import { GeneralError } from '../components/errors/GeneralError';
import { BrowserSessionStorage } from '../../core/infrastructure/storage/BrowserSessionStorage';
import { Layout } from '../components/Layout/Layout';
import { AuthenticationError } from '../../common/httpClient/AuthenticationError';
import { EditTaskPage } from '../pages/Task/EditTask/EditTaskPage';
import { TaskDetailPage } from '../pages/Task/TaskDetail/TaskDetailPage';
import { CoreContext } from '../components/contexts/CoreContext';
import { TaskNotFound } from '../pages/Task/TaskNotFound/TaskNotFound';
import Home from '../pages/Home/Home';
import { SessionContext } from '../components/contexts/SessionContext';
import AppContext from '../lib/AppContext';
import { EventBus } from '../../core/infrastructure/eventBus/EventBus';
import { FinancialsPage } from '../pages/Financials/FinancialsPage';
import { SignUpPage } from '../pages/SignUp/SignUpPage';

export class Application {
    private readonly core: Core;
    private readonly navigation: ReactRouterNavigation;
    private readonly presenterFactory: PresenterFactory;
    private readonly eventBus = new EventBus();
    private readonly sessionStorage = new BrowserSessionStorage();

    constructor() {
        this.core = this.initializeCore();
        this.navigation = new ReactRouterNavigation(this.createRoutes(), this.isAuthenticated.bind(this));
        this.presenterFactory = new PresenterFactory(this.core, this.navigation, this.eventBus, this.onUnhandledError.bind(this));
        this.initializeAppContext();
        this.initializeDevMode();
    }

    private isAuthenticated(): boolean {
        return this.sessionStorage.hasSession();
    }

    private initializeCore() {
        const config: CoreConfig = {
            httpClient: new AxiosHttpClient(Config.get("apiBaseUrl")),
            eventBus: this.eventBus,
            sessionStorage: this.sessionStorage,
        };
        return new Core(config);
    }

    private createRoutes(): RouteConfig {
        return {
            notFoundComponent: NotFound,
            authRouteCall: () => ({
                name: 'login',
            }),
            routes: [
                {
                    name: 'home',
                    public: false,
                    path: '/',
                    component: Home,
                },
                {
                    name: 'signUp',
                    public: true,
                    path: '/sign-up',
                    component: WithPresenter(SignUpPage, v => this.presenterFactory.signUp(v))
                },
                {
                    name: 'login',
                    public: true,
                    path: '/login',
                    component: WithPresenter(LoginPage, v => this.presenterFactory.login(v))
                },
                {
                    name: 'networkError',
                    public: true,
                    path: '/networkError',
                    component: NetworkErrorComponent,
                },
                {
                    name: 'generalError',
                    public: true,
                    path: '/generalError',
                    component: GeneralError,
                },
                {
                    name: 'editTask',
                    public: false,
                    path: '/tasks/:id/edit',
                    component: WithPresenter(EditTaskPage, v => this.presenterFactory.editTask(v)),
                    layout: Layout,
                },
                {
                    name: 'taskDetail',
                    public: false,
                    path: '/tasks/:id',
                    component: WithPresenter(TaskDetailPage, v => this.presenterFactory.taskDetail(v)),
                    layout: Layout,
                },
                {
                    name: 'tasks',
                    public: false,
                    path: '/tasks',
                    component: WithPresenter(TasksPage, v => this.presenterFactory.home(v)),
                    layout: Layout,
                },
                {
                    name: 'financials',
                    public: false,
                    path: '/financials',
                    component: WithPresenter(FinancialsPage, v => this.presenterFactory.financials(v)),
                    layout: Layout,
                },
                {
                    name: 'taskNotFound',
                    public: false,
                    path: '/taskNotFound',
                    component: TaskNotFound,
                },
            ]
        };
    }

    render() {
        const rootElement = (
            <CoreContext.Provider value={this.core}>
                <SessionContext.Provider value={this.sessionStorage}>
                    {this.navigation.render()}
                </SessionContext.Provider>
            </CoreContext.Provider>
        );
        ReactDOM.render(rootElement, document.getElementById('root'));
    }

    private onUnhandledError(e) {
        switch (true) {
            case e instanceof AuthenticationError: this.navigation.redirectToAuth(); break;
            case e instanceof NetworkError: this.navigation.redirect('networkError'); break;
            default: {
                if (DEV) throw e;
                this.navigation.redirect('generalError');
            }
        }
    }

    private initializeAppContext() {
        AppContext.presenters = this.presenterFactory;
    }

    private initializeDevMode() {
        if (!DEV) return;
        // @ts-ignore
        window.App = this;
    }
}
