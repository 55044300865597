export function unhandledPromiseProxy(obj, handleError) {
    const methodHandler = {
        apply: (target, thisArg, argumentsList) => {
            const result = target.apply(obj, argumentsList);
            if (result instanceof Promise) return result.catch(e => handleError(e));
            return result;
        }
    };
    return methodProxy(obj, methodHandler);
}

function methodProxy(obj, methodHandler) {
    const handler = {
        get: (target, prop, receiver) => {
            if (typeof target[prop] === 'function') return new Proxy(target[prop], methodHandler);
            return target[prop];
        }
    };
    return new Proxy(obj, handler);
}