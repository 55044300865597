import React, { MouseEventHandler } from 'react';
import styled from 'styled-components';
import theme from '../../application/theme';

interface Props {
    disabled?: boolean;
    onClick?: MouseEventHandler;
    className?: string;
    title?: string;
}

export const PrimaryButton: React.FC<Props> = (props) => {
    const className = [props.className || '', props.disabled ? 'disabled' : ''].filter(Boolean).join(' ');
    const handleClick = (e) => {
        e.preventDefault();
        !props.disabled && props.onClick?.(e);
    };
    return <Link {...{ className }} title={props.title} href="#" onClick={handleClick}>{props.children}</Link>;
};

const Link = styled.a`
  flex-shrink: 0;
  height: 50px;
  line-height: 50px;
  border-radius: 25px;
  display: inline-block;
  background-color: #1B4C74;
  font-weight: 600;
  color: white;
  font-size: 14px;
  text-transform: uppercase;
  padding: 0 36px;
  text-decoration: none;
  position: relative;
  text-align: center;

  &.disabled {
    background-color: ${theme.buttons.backgroundColorDisabled};
    color: ${theme.buttons.colorDisabled};
    cursor: not-allowed;
  }

  &:hover:not(.disabled) { background-color: #2a76b4; }
`;
