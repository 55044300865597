import React from 'react';
import styled from 'styled-components';

interface Props {
    className?: string;
    name: string;
    color: string;
}

export const GrainIcon: React.FC<Props> = (props) => {
    return <Root className={props.className} style={{ backgroundColor: props.color }}>{props.name[0]}</Root>;
};

const Root = styled.div`
  border-radius: 15px;
  color: white;
  font-size: 16px;
  font-weight: 300;
  line-height: 30px;
  text-align: center;
  text-transform: uppercase;
  width: 30px;
`;
