import { CostCenterService } from '../../app/model/task/CostCenterService';
import { CostCenter } from '../../app/model/task/CostCenter';
import { HttpClient } from '../../../common/httpClient/HttpClient';
import { ApiClient } from '../api/ApiClient';
import { NotAuthenticatedError } from '../../app/model/user/auth/error/NotAuthenticatedError';

export class HttpCostService implements CostCenterService {
    private api: ApiClient;
    private readonly errorMappings = {
        'NotAuthenticatedError': NotAuthenticatedError,
    };

    constructor(httpClient: HttpClient) {
        this.api = new ApiClient(httpClient, this.errorMappings);
    }

    async getAll(): Promise<CostCenter[]> {
        const response = await this.api.get('/costCenters/getAll');
        const costCentersJson = response.body.costCenters;
        return costCentersJson.map(this.toCostCenter.bind(this));
    }

    private toCostCenter(json): CostCenter {
        return {
            id: json.id,
            name: json.name
        };
    }
}
