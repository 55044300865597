import React from 'react';
import styled from 'styled-components';
import { HStack } from '../../../components/common/Stack';
import { Avatar } from '../../../components/common/Avatar';

interface Props { grain: string; color: string; }
export const Grain: React.FC<Props> = ({ grain, color }) => {
    return (
        <Root>
            <Avatar size={16} color={color}><span className="grain-initial">{grain[0]}</span></Avatar>
            <div className="grain-label">{grain}</div>
        </Root>
    );
};

const Root = styled(HStack)`
  align-items: center;

  .grain-initial {
    color: white;
    font-size: 10px;
    line-height: 16px;
  }

  .grain-label {
    display: flex;
    flex-direction: column;
    font-size: 12px;
    font-weight: 600;
    line-height: 1em;
    margin-left: 10px;
  }
`;
