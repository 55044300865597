import { TaskApplication } from '../../model/task/TaskApplication';
import { TaskApplicationInfo, TaskService, UpdateApplicationsRequest } from '../../model/task/TaskService';
import { SupplyUnits } from '../../model/task/SupplyUnits';
import { ApplicationAreas } from '../../model/task/ApplicationAreas';
import { Validator } from '../../../../common/validation/Validator';

export interface UpdateTaskApplicationRequest {
    taskId: number;
    applications: TaskApplication[];
    number: number;
    supplyName: string;
    dose: string;
    unit: SupplyUnits;
    area: ApplicationAreas;
    costPerUnit: string;
    costCenterId: number;
}

export class UpdateTaskApplication {
    constructor(private taskService: TaskService) {}

    async exec(request: UpdateTaskApplicationRequest): Promise<void> {
        this.validate(request);
        await this.taskService.updateApplications(this.createServiceRequestFrom(request));
    }

    private validate(request: UpdateTaskApplicationRequest) {
        const validator = new Validator();
        validator.check('supplyName', request.supplyName).notNullOrBlank('Debe ingresar un insumo');
        validator.check('dose', request.dose)
            .notNullOrBlank('Debe ingresar una dosis')
            .zeroOrPositive('La dosis debe ser cero o un número positivo');
        validator.check('costPerUnit', request.costPerUnit)
            .notNullOrBlank('Debe ingresar un costo')
            .zeroOrPositive('El costo debe ser cero o un número positivo');
        validator.throwIfHasErrors();
    }

    private createServiceRequestFrom(request: UpdateTaskApplicationRequest): UpdateApplicationsRequest {
        const editedApplication: TaskApplication = {
            number: request.number,
            supplyName: request.supplyName,
            dose: parseFloat(request.dose),
            unit: request.unit,
            area: request.area,
            costPerUnit: parseFloat(request.costPerUnit),
            costCenterId: request.costCenterId,
        };
        return {
            taskId: request.taskId,
            applications: this.mergeApplicationsToApplicationInfo(request.applications, editedApplication),
        };
    }

    private mergeApplicationsToApplicationInfo(applications: TaskApplication[], editedApplication: TaskApplication) {
        return applications.map((application) => this.toTaskApplicationInfo(application.number === editedApplication.number ? editedApplication : application));
    }

    private toTaskApplicationInfo(application: TaskApplication): TaskApplicationInfo {
        return {
            supplyName: application.supplyName,
            dose: application.dose,
            unit: SupplyUnits[application.unit],
            area: ApplicationAreas[application.area],
            costPerUnit: application.costPerUnit,
            costCenterId: application.costCenterId
        };
    }
}
