import { Plan } from '../model/plan/Plan';
import { PlanService } from '../model/plan/PlanService';

export class GetPlans {
    constructor(private planService: PlanService) {}

    async exec(): Promise<Plan[]> {
        const response = await this.planService.getPlans();
        return response.plans;
    }
}
