import React from 'react';
import { HStack } from '../../../../components/common/Stack';
import styled from 'styled-components';

interface Props { label?: string|JSX.Element; }
export const Row: React.FC<Props> = ({ label, children }) => {
    const renderedLabel = typeof label == 'string' ? <div>{label}</div> : label;
    return <Root>
        {renderedLabel}
        {children}
    </Root>;
};

const Root = styled(HStack)`
  background-color: white;
  align-items: center;

  > div {
    border: 1px solid #ebebeb;
    flex-grow: 0;
    flex-shrink: 0;
    font-size: 13px;
    font-weight: normal;
    line-height: 55px;
    padding: 0 15px;

    &:first-child {
      color: #707683;
      flex-grow: 1;
      flex-shrink: 1;
    }

    &:not(:first-child) {
      width: 211px;
      text-align: right;
      border-left-width: 0;
    }
  }

  &:not(:last-child) {
    > div {
      border-bottom-width: 0;
    }
  }

  &:last-child,
  &:only-child {
    > div {
      border-bottom-width: 1px;
    }
  }
`;
