import { RouteInfo } from './RouteConfig';
import { Key, pathToRegexp } from 'path-to-regexp';

export class RouteUrlGenerator {
    private routeParamCache: Record<string, string[]> = {};

    generate(route: RouteInfo, params?: Record<string, any>): string {
        const pathParams = this.getRoutePathParams(route);
        const url = this.replacePathParams(route.path, pathParams, params);
        return this.addQueryParams(url, pathParams, params);
    }

    private getRoutePathParams(route: RouteInfo) {
        if (!this.routeParamCache[route.name]) {
            const keys: Key[] = [];
            pathToRegexp(route.path, keys);
            this.routeParamCache[route.name] = keys.map(k => k.name.toString());
        }
        return this.routeParamCache[route.name];
    }

    private replacePathParams(url: string, pathParams: string[], params?: Record<string, any>): string {
        let tempUrl = url;
        for (const pathParam of pathParams) {
            if (!params || !params[pathParam]) throw new Error('Missing parameter ' + pathParam);
            tempUrl = tempUrl.replace(`:${pathParam}`, params[pathParam]);
        }
        return tempUrl;
    }

    private addQueryParams(url: string, pathParams: string[], params?: Record<string, any>): string {
        if (!params) return url;
        const queryParams = Object.keys(params).filter(name => !pathParams.includes(name));
        if (queryParams.length == 0) return url;
        const queryParamsString = queryParams.map(name => name + '=' + params[name]).join('&');
        return url + '?' + queryParamsString;
    }
}
