import React from 'react';
import styled from 'styled-components';

interface Props {
    className?: string;
    icon?: Nullable<JSX.Element>;
    label: string|JSX.Element;
    subItems?: SubItem[];
}

interface State { dropdownOpened: boolean; }

export class MenuItem extends React.Component<Props, State> {
    private readonly linkRef: React.RefObject<HTMLAnchorElement>;

    state = {
        dropdownOpened: false,
    };

    constructor(props) {
        super(props);
        this.linkRef = React.createRef();
    }

    componentDidMount() {
        document.addEventListener('click', this.onClickOutside.bind(this), true);
    }

    componentWillUnmount() {
        document.removeEventListener('click', this.onClickOutside.bind(this), true);
    }

    private onClickOutside(e) {
        if (this.linkRef.current?.contains(e.target)) return;

        this.setState({ dropdownOpened: false });
    }

    private toggleDropdown(e) {
        e.preventDefault();
        this.setState((state) => ({ dropdownOpened: !state.dropdownOpened }));
    }

    private get className(): string {
        return [
            this.props.className === 'open' ? '' : this.props.className,
            this.state.dropdownOpened ? 'open' : ''
        ].filter(Boolean).join(' ');
    }

    render() {
        const subItems = this.props.subItems ?? [];
        return (
            <Root className={this.className}>
                <a href="#" className="menu-item" onClick={e => this.toggleDropdown(e)} ref={this.linkRef}>
                    {this.props.icon}
                    <div className="label">{this.props.label}</div>
                    {subItems.length > 0 && <b className="caret" />}
                </a>
                {subItems.length > 0 && (
                    <ul className="dropdown">
                        {subItems.map(item => <li key={item.label}><a onClick={event => this.onSubItemClick(event, item.action)} href={item.url}>{item.label}</a></li>)}
                    </ul>
                )}
            </Root>
        );
    }

    private onSubItemClick(event: React.MouseEvent<HTMLAnchorElement>, action) {
        if (action) {
            event.preventDefault();
            action();
        }
    }
}

const Root = styled.div`
  position: relative;

  a.menu-item {
    align-items: center;
    display: flex;
    flex-direction: row;
    text-decoration: none;

    .label {
      color: #90a0b7;
      font-size: 14px;
      font-weight: 500;
      line-height: 1em;
    }

    .caret {
      display: inline-block;
      border: 6px solid transparent;
      border-bottom: 0;
      border-top-color: #c4c4c4;
      height: 0;
      margin-bottom: 1px;
      margin-left: 9px;
      vertical-align: middle;
      width: 0;
    }

    &:hover {
      > i { background-position-y: bottom; }
      .label { color: #1b4c74; }
      b.caret { border-top-color: #1b4c74; }
    }
  }

  ul.dropdown {
    list-style: none;
    position: absolute;
    display: none;
    text-align: left;
    top: 100%;
    right: 0;
    background-color: #fff;
    margin: 0;
    z-index: 1000;
    border: 1px solid #e1e1e1;
    border-top: none;
    border-radius: 0 0 4px 4px;
    min-width: 210px;
    padding: 5px 0;
    box-shadow: 0 6px 12px rgba(0,0,0,.1);

    li {
      a {
        color: #7B7B82;
        display: block;
        font-size: 14px;
        margin-bottom: 1px;
        margin-top: 1px;
        padding: 3px 20px;
        text-decoration: none;
        white-space: nowrap;

        &:hover {
          color: #757575;
          background-color: #d7eeff;
        }
      }
    }
  }

  &.open ul.dropdown { display: block; }
`;

export interface SubItem {
    label: string;
    url: string;
    action?: () => void;
}
