import React from 'react';
import styled from 'styled-components';
import { PrimaryButton } from '../../../components/buttons/PrimaryButton';
import { VStack } from '../../../components/common/Stack';
import { Navigation } from '../../../lib/navigation/Navigation';

interface Props {
    navigation: Navigation;
}

export const TaskNotFound: React.FC<Props> = (props) => {
    return (
        <Content>
            <Warning>La tarea a la que estás intentando acceder no existe.</Warning>
            <PrimaryButton onClick={() => { props.navigation.redirect('tasks'); }}>Volver al listado</PrimaryButton>
        </Content>
    );
};

const Content = styled(VStack)`
  background-color: #f5f7f7;
  min-height: 100vh;
  justify-content: center;
  align-items: center;
`;

const Warning = styled.div`
  font-size: 18px;
  font-weight: 600;
  color: #1b4c74;
  margin-bottom: 25px;
`;
