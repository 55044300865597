import { HttpClient } from '../../../common/httpClient/HttpClient';
import { ApiClient } from '../api/ApiClient';
import { GetPlansResponse, PlanService } from '../../app/model/plan/PlanService';
import { Plan } from '../../app/model/plan/Plan';
import { NoPlansError } from '../../app/model/plan/NoPlansError';

export class HttpPlanService implements PlanService {
    private api: ApiClient;
    private readonly errorMappings = {
        'NoStartedPlansError': NoPlansError,
    };

    constructor(httpClient: HttpClient) {
        this.api = new ApiClient(httpClient, this.errorMappings);
    }

    async getPlans(): Promise<GetPlansResponse> {
        const response = await this.api.get('/plans/myStartedPlans');
        return {
            plans: this.plansFormattedFrom(response.body.plans),
            farm: response.body.farm,
        };
    }

    private plansFormattedFrom(plans: Plan[]): Plan[] {
        return plans.map(plan => ({ ...plan, season: plan.season.replace(' - ', '/') }));
    }
}
