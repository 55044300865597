import { HttpError } from '../../../common/httpClient/HttpError';

export type ErrorMapping = { [key: string]: any };

export async function apiErrorMapper<T>(call: () => Promise<T>, mappings: ErrorMapping): Promise<T> {
    try {
        return await call();
    } catch (e) {
        for (const errorType of Object.keys(mappings)) {
            if (e instanceof HttpError && e.data.type === errorType) {
                throw new mappings[errorType](e.data.message);
            }
        }
        throw e;
    }
}
