import { TaskSummary } from '../../../core/app/model/task/TaskSummary';
import { DateFormatter } from './DateFormatter';
import { LocalDate } from '../../../common/time/LocalDate';
import { TaskDetail } from '../../../core/app/model/task/TaskDetail';

export class TaskDateFormatter {
    static format(task: TaskSummary|TaskDetail): string {
        if (task.startDate !== null) return DateFormatter.shortDate(task.startDate);
        if (task.suggestedStartFrom!.equals(task.suggestedStartTo)) return DateFormatter.shortDate(task.suggestedStartFrom);
        return DateFormatter.shortDate(task.suggestedStartFrom) + ' al ' + DateFormatter.shortDate(task.suggestedStartTo);
    }

    static suggestedDate(from: Nullable<LocalDate>, to: Nullable<LocalDate>) {
        if (from === null || to === null) return '';
        if (from.equals(to)) return DateFormatter.shortDate(from);
        return DateFormatter.shortDate(from) + ' al ' + DateFormatter.shortDate(to);
    }

    static monthNameAndYearFrom(task: TaskSummary): string {
        const startDate = task.startDate || task.suggestedStartFrom;
        if(!startDate) return '';
        return `${DateFormatter.monthName(startDate.month)} ${startDate.year}`;
    }
}
