import React from 'react';
import styled from 'styled-components';
import { HStack, VStack } from '../../../common/Stack';
import { Avatar } from '../../../common/Avatar';
import { Icon } from '../../../common/Icon';
import userIconImage from './icon-user.png';
import { MenuItem } from '../MenuItem';
import { Navigation } from '../../../../lib/navigation/Navigation';
import { CoreContext } from '../../../contexts/CoreContext';
import { Logout } from '../../../../../core/app/useCases/Logout';

interface Props {
    className?: string;
    navigation: Navigation;
    name: string;
    email: string;
}

export class UserNav extends React.Component<Props> {
    static contextType = CoreContext;

    private onLogOut() {
        const core = this.context;
        core.useCase(Logout).exec();
        this.props.navigation.redirect('login');
    }

    render() {
        return (
            <Root className={this.props.className}>
                <Avatar size={52} color="white">
                    <Icon url={userIconImage} size={21}/>
                </Avatar>
                <UserInfo>
                    <MenuItem
                        className="menu"
                        label={this.props.name}
                        subItems={[{ url: '/login', label: 'Salir', action: this.onLogOut.bind(this) }]}
                    />
                    <div className="email">{this.props.email}</div>
                </UserInfo>
            </Root>
        );
    }
}

const Root = styled(HStack)`
  align-items: center;
  background-color: #f9f9f9;
  padding: 0 36px 0 18px;
`;

const UserInfo = styled(VStack)`
  align-items: flex-start;
  margin-left: 20px;

  div {
    color: #90a0b7;
    font-size: 14px;
    line-height: 1em;
    font-weight: 600;
  }

  b.caret {
    margin-left: 26px;
  }

  .menu {
    .dropdown {
      border-top: 1px solid #e1e1e1;
      border-radius: 4px;
      font-weight: 500;
      min-width: 175px;
      right: auto;
      top: 18px;
    }
  }

  .email {
    color: #9ba1ab;
    font-size: 12px;
    font-weight: normal;
    line-height: 1em;
    margin-top: 4px;
  }
`;
