import { Route, Router, Switch } from 'react-router-dom';
import { History } from 'history';
import React from 'react';
import { RouteConfig, RouteInfo } from './RouteConfig';
import { AuthGate } from './AuthGate';
import { RouteMatchDetector } from './RouteMatchDetector';
import { IsAuthenticatedFunc, ReactRouterNavigation } from './ReactRouterNavigation';

export class RouterRenderer {
    constructor(
        private navigation: ReactRouterNavigation,
        private history: History,
        private routeConfig: RouteConfig,
        private isAuthenticated: IsAuthenticatedFunc,
        private updateMatch: (route, match) => void,
    ) {}

    private renderRoute(route: RouteInfo, idx: number) {
        return <Route key={idx} exact path={route.path} component={this.getRouteComponent(route)} />;
    }

    private getRouteComponent(route: RouteInfo) {
        const component = this.decorateWithNavigation(route.component);
        return this.decorateWithMatchDetector(route,
            this.decorateWithAuthGate(route,
                route.layout ?
                    this.decorateWithLayout(component, route.layout) :
                    component
            )
        );
    }

    private decorateWithNavigation(Component) {
        return props => <Component {...props} navigation={this.navigation} />;
    }

    private decorateWithLayout(Component, Layout) {
        return props => <Layout {...props} navigation={this.navigation}><Component {...props} /></Layout>;
    }

    private decorateWithAuthGate(route: RouteInfo, Component) {
        if (route.public) return Component;
        return props => (
            <AuthGate isAuthenticated={this.isAuthenticated} redirectToAuth={() => this.navigation.redirectToAuth() }>
                <Component {...props} />
            </AuthGate>
        );
    }

    private decorateWithMatchDetector(route: RouteInfo, Component) {
        return props => <>
            <RouteMatchDetector onChange={match => this.updateMatch(route, match)} />
            <Component { ...props}/>
        </>;
    }

    render() {
        return (
            <Router history={this.history}>
                <Switch>
                    {this.routeConfig.routes.map(this.renderRoute.bind(this))}
                    <Route component={this.routeConfig.notFoundComponent} />
                </Switch>
            </Router>
        );
    }
}
