import { Navigation } from '../../lib/navigation/Navigation';
import { LoginFormVM } from './LoginFormVM';
import { ValidationsError } from '../../../common/validation/ValidationsError';
import { Login } from '../../../core/app/useCases/Login';

export interface LoginView {
    showLoading();
    showForm(form: LoginFormVM);
}

export class LoginPresenter {
    private readonly view: LoginView;
    private readonly login: Login;
    private readonly navigation: Navigation;
    private readonly form = new LoginFormVM();

    constructor(view: LoginView, login: Login, navigation: Navigation) {
        this.view = view;
        this.login = login;
        this.navigation = navigation;
    }

    setEmail(email: string) {
        this.updateForm({ email });
    }

    setPassword(password: string) {
        this.updateForm({ password });
    }

    async submit() {
        try {
            this.view.showLoading();
            await this.login.exec(this.form.email, this.form.password);
            this.navigation.redirect('tasks');
        } catch (e) {
            this.handleSubmitError(e);
        }
    }

    private handleSubmitError(e: Error) {
        if (e instanceof ValidationsError) {
            this.updateForm({ errors: e.allErrorMessages() });
            return;
        }
        throw e;
    }

    private updateForm<K extends keyof LoginFormVM>(changes: Pick<LoginFormVM, K>) {
        Object.assign(this.form, changes);
        this.view.showForm(this.form);
    }
}
