import React from 'react';
import styled from 'styled-components';
import { CheckBox } from '../../../components/forms/CheckBox';
import { TaskSuggestionVM } from './EditApplicationPresenter';
import { GrainIcon } from '../TaskDetail/GrainIcon';

interface Props {
    model: TaskSuggestionVM;
    onToggle: () => void;
}

export const TaskCard: React.FC<Props> = (props) => {
    return (
        <Root>
            <CheckBox checked={props.model.selected} onChange={() => { props.onToggle(); }} />
            <label>
                <_GrainIcon name={props.model.grainName} color={props.model.grainColor} /> &nbsp;
                <div className="indicator">#{props.model.number} |</div> &nbsp;
                {props.model.type} en &quot;{props.model.field}&quot;
            </label>
        </Root>
    );
};

const Root = styled.li`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 10px;

  label {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: 10px;
    font-size: 13px;
    
    .indicator {
      font-weight: 600;
    }
  }
`;

const _GrainIcon = styled(GrainIcon)`
  border-radius: 10px;
  font-size: 14px;
  line-height: 20px;
  width: 20px;
`;
