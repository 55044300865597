import React from 'react';
import styled from 'styled-components';
import { HStack } from '../../../components/common/Stack';

interface Props { className?: string; label: string | JSX.Element; buttons?: JSX.Element[]; }
export const TaskInfoRow: React.FC<Props> = ({ className, label, buttons, children }) => {
    const renderedLabel = typeof label == 'string' ? <div>{label}</div> : label;
    return <Root>
        {renderedLabel}
        {children}
        {buttons && <Actions className={className ? className : ''}>{(buttons || []).map(button => button)}</Actions>}
    </Root>;
};

const Root = styled(HStack)`
  background-color: white;
  

  > div {
    border: 1px solid #ebebeb;
    flex-grow: 0;
    flex-shrink: 0;
    font-size: 13px;
    font-weight: normal;
    line-height: 55px;
    padding: 0 15px;
  
    &:first-child {
      color: #707683;
      flex-grow: 1;
      flex-shrink: 1;
    }
  
    &:not(:first-child) {
      width: 173px;
      text-align: right;
      border-left-width: 0;
    }
  
    .unit {
      color: #828282;
      padding-left: 20px;
    }
  }

  &:not(:last-child) {
    > div {
      border-bottom-width: 0;
    }
  }

  &:last-child,
  &:only-child {
    > div {
      border-bottom-width: 1px;
    }
  }
`;

const Actions = styled(HStack)`
  align-items: center;
  justify-content: flex-end;
  width: 200px;

  .icon-button { margin-right: 15px; }
`;
