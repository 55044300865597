import { Core } from '../../core/infrastructure/Core';
import { LoginPresenter, LoginView } from '../pages/Login/LoginPresenter';
import { Navigation } from '../lib/navigation/Navigation';
import { TasksPresenter, TasksView } from '../pages/Task/Tasks/TasksPresenter';
import { Login } from '../../core/app/useCases/Login';
import { GetTasks } from '../../core/app/useCases/task/GetTasks';
import { unhandledPromiseProxy } from '../../common/unhandledPromiseProxy';
import { EditTaskPresenter, EditTaskView } from '../pages/Task/EditTask/EditTaskPresenter';
import { GetTaskEditForm } from '../../core/app/useCases/task/GetTaskEditForm';
import { UpdateTask } from '../../core/app/useCases/task/UpdateTask';
import { TaskDetailPresenter, TaskDetailView } from '../pages/Task/TaskDetail/TaskDetailPresenter';
import { GetTask } from '../../core/app/useCases/task/GetTask';
import { EditApplicationPresenter, EditApplicationView } from '../pages/Task/EditApplication/EditApplicationPresenter';
import { GetCostCenters } from '../../core/app/useCases/GetCostCenters';
import { AddTaskApplication } from '../../core/app/useCases/task/AddTaskApplication';
import { DeleteTaskApplication } from '../../core/app/useCases/task/DeleteTaskApplication';
import { DeleteTask } from '../../core/app/useCases/task/DeleteTask';
import { FinishTask } from '../../core/app/useCases/task/FinishTask';
import { ReopenTask } from '../../core/app/useCases/task/ReopenTask';
import { PlanNavPresenter, PlanNavView } from '../components/Layout/UserMenu/PlanNav/PlanNavPresenter';
import { GetPlans } from '../../core/app/useCases/GetPlans';
import { GetSelectedPlan } from '../../core/app/useCases/GetSelectedPlan';
import { SelectPlan } from '../../core/app/useCases/SelectPlan';
import { EventBus } from '../../core/infrastructure/eventBus/EventBus';
import { CreateTaskPresenter, CreateTaskView } from '../pages/Task/CreateTask/CreateTaskPresenter';
import { GetCreateTaskForm } from '../../core/app/useCases/task/GetCreateTaskForm';
import { CreateTask } from '../../core/app/useCases/task/CreateTask';
import { FinancialsPresenter, FinancialsView } from '../pages/Financials/FinancialsPresenter';
import { GetSeasonReportAccess } from '../../core/app/useCases/GetSeasonReportAccess';
import { GetFinancialReport } from '../../core/app/useCases/financials/GetFinancialReport';
import { SearchTasks } from '../../core/app/useCases/task/SearchTasks';
import { GetTaskSuggestions } from '../../core/app/useCases/task/GetTaskSuggestions';
import { UpdateApplication } from '../../core/app/useCases/task/UpdateApplication';
import { SignUpPresenter, SignUpView } from '../pages/SignUp/SignUpPresenter';
import { GetUserInfo } from '../../core/app/useCases/GetUserInfo';
import { AnalyticsEventsLogger } from '../../core/infrastructure/logs/AnalyticsEventsLogger';

export type ErrorHandler = (e) => void;

export class PresenterFactory {
    constructor(private core: Core, private navigation: Navigation, private eventBus: EventBus, private onUnhandledError: ErrorHandler) {}

    signUp(view: SignUpView): SignUpPresenter {
        return this.proxy(new SignUpPresenter(view));
    }

    login(view: LoginView): LoginPresenter {
        return this.proxy(new LoginPresenter(view, this.core.useCase(Login), this.navigation));
    }

    home(view: TasksView): TasksPresenter {
        return this.proxy(
            new TasksPresenter(
                view,
                this.navigation,
                this.eventBus,
                this.core.useCase(GetTasks),
                this.core.useCase(SearchTasks),
                this.core.useCase(DeleteTask),
                this.core.useCase(FinishTask),
                this.core.useCase(ReopenTask),
            )
        );
    }

    financials(view: FinancialsView): FinancialsPresenter {
        return new FinancialsPresenter(
            view,
            this.core.useCase(GetSeasonReportAccess),
            this.core.useCase(GetFinancialReport),
            this.core.useCase(GetUserInfo),
            new AnalyticsEventsLogger(),
        );
    }

    editTask(view: EditTaskView): EditTaskPresenter {
        return this.proxy(new EditTaskPresenter(
            view,
            this.navigation,
            this.core.useCase(GetTaskEditForm),
            this.core.useCase(UpdateTask),
            this.core.useCase(GetUserInfo),
            new AnalyticsEventsLogger(),
            this.core.useCase(DeleteTaskApplication),
        ));
    }

    editApplication(view: EditApplicationView): EditApplicationPresenter {
        return this.proxy(new EditApplicationPresenter(
            view,
            this.navigation,
            this.core.useCase(GetTask),
            this.core.useCase(GetCostCenters),
            this.core.useCase(GetTaskSuggestions),
            this.core.useCase(AddTaskApplication),
            this.core.useCase(UpdateApplication),
        ));
    }

    taskDetail(view: TaskDetailView): TaskDetailPresenter {
        return this.proxy(new TaskDetailPresenter(
            view,
            this.core.useCase(GetTask),
            this.core.useCase(DeleteTask),
            this.core.useCase(FinishTask),
            this.core.useCase(ReopenTask),
            this.core.useCase(GetUserInfo),
            new AnalyticsEventsLogger(),
            this.navigation));
    }

    planNav(view: PlanNavView): PlanNavPresenter {
        return new PlanNavPresenter(
            view,
            this.navigation,
            this.core.useCase(GetPlans),
            this.core.useCase(GetSelectedPlan),
            this.core.useCase(SelectPlan),
        );
    }

    createTask(view: CreateTaskView): CreateTaskPresenter {
        return new CreateTaskPresenter(
            view,
            this.navigation,
            this.eventBus,
            this.core.useCase(GetCreateTaskForm),
            this.core.useCase(CreateTask),
        );
    }

    private proxy<T>(presenter: T): T {
        return unhandledPromiseProxy(presenter, this.onUnhandledError);
    }
}
