export class FinancialsVM {
    isLoading = false;
    seasonReportAccess = '';
    netIncome = new IndicatorVM();
    netIncomePerHectare = new IndicatorVM();
    totalCost = new IndicatorVM();
    totalCostPerHectare = new IndicatorVM();
    netMargin = new IndicatorVM();
    netMarginPerHectare = new IndicatorVM();
    roi = '0%';
}

export class IndicatorVM {
    value: number;
    abbreviatedValue: string;
    abbreviation: string;
    unit: string;

    constructor() {
        this.value = 0;
        this.abbreviatedValue = '0';
        this.abbreviation = '';
        this.unit = 'u$s';
    }
}
