import { TaskApplicationVM, TaskVM } from './TaskVM';
import { GetTask } from '../../../../core/app/useCases/task/GetTask';
import { Navigation } from '../../../lib/navigation/Navigation';
import { TaskDetail } from '../../../../core/app/model/task/TaskDetail';
import { TaskDateFormatter } from '../../../lib/formatters/TaskDateFormatter';
import { NumberFormatter } from '../../../lib/formatters/NumberFormatter';
import { TaskNotFoundError } from '../../../../core/app/model/task/TaskNotFoundError';
import { TaskApplication } from '../../../../core/app/model/task/TaskApplication';
import { ApplicationAreas } from '../../../../core/app/model/task/ApplicationAreas';
import { TaskApplicationFormatter } from '../../../lib/formatters/TaskApplicationFormatter';
import { DeleteTask } from '../../../../core/app/useCases/task/DeleteTask';
import { TaskNumberFormatter } from '../../../lib/formatters/TaskNumberFormatter';
import { FinishTask } from '../../../../core/app/useCases/task/FinishTask';
import { InvalidOperationError } from '../../../../core/app/model/task/InvalidOperationError';
import { ReopenTask } from '../../../../core/app/useCases/task/ReopenTask';
import { GetUserInfo } from '../../../../core/app/useCases/GetUserInfo';
import { EventsLogger } from '../../../../core/infrastructure/logs/EventsLogger';

export interface TaskDetailView {
    showLoading();
    showTask(task: TaskVM);
    showTaskAlreadyFinishedMessage();
    showTaskNotFoundMessage();
}

export class TaskDetailPresenter {
    private taskId!: number;

    constructor(
        private view: TaskDetailView,
        private getTask: GetTask,
        private deleteTask: DeleteTask,
        private finishTask: FinishTask,
        private reopenTask: ReopenTask,
        private getUserInfo: GetUserInfo,
        private eventsLogger: EventsLogger,
        private navigation: Navigation,
    ) {}

    async start() {
        this.taskId = this.getTaskIdFromRoute();
        this.view.showLoading();
        await this.refresh();
    }

    async delete() {
        try {
            await this.tryDelete();
        } catch (e) {
            if(e instanceof TaskNotFoundError) return this.redirectToTasks();
            throw e;
        }
    }

    redirectToTasks() { this.navigation.redirect('tasks'); }

    private async tryDelete() {
        await this.deleteTask.exec(this.taskId!);
        this.redirectToTasks();
    }

    async finish() {
        try {
            await this.tryFinish();
            this.logTaskFinishedEvent();
        } catch (e) {
            if (e instanceof InvalidOperationError) { return this.view.showTaskAlreadyFinishedMessage(); }
            if (e instanceof TaskNotFoundError) { return this.view.showTaskNotFoundMessage(); }
            throw e;
        }
    }

    private async tryFinish() {
        await this.finishTask.exec(this.taskId);
        await this.refresh();
    }

    private logTaskFinishedEvent() {
        const userInfo = this.getUserInfo.exec();
        this.eventsLogger.logCustomEvent('TaskFinished', { user: userInfo.email });
    }

    async reopen() {
        try {
            await this.tryReopen();
        } catch (e) {
            if(e instanceof InvalidOperationError) return await this.refresh();
            if(e instanceof TaskNotFoundError) return this.view.showTaskNotFoundMessage();
            throw e;
        }
    }

    async tryReopen() {
        await this.reopenTask.exec(this.taskId);
        await this.refresh();
    }

    async refresh() {
        try {
            const task = await this.fetchTask();
            this.view.showTask(this.toVM(task));
        } catch (e) {
            this.handleFetchTaskErrors(e);
        }
    }

    private async fetchTask(): Promise<TaskDetail> { return this.getTask.exec(this.taskId); }

    private handleFetchTaskErrors(e) {
        if (e instanceof TaskNotFoundError) { return this.navigation.redirect('taskNotFound'); }
        throw e;
    }

    private getTaskIdFromRoute() {
        return parseInt(this.navigation.currentRoute()?.params['id'], 10);
    }

    private toVM(task: TaskDetail): TaskVM {
        return {
            ...task,
            number: TaskNumberFormatter.format(task.number),
            startDate: TaskDateFormatter.format(task),
            hectares: task.hectares.toString(),
            laborCostPerHectare: {
                amount: NumberFormatter.float(task.laborCostPerHectare, 2),
                unit: 'U$S/ha',
            },
            totalCostPerHectare: {
                amount: NumberFormatter.float(task.totalCostPerHectare, 2),
                unit: 'U$S/ha',
            },
            applications: task.applications.map(a => this.toApplicationVM(task, a)),
        };
    }

    private toApplicationVM(task: TaskDetail, application: TaskApplication): TaskApplicationVM {
        const costPerHectare = this.calculateCostPerHectare(task, application);
        return {
            ...application,
            dose: {
                amount: NumberFormatter.float(application.dose, 2),
                unit: TaskApplicationFormatter.formatDoseUnit(application),
            },
            costPerHectare: {
                amount: NumberFormatter.float(costPerHectare, 2),
                unit: 'U$S/ha',
            },
        };
    }
    // TODO: Move business logic to server

    private calculateCostPerHectare(task: TaskDetail, application: TaskApplication): number {
        let costPerHectare = application.dose * application.costPerUnit;
        if (application.area == ApplicationAreas.Total) {
            costPerHectare /= task.hectares;
        }
        return costPerHectare;
    }
}
