import React from 'react';
import { ModalRender } from './ModalRender';
import styled from 'styled-components';
import { PrimaryButton } from '../buttons/PrimaryButton';
import { LinkButton } from '../buttons/LinkButton';
import { ModalOverlay } from './ModalOverlay';
import { Loader } from '../common/Loader';

interface ModalProps {
    showModal: boolean;
    title: string;
    onSubmit: () => void;
    onCancel: () => void;
}

interface State {
    isLoading: boolean;
}

export class Modal extends React.Component<ModalProps, State> {
    state: State = {
        isLoading: false,
    };

    private async onSubmit() {
        this.setState({ isLoading: true });
        await this.props.onSubmit();
        this.setState({ isLoading: false });
    }

    private onCancel() {
        this.props.onCancel();
        this.setState({ isLoading: false });
    }

    render() {
        if (this.props.showModal) {
            return <ModalRender>
                <StyledModal>
                    <ModalContent>
                        <ModalBody>
                            <div className="title">
                                <label>{this.props.title}</label>
                                <div className="closeModal" onClick={() => this.onCancel()}>X</div>
                            </div>
                            <div className="body">
                                Esta acción no se puede revertir
                            </div>
                        </ModalBody>
                        <ModalFooter>
                            <LinkButton onClick={() => this.onCancel()}>Cancelar</LinkButton>
                            <PrimaryButton onClick={async () => await this.onSubmit()}>Confirmar</PrimaryButton>
                        </ModalFooter>
                        {this.state.isLoading ? <ModalOverlay> <Loader /> </ModalOverlay> : <></> }
                    </ModalContent>
                </StyledModal>
            </ModalRender>;
        }
        return <></>;
    }
}

const StyledModal = styled.div`
  background-color: #000000bf;
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ModalContent = styled.div`
  background-color: #fff;
  position: relative;
  display: flex;
  width: 486px;
  height: 350px;
  flex-direction: column;
  pointer-events: auto;
  background-clip: padding-box;
  border-radius: 0.3rem;
  outline: 0;
  align-content: end;
`;

const ModalBody = styled.div`
  display: flex;
  flex-direction: column;
  padding: 25px 26px 0;
  flex-grow: 1;
  flex-shrink: 1;
  
  .title {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-bottom: 20px;
    font-size: 18px;
    font-weight: 600;
    flex-grow: 0;
    flex-shrink: 0;

    .closeModal {
      cursor: pointer;
    }
  }
  
  .body {
    flex-grow: 1;
    flex-shrink: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
  }
`;

const ModalFooter = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  padding: 15px 50px 40px 15px;
  border-bottom-right-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem;
  flex-grow: 0;
  flex-shrink: 0;
  align-items: flex-end;

  p {
    margin-right: 135px;
    color: #f36666;
  }

  > a:first-child {
    flex-grow: 0;
    flex-shrink: 0;
    width: 50%;
  }

  > a:last-child {
    flex-shrink: 1;
    flex-grow: 1;
    margin-left: 10px;
  }
`;
