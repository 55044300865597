import React from 'react';
import styled from 'styled-components';
import { Icon } from '../common/Icon';
import { VStack } from '../common/Stack';
import buttonCheck from './button-check.png';
import buttonDelete from './button-delete.png';
import buttonEdit from './button-edit.png';
import buttonReopen from './button-reopen.png';

interface Size { h: number; w: number; }
export interface Props {
    disabled?: boolean;
    size: Size|number;
    onClick: () => void;
    title?: string;
    label?: string;
    labelPosition?: 'right'|'bottom';
}
interface IconButtonProps extends Props { image: string; }
export const IconButton: React.FC<IconButtonProps> = ({ disabled, image, size, onClick, title, label, labelPosition }) => {
    const onClickHandler = () => !disabled && onClick();
    const className = [
        'icon-button',
        disabled ? 'disabled' : '',
        labelPosition === 'right' ? 'label-right' : '',
    ].filter(Boolean).join(' ');

    return (
        <Root title={title} onClick={onClickHandler} className={className}>
            <Icon className="icon-button-image" url={image} size={size} />
            {label && <label className="icon-button-label">{label}</label>}
        </Root>
    );
};

const Root = styled(VStack)`
  align-items: center;
  cursor: pointer;

  &.label-right { flex-direction: row; }

  .icon-button-label {
    color: #67748e;
    cursor: pointer;
    font-size: 12px;
    font-weight: 600;
    line-height: 1.5;
    margin: 3px 5px 0;
  }

  &.disabled {
    &, .icon-button-label { cursor: not-allowed };

    .icon-button-label { color: #b9b9b9; }
    .icon-button-image { background-position-y: center; }
  }

  &:hover:not(.disabled) {
    .icon-button-label { color: #1c4d75; }
    .icon-button-image { background-position-y: bottom; }
  }
`;

export const CheckButton: React.FC<Props> = (props) => <IconButton {...props} image={buttonCheck} />;
export const ReopenButton: React.FC<Props> = (props) => <IconButton {...props} image={buttonReopen} />;
export const DeleteButton: React.FC<Props> = (props) => <IconButton {...props} image={buttonDelete} />;
export const EditButton: React.FC<Props> = (props) => <IconButton {...props} image={buttonEdit} />;
