import React from 'react';
import styled from 'styled-components';
import { HStack } from '../../../common/Stack';
import { Icon } from '../../../common/Icon';
import farmIconImage from './icon-farm.png';

interface Props { className?: string; name: string; }
export const MyFarm: React.FC<Props> = ({ className, name }) => {
    return (
        <Root {...{ className }}>
            <Icon url={farmIconImage} size={16} className="my-farm-icon" />
            <div className="my-farm-label">{name}</div>
        </Root>
    );
};

const Root = styled(HStack)`
  align-items: center;

  .my-farm-icon {
    flex-grow: 0;
    flex-shrink: 0;
    margin-right: 7px;
  }

  .my-farm-label {
    color: #90a0b7;
    font-size: 14px;
    font-weight: 500;
    line-height: 1em;
  }
`;
