export interface SignUpView {
}

export class SignUpPresenter {
    private readonly view: SignUpView;

    constructor(view: SignUpView) {
        this.view = view;
    }
}
