import { Validator } from '../../../common/validation/Validator';
import { InvalidCredentialsError } from '../model/user/auth/error/InvalidCredentialsError';
import { UnverifiedEmailError } from '../model/user/auth/error/UnverifiedEmailError';
import { UserService } from '../model/user/UserService';
import { Farm, GetPlansResponse, PlanService } from '../model/plan/PlanService';
import { SessionStorage } from '../model/user/auth/SessionStorage';
import { User } from '../model/user/User';
import { InvalidEmailError } from '../model/user/auth/error/InvalidEmailError';
import { NoPlansError } from '../model/plan/NoPlansError';
import { Plan } from '../model/plan/Plan';

export class Login {
    constructor(
        private sessionStorage: SessionStorage,
        private userService: UserService,
        private planService: PlanService,
    ) {}

    async exec(email: string, password: string) {
        const user = await this.login(email, password);
        const response = await this.fetchPlans();
        this.store(email, user, response.plans.first(), response.farm);
    }

    private async login(email: string, password: string): Promise<User|undefined> {
        this.validateCredentials(email, password);
        return await this.doLogin(email, password);
    }

    private validateCredentials(email: string, password: string) {
        const validator = new Validator();
        validator.check('email', email).notNullOrEmpty('Debe ingresar un email');
        validator.check('password', password).notNullOrEmpty('Debe ingresar una contraseña');
        validator.throwIfHasErrors();
    }

    private async doLogin(email: string, password: string): Promise<User|undefined> {
        try {
            return await this.userService.login(email, password);
        } catch (e) {
            Validator.errorToValidationError(e, [
                { error: InvalidCredentialsError, message: 'Los datos ingresados son inválidos' },
                { error: UnverifiedEmailError, message: 'Debe verificar su mail en la aplicación móvil' },
                { error: InvalidEmailError, message: 'Debe ingresar un email válido' },
            ]);
        }
    }

    private async fetchPlans(): Promise<GetPlansResponse> {
        try {
            return await this.planService.getPlans();
        } catch (e) {
            if (e instanceof NoPlansError) {
                Validator.throwGeneralError('Completa la etapa de planificación desde la aplicación móvil e ingresa nuevamente');
            }
            throw e;
        }
    }

    private store(email: string, user: User | undefined, plan: Plan, farm: Farm) {
        this.sessionStorage.store({
            user: {
                email: email,
                screenName: user!.screenName
            },
            selectedPlan: {
                id: plan.id,
                season: plan.season,
            },
            farm: farm,
        });
    }
}
