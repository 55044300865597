import { GetPlans } from '../../../../../core/app/useCases/GetPlans';
import { Plan } from '../../../../../core/app/model/plan/Plan';
import { SelectPlan } from '../../../../../core/app/useCases/SelectPlan';
import { GetSelectedPlan } from '../../../../../core/app/useCases/GetSelectedPlan';
import { Option } from '../../../forms/DropDown';
import { PlanInfo } from '../../../../../core/app/model/plan/PlanInfo';
import { Navigation } from '../../../../lib/navigation/Navigation';

export interface PlanNavView {
    showOptions(options: Option[], selectedOption: Option);
}

export class PlanNavPresenter {
    private plans: Plan[] = [];

    constructor(
        private view: PlanNavView,
        private navigation: Navigation,
        private getPlans: GetPlans,
        private getSelectedPlan: GetSelectedPlan,
        private selectPlan: SelectPlan
    ) {}

    async start() {
        this.plans = await this.getPlans.exec();
        const selectedPlan: PlanInfo = await this.getSelectedPlan.exec();
        this.view.showOptions(this.plans.map(this.planToOption), this.planToOption(selectedPlan));
    }

    async select(planId: number) {
        const selectedPlan: Plan = this.plans.first(plan => plan.id === planId);
        await this.selectPlan.exec(selectedPlan);
        this.redirectToTasksOrReload();
    }

    private redirectToTasksOrReload() {
        this.navigation.redirect('tasks');
    }

    private planToOption(plan: Plan|PlanInfo): Option {
        return {
            value: plan.id,
            label: `Campaña ${plan.season}`
        };
    }
}
