import { CreateTaskForm, CreateTaskRequest, TaskSearchFilters, TaskEditForm, TaskService, UpdateApplicationsRequest, UpdateTaskRequest, SearchTasksByApplicationRequest, UpdateApplicationRequest, } from '../../app/model/task/TaskService';
import { TaskSummary } from '../../app/model/task/TaskSummary';
import { HttpClient } from '../../../common/httpClient/HttpClient';
import { ApiClient } from '../api/ApiClient';
import { TaskStatuses } from '../../app/model/task/TaskStatuses';
import { PlanNotFoundError } from '../../app/model/plan/PlanNotFoundError';
import { NotAuthenticatedError } from '../../app/model/user/auth/error/NotAuthenticatedError';
import { TaskDetail } from '../../app/model/task/TaskDetail';
import { TaskNotFoundError } from '../../app/model/task/TaskNotFoundError';
import { TaskApplication } from '../../app/model/task/TaskApplication';
import { ApplicationAreas } from '../../app/model/task/ApplicationAreas';
import { SupplyUnits } from '../../app/model/task/SupplyUnits';
import { TaskApplicationForm, TaskForm } from '../../app/model/task/TaskForm';
import { InvalidOperationError } from '../../app/model/task/InvalidOperationError';
import { TaskFormNotFoundError } from '../../app/model/task/editForm/TaskFormNotFoundError';
import { TaskSuggestion } from '../../app/model/task/TaskSuggestion';

export class HttpTaskService implements TaskService {
    private api: ApiClient;
    private readonly errorMappings = {
        'PlanNotFoundError': PlanNotFoundError,
        'TaskNotFoundError': TaskNotFoundError,
        'NotAuthenticatedError': NotAuthenticatedError,
        'InvalidOperationError': InvalidOperationError,
        'TaskFormNotFoundError': TaskFormNotFoundError,
    };

    constructor(httpClient: HttpClient) {
        this.api = new ApiClient(httpClient, this.errorMappings);
    }

    async create(request: CreateTaskRequest): Promise<number> {
        const response = await this.api.post('/plans/createTask', {
            ...request,
            typeId: request.taskTypeId,
            startDate: this.api.toDate(request.startDate),
        });
        return response.body.taskId;
    }

    async getById(id: number): Promise<TaskDetail> {
        const response = await this.api.get(`/plans/getTask?taskId=${id}`);
        return this.toTaskDetail(response.body.task);
    }

    async search(request: TaskSearchFilters): Promise<TaskSummary[]> {
        let url = `/plans/searchTasks?planId=${request.planId}`;
        url += request.grainId ? `&grainId=${request.grainId}` : '';
        url += request.typeId ? `&typeId=${request.typeId}` : '';
        url += request.fieldId ? `&fieldId=${request.fieldId}` : '';
        const response = await this.api.get(url);
        const tasksJson = response.body.tasks;
        return tasksJson.map(this.toTaskSummary.bind(this));
    }

    async getByPlan(planId: number): Promise<TaskSummary[]> {
        const response = await this.api.get(`/plans/getTasks?planId=${planId}`);
        const tasksJson = response.body.tasks;
        return tasksJson.map(this.toTaskSummary.bind(this));
    }

    async getEditForm(id: number): Promise<TaskEditForm> {
        const response = await this.api.get(`/plans/getTaskEditForm?taskId=${id}`);
        return {
            task: this.toTaskForm(response.body.task),
            taskTypes: response.body.taskTypes,
        };
    }

    async getCreateTaskForm(planId: number): Promise<CreateTaskForm> {
        const response = await this.api.get(`/plans/getCreateTaskForm?planId=${planId}`);
        return response.body;
    }

    async update(request: UpdateTaskRequest): Promise<void> {
        await this.api.post('/plans/updateTask', {
            ...request,
            startDate: this.api.toDate(request.startDate),
        });
    }

    async updateApplications(request: UpdateApplicationsRequest): Promise<void> {
        await this.api.post('/plans/updateTaskApplications', { ...request });
    }

    async finish(id: number): Promise<void> {
        await this.api.post('/plans/finishTask', { taskId: id });
    }

    async reopen(id: number): Promise<void> {
        await this.api.post('/plans/reopenTask', { taskId: id });
    }

    async delete(id: number): Promise<void> {
        await this.api.post('/plans/deleteTask', {
            taskId: id
        });
    }

    async searchTasksByApplication(request: SearchTasksByApplicationRequest): Promise<TaskSuggestion[]> {
        const apiResponse = await this.api.post('/plans/searchTasksByApplication', {
            planId: request.planId,
            application: {
                supply: request.application.supply,
                dose: request.application.dose,
                unit: SupplyUnits[request.application.unit],
                area: ApplicationAreas[request.application.area],
                costPerUnit: request.application.costPerUnit,
                costCenterId: request.application.costCenterId,
            },
        });
        return apiResponse.body.tasks.map(this.toTaskSuggestion.bind(this));
    }

    async updateApplication(request: UpdateApplicationRequest): Promise<void> {
        await this.api.post('/plans/updateApplication', {
            planId: request.planId,
            application: request.application,
            editedApplication: request.editedApplication,
            tasksToBeEdited: request.tasksToBeEdited.map(id => ({ id })),
        });
    }

    private toTaskSuggestion(json): TaskSuggestion {
        return {
            id: json.id,
            number: json.number,
            type: json.type,
            field: json.field,
            grain: json.grain,
        };
    }

    private toTaskSummary(json): TaskSummary {
        return {
            ...json,
            status: this.taskStatusFromString(json.status),
            startDate: this.api.fromDateTimestamp(json.startDate),
            suggestedStartFrom: this.api.fromDateTimestamp(json.suggestedStartFrom),
            suggestedStartTo: this.api.fromDateTimestamp(json.suggestedStartTo),
        };
    }

    private toTaskDetail(json): TaskDetail {
        return {
            ...json,
            status: this.taskStatusFromString(json.status),
            startDate: this.api.fromDateTimestamp(json.startDate),
            suggestedStartFrom: this.api.fromDateTimestamp(json.suggestedStartFrom),
            suggestedStartTo: this.api.fromDateTimestamp(json.suggestedStartTo),
            applications: json.applications.map(this.toApplication.bind(this))
        };
    }

    private toTaskForm(json): TaskForm {
        return {
            ...json,
            startDate: this.api.fromDateTimestamp(json.startDate),
            suggestedStartFrom: this.api.fromDateTimestamp(json.suggestedStartFrom),
            suggestedStartTo: this.api.fromDateTimestamp(json.suggestedStartTo),
            applications: json.applications.map(this.toApplicationForm.bind(this)),
            observations: json.observations,
        };
    }

    private toApplication(json, idx: number): TaskApplication {
        return {
            ...json,
            number: idx + 1,
            area: this.applicationAreaFromString(json.area),
            unit: this.supplyUnitFromString(json.unit),
        };
    }

    private toApplicationForm(json): TaskApplicationForm {
        return {
            number: json.number,
            supplyName: json.supplyName,
            dose: json.dose,
            unit: this.supplyUnitFromString(json.unit),
            area: this.applicationAreaFromString(json.area),
            costPerUnit: json.costPerUnit,
            costCenterId: json.costCenterId,
            costPerHectare: json.costPerHectare,
        };
    }

    private taskStatusFromString(status: string): TaskStatuses {
        switch (status) {
            case 'Pending': return TaskStatuses.Pending;
            case 'Finished': return TaskStatuses.Finished;
            default: throw new Error(`Invalid task status: ${status}`);
        }
    }

    private applicationAreaFromString(area: string): ApplicationAreas {
        switch (area) {
            case 'PerHectare': return ApplicationAreas.PerHectare;
            case 'Total': return ApplicationAreas.Total;
            default: throw new Error(`Invalid application area: ${area}`);
        }
    }

    private supplyUnitFromString(unit: string): SupplyUnits {
        switch (unit) {
            case 'Litres': return SupplyUnits.Litres;
            case 'Kilograms': return SupplyUnits.Kilograms;
            case 'Bag': return SupplyUnits.Bag;
            default: throw new Error(`Invalid supply unit: ${unit}`);
        }
    }
}
