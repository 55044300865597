import { TaskDetail } from '../../model/task/TaskDetail';
import { TaskService } from '../../model/task/TaskService';

export class GetTask {
    constructor(private taskService: TaskService) {}

    async exec(id: number): Promise<TaskDetail> {
        return this.taskService.getById(id);
    }
}
