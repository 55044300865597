import { Measure } from '../Measure';

export class TaskEditFormVM {
    taskId = 0;
    number = 0;
    fieldName = '';
    grainName = '';
    typeId = 0;
    typeName = '';
    canEditType = false;
    startDate = '';
    suggestedStartDate = '';
    hectares = '';
    laborCostPerHectare = '';
    totalCostPerHectare: Measure = { amount: '', unit: '' };
    applications: TaskApplicationVM[] = [];
    assignee: Nullable<string> = null;
    observations = '';
    taskTypes: TaskTypeVM[] = [];
    errors: { [property: string]: string } = {};
}

interface TaskTypeVM {
    id: number;
    name: string;
}

interface TaskApplicationVM {
    id: number;
    supplyName: string;
    dose: Measure;
    cost: Measure;
}
