import { PlanInfo } from '../model/plan/PlanInfo';
import { SessionStorage } from '../model/user/auth/SessionStorage';
import { EventBus } from '../../infrastructure/eventBus/EventBus';
import { SelectedPlanChanged } from '../events/SelectedPlanChanged';

export class SelectPlan {
    constructor(private sessionStorage: SessionStorage, private eventBus: EventBus) {}

    async exec(plan: PlanInfo) {
        this.sessionStorage.do(session => { session.selectedPlan = plan; });
        await this.eventBus.post(new SelectedPlanChanged(plan));
    }
}
