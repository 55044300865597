import React, { ChangeEventHandler } from 'react';
import styled from 'styled-components';
import InputMask from 'react-input-mask';
import theme from '../../application/theme';

export const DateInput: React.FC<Props> = (props) => (
    <StyledInputMask
        mask="99/99/9999"
        maskPlaceholder="DD/MM/AAAA"
        alwaysShowMask={true}
        spellCheck="false"
        className={props.hasError ? 'has-error' : ''}
        value={props.value}
        onChange={props.onChange}
    />
);

interface Props {
    value?: string;
    onChange?: ChangeEventHandler<HTMLInputElement>;
    hasError?: boolean;
}

const StyledInputMask = styled(InputMask)`
  height: 45px;
  border-radius: ${theme.forms.field.input.borderRadius};
  border: 1px solid ${theme.forms.field.input.borderColor};
  font-size: 13px;
  line-height: 19px;
  padding: 12px 11px 14px;
  width: 100%;
  color: ${theme.forms.field.input.color};

  &:focus { border-color: ${theme.forms.field.input.borderColorFocussed}; }

  &.has-error {
    border-color: ${theme.errorColor};
    background-color: ${theme.forms.field.input.backgroundColorError};
  }
`;
