import styled from 'styled-components';

export const VStack = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ReversedVStack = styled.div`
  display: flex;
  flex-direction: column-reverse;
`;

export const HStack = styled.div`
  display: flex;
  flex-direction: row;
`;

export const ReversedHStack = styled.div`
  display: flex;
  flex-direction: row-reverse;
`;
