import { Navigation } from '../../../lib/navigation/Navigation';
import { GetTask } from '../../../../core/app/useCases/task/GetTask';
import { SupplyUnits } from '../../../../core/app/model/task/SupplyUnits';
import { ApplicationAreas } from '../../../../core/app/model/task/ApplicationAreas';
import { CostCenter } from '../../../../core/app/model/task/CostCenter';
import { GetCostCenters } from '../../../../core/app/useCases/GetCostCenters';
import { ApplicationFormVM } from './ApplicationFormVM';
import { TaskApplication } from '../../../../core/app/model/task/TaskApplication';
import { TaskNotFoundError } from '../../../../core/app/model/task/TaskNotFoundError';
import { TaskDetail } from '../../../../core/app/model/task/TaskDetail';
import { NumberFormatter } from '../../../lib/formatters/NumberFormatter';
import { ValidationsError } from '../../../../common/validation/ValidationsError';
import { TaskApplicationFormatter } from '../../../lib/formatters/TaskApplicationFormatter';
import { AddTaskApplication, AddTaskApplicationRequest } from '../../../../core/app/useCases/task/AddTaskApplication';
import { GetTaskSuggestions } from '../../../../core/app/useCases/task/GetTaskSuggestions';
import { TaskSuggestion } from '../../../../core/app/model/task/TaskSuggestion';
import { UpdateApplication, UpdateApplicationRequest } from '../../../../core/app/useCases/task/UpdateApplication';

export interface EditApplicationView {
    showForm(form: ApplicationFormVM);
    showLoading();
    closeLoading();
    onSuccessfulSubmit();
    onTaskNotFoundError(e: Error);
    showTaskSuggestions(tasks: TaskSuggestionVM[], isAllOptionChecked: boolean);
    showOutOfSyncError();
}

export interface TaskSuggestionVM {
    id: number;
    number: number;
    field: string;
    type: string;
    grainName: string;
    grainColor: string;
    selected: boolean;
}

export class EditApplicationPresenter {
    private taskId!: number;
    private task!: TaskDetail;
    private applicationNumber!: Nullable<number>;
    private applications!: TaskApplication[];
    private costCenters!: CostCenter[];
    private form = new ApplicationFormVM();
    private units = [
        { key: SupplyUnits.Kilograms, label: 'Kilogramos' },
        { key: SupplyUnits.Litres, label: 'Litros' },
        { key: SupplyUnits.Bag, label: 'Bolsas' },
    ];
    private areas = [
        { key: ApplicationAreas.PerHectare, label: 'Por hectárea' },
        { key: ApplicationAreas.Total, label: 'Totales' },
    ];
    private taskSuggestionVMs: TaskSuggestionVM[] = [];

    constructor(
        private view: EditApplicationView,
        private navigation: Navigation,
        private getTask: GetTask,
        private getCostCenters: GetCostCenters,
        private getTasksSuggestion: GetTaskSuggestions,
        private addTaskApplication: AddTaskApplication,
        private updateApplication: UpdateApplication
    ) {}

    async start(taskId: number, applicationNumber: Nullable<number>) {
        this.taskId = taskId;
        this.applicationNumber = applicationNumber;
        await this.createForm();
    }

    private async createForm() {
        this.view.showLoading();
        await this.fetchCostCenters();
        try {
            await this.fetchApplications((task: TaskDetail) => {
                this.showForm(task);
                this.showTaskSuggestions();
            });
        } catch (e) {
            if (e instanceof TaskNotFoundError) return this.navigation.redirectNotFound();
            throw e;
        }
    }

    private async fetchCostCenters() {
        this.costCenters = await this.getCostCenters.exec();
    }

    private showForm(task: TaskDetail) {
        this.applications = task.applications;
        this.form = this.applicationNumber ? this.createFormForEditing(this.applicationToBeEdited()) : this.createFormForAdding();
        this.view.showForm(this.form);
    }

    private createFormForAdding() {
        return {
            ...new ApplicationFormVM(),
            units: this.units,
            areas: this.areas,
            costCenters: this.costCentersVM(),
            errors: {},
        };
    }

    private async fetchApplications(onSuccess: (task: TaskDetail) => void) {
        this.task = await this.getTask.exec(this.taskId);
        onSuccess(this.task);
    }

    private applicationToBeEdited() {
        return this.applications.filter(a => a.number === this.applicationNumber).first();
    }

    private createFormForEditing(application: TaskApplication): ApplicationFormVM {
        return {
            ...new ApplicationFormVM(),
            number: application.number,
            supplyName: application.supplyName,
            dose: application.dose.toString(),
            costPerUnit: application.costPerUnit.toString(),
            unit: application.unit,
            area: application.area,
            costCenterId: application.costCenterId,
            units: this.units,
            areas: this.areas,
            costCenters: this.costCentersVM(),
            totalCost: this.calculateTotalCost(application.dose, application.costPerUnit, application.area),
            totalCostPerHectare: this.calculateTotalCostPerHectare(application.dose, application.costPerUnit, application.area),
            costUnitLabel: TaskApplicationFormatter.formatCostUnit(application.unit),
            errors: {},
        };
    }

    private costCentersVM() {
        return this.costCenters.map((costCenter) => ({ key: costCenter.id, label: costCenter.name }));
    }

    private async showTaskSuggestions() {
        if (!this.applicationNumber) { return; }
        const application = this.task!.applications[this.applicationNumber - 1];
        const tasks = await this.getTasksSuggestion.execute({
            supply: application.supplyName,
            dose: application.dose,
            area: application.area,
            unit: application.unit,
            costPerUnit: application.costPerUnit,
            costCenterId: application.costCenterId,
        });
        this.taskSuggestionVMs = tasks.map(this.taskSuggestionToVM.bind(this)).filter(task => task.id !== this.task!.id);
        this.view.showTaskSuggestions(this.taskSuggestionVMs, false);
    }

    private taskSuggestionToVM(task: TaskSuggestion): TaskSuggestionVM {
        return {
            selected: false,
            grainName: task.grain.name,
            grainColor: task.grain.color,
            ...task,
        };
    }

    toggleSuggestedTask(id: number) {
        const task = this.taskSuggestionVMs.first(t => t.id === id);
        task.selected = !task.selected;
        const isAllOptionChecked = this.taskSuggestionVMs.every(t => t.selected);
        this.view.showTaskSuggestions(this.taskSuggestionVMs, isAllOptionChecked);
    }

    toggleAllOption() {
        const isAllOptionChecked = this.taskSuggestionVMs.any(t => !t.selected);
        this.taskSuggestionVMs.forEach(task => { task.selected = isAllOptionChecked; });
        this.view.showTaskSuggestions(this.taskSuggestionVMs, isAllOptionChecked);
    }

    setSupplyName(supplyName: string) {
        this.updateForm({ supplyName });
    }

    setDose(dose: string) {
        this.updateForm({ dose });
    }

    setCostPerUnit(costPerUnit: string) {
        this.updateForm({ costPerUnit });
    }

    setUnit(unit: SupplyUnits) {
        this.updateForm({ unit });
    }

    setCostCenterId(id: string) {
        this.updateForm({ costCenterId: parseInt(id, 10) });
    }

    setArea(area: ApplicationAreas) {
        this.updateForm({ area });
    }

    private calculateTotalCost(dose: number, costPerUnit: number, area: ApplicationAreas) {
        if (area === ApplicationAreas.Total) {
            return NumberFormatter.float(dose * costPerUnit, 2);
        }
        const total = (dose * this.task.hectares) * costPerUnit;
        return NumberFormatter.float(total, 2);
    }

    private calculateTotalCostPerHectare(dose: number, costPerUnit: number, area: ApplicationAreas) {
        if (area === ApplicationAreas.PerHectare) {
            return NumberFormatter.float(dose * costPerUnit, 2);
        }
        const total = (dose * costPerUnit) / this.task!.hectares;
        return NumberFormatter.float(total, 2);
    }

    private updateForm<K extends keyof ApplicationFormVM>(changes: Pick<ApplicationFormVM, K>) {
        this.form = Object.assign(this.form, changes);
        this.form.totalCost = this.calculateTotalCost(Number(this.form.dose), Number(this.form.costPerUnit), this.form.area!);
        this.form.totalCostPerHectare = this.calculateTotalCostPerHectare(Number(this.form.dose), Number(this.form.costPerUnit), this.form.area!);
        this.form.costUnitLabel = TaskApplicationFormatter.formatCostUnit(this.form.unit!);
        this.view.showForm(this.form);
    }

    async submit() {
        try {
            this.view.showLoading();
            await this.updateOrAddTaskApplication();
            await this.view.onSuccessfulSubmit();
        } catch (e) {
            await this.handleSubmitError(e);
            this.view.closeLoading();
        }
    }

    private async updateOrAddTaskApplication() {
        if (this.applicationNumber) {
            await this.updateApplication.exec(this.createUpdateTaskApplicationsRequest());
        } else {
            await this.addTaskApplication.exec(this.createAddTaskApplicationsRequest());
        }
    }

    private async handleSubmitError(e: Error) {
        if (e instanceof ValidationsError) {
            this.updateForm({ errors: e.allErrorMessages() });
            return;
        } else if (e instanceof TaskNotFoundError) {
            if (e.message.includes(`'${this.task!.id}'`)) {
                this.view.onTaskNotFoundError(e);
            } else {
                await this.showTaskSuggestions();
                this.view.showOutOfSyncError();
            }
            return;
        }
        throw e;
    }

    private createUpdateTaskApplicationsRequest(): UpdateApplicationRequest {
        return {
            number: this.applicationNumber!,
            supplyName: this.form.supplyName,
            dose: this.form.dose,
            unit: this.form.unit!,
            area: this.form.area!,
            costPerUnit: this.form.costPerUnit,
            costCenterId: this.form.costCenterId!,
            applications: this.applications,
            taskIds: this.editingTaskIds(),
        };
    }

    private editingTaskIds(): number[] {
        if (!this.task) { return []; }
        return [
            this.task!.id,
            ...this.taskSuggestionVMs.filter(task => task.selected).map(task => task.id),
        ];
    }

    private createAddTaskApplicationsRequest(): AddTaskApplicationRequest {
        return {
            taskId: this.taskId,
            applications: this.applications,
            supplyName: this.form.supplyName,
            dose: this.form.dose,
            unit: this.form.unit!,
            area: this.form.area!,
            costPerUnit: this.form.costPerUnit,
            costCenterId: this.form.costCenterId!,
        };
    }
}
